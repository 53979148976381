const CheckboxItem = ({label, name, checked, onChange, subLabel}) => {
    return (
        <label className="flex items-center gap-2">
            <input
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
                className="cursor-pointer h-5 w-5 text-blue-600 rounded focus:ring-blue-500"
            />
            <div

            />
            {!!label &&
                <div className='flex flex-col'>
                    <span className={checked ? '' : 'text-gray-500'}>{label}</span>
                    {!!subLabel && <span className={'text-xs text-gray-500'}>{subLabel}</span>}
                </div>
            }
        </label>
    );
};

export default CheckboxItem;
