import React from "react";


function StatItem({value, label}) {
    return (
        <div className='flex flex-col whitespace-nowrap '>
            <div className='flex flex-row gap-1'>
                <p className='text-xl md:text-2xl font-semibold'>
                    {value}
                </p>
            </div>
            <p className='text-xs sm:text-sm text-gray-500 -mt-1'>
                {label}
            </p>
        </div>
    )
}

export default StatItem
