import supabase from './supabase';
import store from '../Store';
import axios from './axios';

export const addScanner = async (eventId, scannerId) => {
    const { data: { session: { access_token }} } = await supabase.auth.getSession();
    const endpoint = "/event/scanner"
    const res = await axios.post(endpoint, {
        access_token,
        event_id: eventId,
        scanner_id: scannerId,
    });

    if (!!res?.data?.error)
      throw new Error(res.data.error)
};


export const addScannerByPhoneNumber = async (eventId, phoneNumber) => {
    const { data: { session: { access_token }} } = await supabase.auth.getSession();
    const endpoint = "/event/scanner"
    const res = await axios.post(endpoint, {
        access_token,
        event_id: eventId,
        phone_number: phoneNumber,
    });

    if (!!res?.data?.error)
      throw new Error(res.data.error)

    if (res.data.type === 'invite') {
        store.dispatch({
            type: "UPDATE_USER_EVENT",
            eventId,
            eventDetails: {
                invited_users: [
                    ...store.getState().userReducer.userEvents[eventId].invited_users, res.data.invite
                ]
            }
        })
    } else if (res.data.type === 'scanner') {
      store.dispatch({
          type: "UPDATE_USER_EVENT",
          eventId,
          eventDetails: {
              scanners: [
                  ...store.getState().userReducer.userEvents[store.getState().userReducer.currentEventId].scanners,
                  {
                      event_id: eventId,
                      profile: res.data.profile,
                      profile_id: res.data.profile.id
                  }
              ]
          }
      })
    }
}

export const removeScanner = async (eventId, scannerId) => {
    const { data, error } = await supabase
        .from("scanners")
        .delete()
        .eq("event_id", eventId)
        .eq("profile_id", scannerId)
        .select()

    if (data.length === 0) {
        const { error: error2 } = await supabase
            .from("invited_users")
            .delete()
            .eq("id", scannerId)
        const updatedInvitedUsers = store.getState().userReducer.userEvents[store.getState().userReducer.currentEventId].invited_users.filter(s => s.id !== scannerId);
        store.dispatch({
            type: "UPDATE_USER_EVENT",
            eventId,
            eventDetails: { invited_users: updatedInvitedUsers }
        })
        return;
    }

    const updatedScanners = store.getState().userReducer.userEvents[store.getState().userReducer.currentEventId].scanners.filter(s => s.profile_id !== scannerId);
    store.dispatch({
        type: "UPDATE_USER_EVENT",
        eventId,
        eventDetails: { scanners: updatedScanners },
    });
};
