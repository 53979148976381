import { useEffect } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import axios from 'api/axios';
import { connect } from 'react-redux';
import Spinner from 'components/atoms/Spinner';
import { isDisabled, fetchHostForShortlink } from 'api/hosts';

const mapStateToProps = (state) => {
    return {
        profileId: state.userReducer.authSession?.user?.id,
        events: state.userReducer.userEvents,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEventView: (view) => {
      dispatch({
        type: "SET_EVENT_VIEW",
        view
      })
    }
  }
}

const InvitePage = (props) => {
    const {shortlink} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const handle = async () => {
            let eventId, hostId, parentId;
            if (!!shortlink) {
              const {event_id, host_id, parent_id} = await fetchHostForShortlink(shortlink);

              // invalid link or link deactivated
              if (!event_id) {
                navigate("/");
                return;
              }

              eventId = event_id;
              hostId = host_id;
              parentId = parent_id;
            } else {
              eventId = searchParams.get("eventId");
              hostId = searchParams.get("hostId");
              parentId = searchParams.get("parentId")
            }

            // skip host disabled check if coming from guest invite as +1
            if (!!hostId && !parentId && isDisabled(eventId, hostId)) {
                window.sessionStorage.removeItem("opassityInviteLinkEventId");
                window.sessionStorage.removeItem("opassityInviteLinkHostId");
                window.sessionStorage.removeItem("opassityInviteLinkParentId");
                navigate("/")
                return;
            }

            if (!!eventId && !!hostId && !parentId) {
                // guest invited by host
                // if user does not already have account, create guest on their behalf
                if (!!props.profileId && !props.events?.[eventId]) {
                    axios.post("/event/login", {
                        profileId: props.profileId,
                        eventId: eventId,
                        hostId: hostId,
                    }).then(res => {
                        navigate(`/event/${eventId}?hostId=${hostId}`)
                    })
                } else {
                    navigate(`/event/${eventId}?hostId=${hostId}`)
                }
              return;
            } else if (!!eventId && !!parentId)  {
              // plus one invited by guest
              if (!!props.profileId && !props.events?.[eventId]) {
                  axios.post("/event/login", {
                      profileId: props.profileId,
                      eventId: eventId,
                      hostId: hostId,
                      parentId: parentId,
                  }).then(res => {
                      navigate(`/event/${eventId}`)
                  })
              } else {
                  navigate(`/event/${eventId}`)
              }
              return;
            } else if (!!eventId) {
              // host invited by organizer
              // no need to check if profie exists because there are no generic links to invite host
              props.setEventView("Host")
              navigate(`/event/${eventId}`)
              return;
            }

          navigate("/")
        }

        handle();

    }, [searchParams])

    return (
        <Spinner size={24} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitePage);
