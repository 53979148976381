import { encode, decode } from 'base64-arraybuffer';

export function calculateAge(dob) {    
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

/** Return 'st', 'nd', 'rd', or 'th' depending on the provided date
 */
export const dateOrdinal = (date) => {
    const day = date.toFormat("dd");
    const st = "st";
    const nd = "nd";
    const rd = "rd";
    const th = "th";

    if (day === "11" || day === "12" || day === "13") return th;

    switch (day[1]) {
        case "1": return st;
        case "2": return nd;
        case "3": return rd;
        default: return th;
    }
}

export const bufferToBase64Url = (buffer) => {
    return encode(buffer);
    const byteView = new Uint8Array(buffer);
    let str = "";
    for (const charCode of byteView) {
        str += String.fromCharCode(charCode);
    }

    // Binary string to base64
    const base64String = btoa(str);

    // Base64 to base64url - assume base64url is well formed
    const base64urlString = base64String.replace(/\+/g, "-").replace(
        /\//g,
        "_",
    ).replace(/=/g, "");
    return base64urlString;
}

export const base64UrlToBuffer = (base64Url) => {
    return decode(base64Url);
}
