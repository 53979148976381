import React, {useCallback, useState, useEffect} from 'react';
import OrganizerApprovalsTable from "../tables/OrganizerApprovalsTable";
import {sample_invites, sample_rejections} from "../../../utils/dummy";
import SectionTabs from "../../atoms/SectionTabs";
import { updateGuestDetails } from '../../../api/guests';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        guests: state.userReducer.guests,
    }
}

function OrganizerApprovalsTableView(props) {
    const [activeTab, setActiveTab] = useState('Pending')
    const [pendingInvites, setPendingInvites] = useState([])
    const [rejectedInvites, setRejectedInvites] = useState([]);

    useEffect(() => {
        const _guests = props.guests?.[props.currentEventId];
        const primaryGuests = _guests?.filter(g => g.is_primary_guest);
        setPendingInvites(_guests?.filter(g => g.status === 'Pending Organizer'))
        const rejects = _guests?.map(g =>
            g.status === 'Rejected' ? g : null
        ).filter(g => !!g)
        const doubleCountedPlusOnes = rejects?.map(g => g.plusOne?.profile_id).filter(e => !!e);
        setRejectedInvites(rejects?.filter(g => !doubleCountedPlusOnes.includes(g.profile_id)))
    }, [props.currentEventId, props.guests])

    const changeTab = useCallback((tab) => {
        setActiveTab(tab)
    }, [])

    // clear notifications
    useEffect(() => {
      const newNotificationGuests = props.guests[props.currentEventId].filter(g => g.status === 'Pending Organizer' && g.is_new_notification)
      newNotificationGuests.forEach(guest => {
        updateGuestDetails(props.currentEventId, guest.profile.id, {
          is_new_notification: false,
          group: guest.group,
          host_id: guest.host_id
        })
      })
    }, [])

    return (
        <div className='flex flex-col flex-1 gap-5'>
            <div className='mt-5 md:hidden flex flex-row items-center justify-between'>
                <p className='text-2xl font-semibold'>
                    Approvals
                </p>
            </div>
            <div className='md:mt-4'>
                <SectionTabs
                    activeTab={activeTab}
                    changeTab={changeTab}
                    tabs={['Pending', 'Rejected']}
                />
            </div>
            <OrganizerApprovalsTable
                invites={activeTab === 'Pending' ? pendingInvites : rejectedInvites}
                tab={activeTab}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(OrganizerApprovalsTableView);
