import {useState, useEffect} from 'react';
import SmsVerificationInput from 'components/auth/SmsVerificationInput';
import {checkSmsVerificationCode, sendSmsVerificationCode} from 'api/auth';
import {useNavigate} from 'react-router-dom';
import {Button} from "../../components/atoms/Button";

const AuthEnterPhoneNumberView = (props) => {
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setError(props.error);
    }, [props.error])

    const handleCodeChange = (code) => {
        setError("");
        setCode(code);
    }

    const handleSubmit = async (code) => {
        const {verified, error} = await checkSmsVerificationCode(`+1${props.phoneNumber}`, code);
        if (verified)
            navigate("/")
        else
            setCode("");
        setError(error);
    }

    const resendCode = async () => {
        setError("");
        sendSmsVerificationCode(`+1${props.phoneNumber}`)
            .then(err => {
                setError(err)
            })
    }

    const handleGoBack = () => {
        props.setAuthView('landing')
    }

    return (
        <div
            className='flex flex-col w-full md:w-[440px] bg-white p-5 py-8 rounded-xl shadow-2xl max-w-screen-sm ml-auto mr-auto gap-2'>
            <div className='flex flex-col items-center my-5 gap-1'>
                <p className="text-center text-2xl font-semibold">
                    {`Enter the code we sent to ${props.phoneNumber}`}
                </p>
                <button className="text-primary hover:text-primary-600" onClick={resendCode}>Resend code</button>
            </div>
            <div className="my-4">
                <SmsVerificationInput
                    value={code}
                    onChange={handleCodeChange}
                    onComplete={handleSubmit}
                />
            </div>
            {!!error && <p className="text-center text-red-500 text-xs">{error}</p>}
            <Button
                onClick={handleGoBack}
                variant={'secondary'}
            >
                Go Back
            </Button>
        </div>
    )
}

export default AuthEnterPhoneNumberView;
