import supabase from './supabase';
import axios from 'api/axios';

export const fetchCheckoutSession = async (price_id) => {
    const endpoint = `/payment/checkout?price_id=${price_id}`;
    const res = await axios.get(endpoint);
    return res.data?.session;
}

export const fetchCheckoutSuccess = async (session_id) => {
    const { data: { session: { access_token } } } = await supabase.auth.getSession();
    const endpoint = "/payment/checkout/success";
    const res = await axios.post(endpoint, {
      access_token,
      session_id,
    });
    return res.data;
}
