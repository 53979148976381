import React, {useState, useEffect} from 'react';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {fetchCheckoutSuccess} from 'api/stripe';
import {Button} from 'components/atoms/Button';
import LottieWrapper from "../components/atoms/LottieWrapper";
import check from "../assets/video/Check.json";
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileTier: (tier) => {
      dispatch({
        type: "SET_PROFILE_TIER",
        tier
      })
    }
  }
}

const Page = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [newTier, setNewTier] = useState('');
    const [loading, setLoading] = useState(true);

    const sessionId = searchParams.get("session_id");
    useEffect(() => {
        const handle = async () => {
            const res = await fetchCheckoutSuccess(sessionId);

            if (!res?.tier) {
              // TODO (@strachan): Payment was unsuccessful or someone randomly navigated to this page
              navigate("/pricing");
            }

            setLoading(false);
            setNewTier(res.tier)
            props.setProfileTier(res.tier);
        }

        handle();
    }, [sessionId])


    return loading ? null : (
        <div className="flex flex-col mt-auto mb-auto justify-center min-h-[100dvh] items-center gap-4 text-center">
            <LottieWrapper
                json={check}
                loop={false}
            />
            <p className='text-3xl font-semibold'>{`You've successfully upgraded to ${newTier} tier!`}</p>
            <p className='text-slate-500'>
                Thanks for choosing Opassity. Reach out to us at <a href="mailto:support@opassity.com"
                                                                    className='font-semibold text-primary-500'>support@opassity.com</a> with
                any questions
                you have.
            </p>
            <Button variant="primary" onClick={() => navigate("/")}>Go Home</Button>
        </div>
    )
}

export default connect(null, mapDispatchToProps)(Page);
