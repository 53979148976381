import supabase from './supabase';
import store from '../Store';
import axios from './axios';

export const getEventById = async (event_id) => {
    const cache = store.getState().userReducer.userEvents;
    if (!!cache?.[event_id])
        return cache[event_id]

    const {data, error} = await supabase.from("events").select("*, guests(*, profile:profiles(*))").eq("id", event_id)

    if (!data || data.length === 0)
        throw new Error(`No event found for provided event id: ${event_id}`);
    return data[0];
}

export const getUserEvents = async () => {
    const {data, error} = await supabase
        .from("events")
        .select(`
            *,
            hosts("*", profile:profiles("*")),
            organizers("*", profile:profiles("*")),
            guests("*", profile:profiles("*")),
            scanners("*", profile:profiles("*")),
            invited_users("*")
        `);
    
    if (data.length === 0) {
        return [];
    }

    const events = data.reduce((obj, event) => ({...obj, [event.id]: event}), {})
    store.dispatch({
        type: "SET_USER_EVENTS",
        userEvents: events
    })

    return events
}


export const updateEventDetails = async (eventId, eventDetails) => {
    let error;

    try {
        const { data, error: updateError } = await supabase
            .from("events")
            .update(eventDetails)
            .eq("id", eventId);

        if (updateError) {
            throw updateError;
        }

        store.dispatch({
            type: "UPDATE_USER_EVENT",
            eventId,
            eventDetails: data
        });

        return data;
    } catch (updateError) {
        error = updateError;
        console.error("Failed to update event details:", error);

        // Optionally, throw the error again if you want it to be caught by the caller
        throw error;
    }
};

export const setImageList = async (eventId, images) => {
    const { error } = await supabase
        .from("events")
        .update({image_urls: images})
        .eq("id", eventId)
}

export const getGuestsForEvent = async (eventId) => {
    // const cache = store.getState().userReducer.guests;
    // if (!!cache[eventId])
    //     return cache[eventId];

    const { data, error } = await supabase
        .from("guests")
        .select(`
            *,
            profile:profiles!public_guests_profile_id_fkey("*"),
            host:hosts(profile:profiles("first_name", "last_name", "display_name")),
            plusOne:guests("*", profile:profiles("*"), host:hosts(profile:profiles("first_name", "last_name", "display_name")))
        `)
        .eq("event_id", eventId)
        .order("created_at", { ascending: false })

    const res = data.map(d => d?.plusOne.length !== 0 ? {...d, plusOne: d.plusOne} : {...d, plusOne: null});
    store.dispatch({
        type: "SET_GUEST_FOR_EVENT",
        eventId,
        guests: res
    })
    return res;
}

export const getHostsForEvent = async (eventId) => {
    const cache = store.getState().userReducer.hosts;
    if (!!cache[eventId])
        return cache[eventId];

    const { data, error } = await supabase
        .from("hosts")
        .select(`
            *,
            profile:profiles("*")
        `)
        .eq("event_id", eventId)

    store.dispatch({
        type: "SET_HOSTS_FOR_EVENT",
        eventId,
        hosts: data
    })

    return data;
}

export const getUserTypeForEvent = async (eventId, userId) => {
    const event = await getEventById(eventId)

    let userType = 'none'
    event.guests?.forEach(guest_id => {
        if (guest_id === userId) userType = 'guest';
    })
    event.hosts?.forEach(h => {
        if (h.profile_id === userId) userType = 'host';
    })
    event.organizers?.forEach(o => {
        if (o.profile_id === userId) userType = 'organizer';
    })

    return userType;
}

export const getInvitedUsers = async (eventId) => {
  const { data: invitedUsers, error } = await supabase
    .from("invited_users")
    .select("*")
    .eq("event_id", eventId)

  if (!!error)
    throw new Error("Unable to find event")

  return invitedUsers;
}

export const createEvent = async (userId, eventDetails) => {
  const endpoint = "/event";
  const res = await axios.post(endpoint, {
      ...eventDetails,
      owner_id: userId,
  });

  const eventId = res?.data?.event_id;
  if (!eventId)
    throw new Error(res?.data?.error || "Unable to create event")

  // create organizer
  const { error: error2 } = await supabase
    .from("organizers")
    .insert({
      event_id: eventId,
      profile_id: userId,
    })

  await store.dispatch({
      type: "UPDATE_USER_EVENT",
      eventId,
      eventDetails
  });

  return eventId;
}

// Check if user can join event without fetching all details.
// This logic should *really* be handled server side, but this works for now.
export const checkCapacity = async (eventId) => {
  const userId = (await supabase.auth.getSession()).data?.session?.user?.id;
  const userIsOrganizer = (await supabase
                            .from("organizers")
                            .select("*", { count: "exact", head: true })
                            .eq("event_id", eventId)
                            .eq("profile_id", userId)
                          ).count > 0;
  const userIsHost = (await supabase
                            .from("hosts")
                            .select("*", { count: "exact", head: true })
                            .eq("event_id", eventId)
                            .eq("profile_id", userId)
                          ).count > 0;
  const userAlreadyGuest = (await supabase
                            .from("guests")
                            .select("*", { count: "exact", head: true })
                            .eq("event_id", eventId)
                            .eq("profile_id", userId)
                            .neq("status", null)
                            ).count > 0;
  if (userIsOrganizer || userIsHost || userAlreadyGuest)
    return true;

  // check if there is available capacity
  const tier = (await supabase
                .from("events")
                .select("owner:profiles!events_owner_id_fkey(tier)")
                .eq("id", eventId)
                ).data?.[0]?.owner?.tier;

  if (!tier)
    throw new Error("No event found");

  const numActiveGuests = (await supabase
                  .from("guests")
                  .select("*", { count: 'exact', head: true })
                  .eq("event_id", eventId)
                  ).count;
  const numInvitedGuests = (await supabase
                      .from("invited_users")
                      .select("*", { count: "exact", head: true })
                      .eq("event_id", eventId)
                      .eq("type", "Guest")
                      ).count;
  const numGuests = numActiveGuests + numInvitedGuests;

  switch (tier) {
    case "Enterprise":
      return true;
    case "Pro":
      return numGuests < 500;
    case "Free":
      return numGuests < 100;
    default:
      return false;
  }
}
