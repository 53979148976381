import {useEffect, useRef, useState} from 'react';

const SmsVerificationInput = (props) => {
    const inputRef = useRef(null);
    const [focused, setFocused] = useState(false);

    // autofocus
    useEffect(() => {
        inputRef.current?.focus();
    }, [])

    const handleFocus = () => {
        inputRef.current.focus();
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
    }

    const getValue = (i) => {
        return props?.value?.[i] || "";
    }

    const isActive = (i) => {
        return focused && i === props.value?.length;
    }

    const handleInputChange = (e) => {
        const input = e.target.value;

        if (RegExp(`^[0-9]{0,${props?.length || 6}}$`).test(input)) {
            props.onChange?.(input);
            if (input.length === (props?.length || 6))
                props.onComplete?.(input);
        }
    }

    return (
        <div
            className="cursor-text"
            onClick={handleFocus}
        >
            <input
                ref={inputRef}
                type="tel"
                inputMode="tel"
                autoComplete="one-time-code"
                value={props.value}
                spellCheck={false}
                placeholder={props.placeholder}
                autoFocus={props?.autoFocus ? props.autoFocus : false}
                onChange={handleInputChange}
                variant="ghost"
                className="h-0 w-0 absolute start-0 bg-transparent outline-none caret-transparent text-transparent"
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <div className="flex relative gap-2 h-[75px] w-full justify-center">
                {[...Array(6)].map((_, i) => (
                    <div
                        key={`verification-input-box-${i}`}
                        className={`
                            ${isActive(i) ? character_active_s : character_inactive_s}
                            ${character_s}
                        `}
                    >
                        <p>{getValue(i)}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

// individual input box
const character_s = `
    flex
    text-gray-800
    text-4xl
    font-light
    h-full
    w-[50px]
    bg-gray-200
    rounded-lg
    justify-center
    items-center
`;

// input box that is inactive
const character_inactive_s = "";

const character_active_s = "border-2 border-primary"

export default SmsVerificationInput;
