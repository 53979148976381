import TableHeaders from "../../atoms/TableHeaders";
import React from "react";
import {attendee_table_headers} from "../../../utils/consts";
import OrganizerApprovalsTableItem from "./OrganizerApprovalsTableItem";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    event: state.userReducer.userEvents[state.userReducer.currentEventId],
  }
}

function OrganizerApprovalsTable({invites, tab, event}) {

    // map column name to required scope
    const colScopes = {
      'Age': 'age',
      'Gender': 'gender',
      'Instagram': 'social',
      'Is +1?': 'plusOne',
    }
    const headers = attendee_table_headers.filter(col => {
      return !colScopes[col] || !!event.guest_scopes[colScopes[col]];
    })
    return (
        <div
            className='w-full flex justify-start overflow-y-hidden overflow-x-scroll border border-gray-200 rounded-xl'>
            <table className='w-full'>
                <TableHeaders columns={headers}/>
                <tbody>
                {invites.length > 0 ?
                    invites.map((invite, index) => (
                        <OrganizerApprovalsTableItem
                            invite={invite}
                            index={index}
                            tab={tab}
                            scopes={event.guest_scopes}
                        />
                    )) :
                    <tr className="h-[100px] text-center bg-gray-50">
                        <td colSpan="99">
                            {`No guests ${tab === 'Pending' ? 'are pending organizer approval' : 'have been rejected'} for this event`}
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}

export default connect(mapStateToProps, null)(OrganizerApprovalsTable);
