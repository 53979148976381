import TextInputWrapper from "./TextInputWrapper";
import React, { useEffect, useState, useCallback } from "react";

const formatPhoneNumber = (value) => {
    if (!value) return '';
    const numbers = value.replace(/[^\d]/g, '');
    const matches = numbers.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    let formatted = '';
    if (matches) {
        formatted = `(${matches[1]}`;
        if (matches[2]) {
            formatted += `) ${matches[2]}`;
        }
        if (matches[3]) {
            formatted += `-${matches[3]}`;
        }
    }
    return formatted;
};

function PhoneInputWrapper({value, onChange, label, disabled}) {
    const [formattedValue, setFormattedValue] = useState(formatPhoneNumber(value));

    useEffect(() => {
        // Automatically update the formatted value when the external value changes
        setFormattedValue(formatPhoneNumber(value));
    }, [value]); // Re-run the effect when the external value changes

    const handleInputChange = useCallback((event) => {
        const rawValue = event.target.value;
        if (rawValue.length > 14) return;
        const formatted = formatPhoneNumber(rawValue);
        setFormattedValue(formatted); // Update the displayed phone number
        onChange(rawValue.replace(/[^\d]/g, '')); // Send back numeric values only
    }, [onChange]); // Depend on the onChange prop

    return (
        <div className='flex flex-col gap-3 w-full'>
            <TextInputWrapper
                label={label}
                value={formattedValue}
                type={'tel'}
                placeholder={disabled ? "" : '(000) 000-0000'}
                onChange={handleInputChange}
                disabled={disabled}
            />
        </div>
    );
}

export default PhoneInputWrapper;
