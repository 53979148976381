import React from 'react';

function TableHeaders({columns}) {

    return (
        <thead className='bg-primary-50'>
        <tr className='bg-primary-50 text-xs md:text-sm z-[0]'>
            {columns?.map((header, index) => (
                <th key={index} className={`px-2 md:px-4 py-1 md:py-2 text-primary text-left ${index === 0 && 'sticky z-0 left-0 bg-primary-50'}`}>
                    {header}
                </th>
            ))}
        </tr>
        </thead>
    )
}

export default TableHeaders;
