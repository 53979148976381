import OnboardingStepper from "../components/onboarding/OnboardingStepper";
import {useEffect, useState} from "react";
import OnboardingRsvpDetails from "../components/onboarding/OnboardingRsvpDetails";
import OnboardingPersonalDetails from "../components/onboarding/OnboardingPersonalDetails";
import {createProfile, checkProfileExists} from 'api/profiles';
import {connect} from 'react-redux';
import Spinner from "components/atoms/Spinner";

const mapStateToProps = (state) => {
    return {
        userId: state.userReducer.authSession?.user?.id,
        authSession: state.userReducer.authSession,
    }
}

function OnboardingPage(props) {
    const [step, setStep] = useState(1);
    const [switchingStep, setSwitchingStep] = useState(false);
    const [renderedStep, setRenderedStep] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('')
    const [dob, setDob] = useState('')
    const [gender, setGender] = useState('')
    const [instagram, setInstagram] = useState('');
    const [loading, setLoading] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false)

    const NUM_STEPS = 2;

    // prepopulate data
    useEffect(() => {
        const user = props.authSession?.user;
        const name = user?.user_metadata?.name

        // first and last name are present
        if (name?.split(" ").length > 1) {
            setFirstName(name.split(" ").slice(0, name.split(" ").length - 1).join(" "));
            setLastName(name.split(" ").slice(name.split(" ").length - 1).join(" "));
        }
        const email = user?.user_metadata?.email || "";
        setEmail(email);
    }, [])

    // route to another page if user is already onboarded
    useEffect(() => {
        if (!props.authSession?.user)
            window.location.href = "/auth"
        if (props.authSession?.user?.user_metadata?.onboarded)
            window.location.href = "/"
    }, [props.authSession])

    useEffect(() => {
        if (!switchingStep) {
            setRenderedStep(step);
        }
    }, [switchingStep, step]);

    const changeStep = (newStep) => {
        setSwitchingStep(true);
        setTimeout(() => {
            setStep(newStep);
            setSwitchingStep(false);
        }, 300);
    }

    const goNextStep = () => {
        if (step === NUM_STEPS) {
            submit();
        }
        changeStep(step + 1);
    }

    const goBackStep = () => {
        changeStep(step - 1);
    }

    const submit = async () => {
        setLoading(true)
        if (await checkProfileExists(phone))
            return;

        const profile = {
            id: props.userId,
            firstName,
            lastName,
            phoneNumber: phone,
            email,
            dateOfBirth: dob,
            gender,
            instagram
        }
        await createProfile(profile);

        let route = "/";
        if (!!window.sessionStorage.getItem("opassityInviteLinkEventId")) {
            route = `/invite?eventId=${window.sessionStorage.getItem("opassityInviteLinkEventId")}&hostId=${window.sessionStorage.getItem("opassityInviteLinkHostId")}&parentId=${window.sessionStorage.getItem("opassityInviteLinkParentId")}`;
            window.sessionStorage.removeItem("opassityInviteLinkEventId");
            window.sessionStorage.removeItem("opassityInviteLinkHostId");
            window.sessionStorage.removeItem("opassityInviteLinkParentId");
        }
        window.location.href = route;
        setLoading(false);
    }

    const stepStyle = {
        opacity: switchingStep ? 0 : 1,
        transform: switchingStep ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms'
    };

    const renderStep = () => {
        switch (renderedStep) {
            case 1:
                return <OnboardingRsvpDetails
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    phone={phone}
                    setPhone={setPhone}
                    email={email}
                    setEmail={setEmail}
                    acceptedTerms={acceptedTerms}
                    setAcceptedTerms={setAcceptedTerms}
                    goNextStep={goNextStep}
                />;
            case 2:
                return <OnboardingPersonalDetails
                    dob={dob}
                    setDob={setDob}
                    gender={gender}
                    setGender={setGender}
                    instagram={instagram}
                    setInstagram={setInstagram}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />
            default:
                return null;
        }
    };

    return (
        loading ?
            <div className='flex flex-col items-center justify-center min-h-[100dvh]'>
                <Spinner size={24}/>
            </div>
            :
            <div className='flex font-satoshi bg-white flex-col items-center h-[100vh] p-5 gap-5'>
                <OnboardingStepper
                    numSteps={NUM_STEPS}
                    step={step}
                />
                <div className='flex flex-col items-center justify-center flex-1' style={stepStyle}>
                    {renderStep()}
                </div>
            </div>
    );
}

export default connect(mapStateToProps, null)(OnboardingPage);
