import React, {useCallback, useState, useEffect} from 'react';
import {sample_event, sample_host_summary_data, sample_summary_data} from "../../utils/dummy";
import AnimatedTabs from "../../components/atoms/AnimatedTabs";
import EventPageHeaderComponent from "../../components/components/EventPageHeaderComponent";
import HostGuestListView from "../../components/host_view/views/HostGuestListView";
import HostInvitesView from "../../components/host_view/views/HostInvitesView";
import HostApprovalsView from "../../components/host_view/views/HostApprovalsView";
import {getGuestsForEvent, getInvitedUsers} from '../../api/events';
import {connect} from 'react-redux';
import Spinner from "../../components/atoms/Spinner";

const mapStateToProps = (state) => {
    return {
        guests: state.userReducer.guests,
        hosts: state.userReducer.hosts,
        authSession: state.userReducer.authSession,
        currentEventId: state.userReducer.currentEventId
    }
}

function HostEventPage(props) {
    const [filteredEventGuests, setFilteredEventGuests] = useState([]);
    const [guests, setGuests] = useState([]);
    const [invitedGuests, setInvitedGuests] = useState([]);
    const [myHostObject, setHostObject] = useState({});
    const [eventLoading, setEventLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('Guest List');
    const [numNotifications, setNumNotifications] = useState(0);

    useEffect(() => {
        getHostsAndGuests();
    }, [props.event])

    useEffect(() => {
        const _guests = (props.guests?.[props.event.id] || []).filter(g => !!g.status);
        const primaryGuests = _guests.filter(guest => guest.is_primary_guest);
        setFilteredEventGuests(primaryGuests)

        const _hosts = props.event.hosts;
        setHostObject(_hosts?.filter(host => host.profile_id === props.authSession.user.id)[0])
    }, [props.guests, props.event.id])

    const getHostsAndGuests = async () => {
        setEventLoading(true)

        const guests = await getGuestsForEvent(props.event.id);
        setGuests(guests);

        const invitedUsers = await getInvitedUsers(props.event.id);
        setInvitedGuests(invitedUsers);

        setEventLoading(false);
    }

    useEffect(() => {
      const numNotifications = props.guests[props.currentEventId]?.filter(g => g.status === 'Pending Host' && !!g.is_new_notification).length;
      setNumNotifications(numNotifications);
    }, [props.guests, props.currentEventId])

    const switchView = useCallback(() => {
        switch (activeTab) {
            case 'Guest List':
                return (
                    <HostGuestListView setFilteredGuests={() => {
                    }} event={props.event}/>
                )
            case 'Approvals':
                return (
                    <HostApprovalsView event={props.event}/>
                )
            case 'Invites':
                return (
                    <HostInvitesView event={props.event}/>
                )
            default:
                return null
        }
    }, [activeTab, props.event])

    return eventLoading ?
        <div className='flex flex-col items-center justify-center min-h-[100dvh]'>
            <Spinner size={24}/>
        </div>
        : (
            <div className='flex flex-col flex-1 w-full p-5'>
                <EventPageHeaderComponent
                    event={props.event}
                    filteredGuests={filteredEventGuests}
                    invitedGuests={invitedGuests}
                    hostObject={myHostObject}
                />
                <div className='flex flex-col flex-1'>
                    <div
                        className='sticky top-14 bg-white z-[1] flex flex-row justify-between items-end border-b border-gray-200'>
                        <div className='mr-auto'>
                            <AnimatedTabs
                                activeTab={activeTab}
                                changeTab={setActiveTab}
                                tabs={[
                                    {title: 'Guest List'},
                                    {
                                        title: 'Approvals',
                                        badge: numNotifications,
                                    },
                                    {title: 'Invites'},
                                ]}
                                className={'my-3'}
                            />
                        </div>
                    </div>
                    {switchView()}
                </div>
            </div>
        )
}

export default connect(mapStateToProps, null)(HostEventPage);
