import React, {useCallback, useState, useEffect} from 'react';
import SvgCheck from "../../icons/SvgCheck";
import {Button} from "../../atoms/Button";
import SvgSubDirectoryRight from "../../icons/SvgSubDirectoryRight";
import {calculateAge} from "../../../utils/helpers";
import SvgClose from "../../icons/SvgClose";
import SvgUndo from "../../icons/SvgUndo";
import SvgClock from "../../icons/SvgClock";
import {setGuestStatus} from '../../../api/guests';
import {connect} from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../../utils/consts";
import GroupTag from "../../atoms/GroupTag";

const mapStateToProps = (state) => {
    return {
        approvalRequired: state.userReducer.userEvents[state.userReducer.currentEventId].hosts?.filter(h => h.profile_id === state.userReducer.authSession?.user?.id)?.[0]?.requires_approval,
        mobile: state.userReducer.mobile,
    }
}

function HostApprovalsTableItem({invite, index, isAwaitingOrganizerApproval, tab, approvalRequired, scopes, mobile}) {
    const [plusOne, setPlusOne] = useState(null);

    useEffect(() => {
        setPlusOne(invite.is_primary_guest ? invite.plusOne : invite);
    }, [invite, tab])

    const renderCell = (key, customRender = null) => {
        const content = key.split(".").reduce((o, k) => o?.[k], invite);
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs inline whitespace-nowrap truncate'>{content || '--'}</div>;
    };

    const renderPlusOneCell = (plusOne, key, customRender = null) => {
        if (!plusOne) return;
        const content = key.split(".").reduce((o, k) => o?.[k], plusOne);
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs inline whitespace-nowrap truncate'>{content || '--'}</div>;
    };

    const handleAction = async (guest, status, actionName) => {
        try {
            await toast.promise(
                setGuestStatus(guest.event_id, guest.profile_id, status, false, false),
                {
                    pending: `${actionName}...`,
                    success: `${actionName} success`,
                    error: `Failed to ${actionName.toLowerCase()}.`
                },
                TOAST_OPTIONS
            );

            // Handle guest's plusOne if exists
            if (!!guest.plusOne) {
              await Promise.all(guest.plusOne.map(async p => {
                await setGuestStatus(guest.event_id, p.profile_id, status)
              }))
            }
        } catch (error) {
            // Error handling beyond the toast notification, if necessary
            console.error(`Error in ${actionName}:`, error);
        }
    };

    const handleUndoReject = (guest) => {
        handleAction(guest, (guest.is_primary_guest) ? 'Pending Organizer' : 'Pending Host', 'Undo Rejection');
    };

    const handleApprove = (guest) => {
        if (approvalRequired === undefined || approvalRequired)
          handleAction(guest, 'Pending Organizer', 'Approval');
        else if (guest.requested)
          handleAction(guest, 'Attending', 'Approval');
        else
          handleAction(guest, 'Approved', 'Approval');
    };

    const handleReject = (guest) => {
        handleAction(guest, 'Rejected', 'Rejection');
    };

    return (
        <>
            <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} text-xs md:text-sm`} key={index}>
                <td className={`px-5 py-3 sticky left-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                    <div className='relative'>
                        {renderCell('profile', (profile) => (
                            <div className='max-w-xs truncate font-semibold'>
                                {`${profile?.first_name} ${profile?.last_name}`}
                            </div>
                        ))}

                    </div>
                </td>
                {!mobile &&
                  <td className='px-2 md:px-5 py-2 md:py-3 whitespace-nowrap'>{renderCell("host.profile.display_name", (displayName) =>
                    displayName || `${renderCell('host.profile.first_name', content => content) || ""} ${renderCell('host.profile.last_name', content => content) || ""}`
                  )}</td>
                }
                <td className='px-2 md:px-5 py-2 md:py-3'>
                    <div className='w-min whitespace-nowrap'>
                        <GroupTag group={invite?.group}/>
                    </div>
                </td>
                {!mobile &&
                  <>
                    <td className='whitespace-nowrap px-5 py-3'>{renderCell('profile.phone_number', (phoneNumber) =>
                        <p>
                            {parsePhoneNumber(`+1${phoneNumber}`)?.formatNational()}
                        </p>
                    )}</td>
                  <td className='px-5 py-3'>{renderCell('profile.email')}</td>
                  {!!scopes.age &&
                    <td className='px-5 py-3'>
                        {renderCell('profile.date_of_birth', (dob) => (
                            <div className='max-w-xs truncate'>
                                {!!dob ? calculateAge(dob) : "--"}
                            </div>
                        ))}
                    </td>
                  }
                  {!!scopes.gender &&
                    <td className='px-5 py-3'>
                        {renderCell('profile.gender', (gender) => (
                            <div className='max-w-xs truncate'>{gender === 'Prefer Not To Say' ? '--' : gender === 'Male' ? 'M' : 'F'}</div>
                        ))}
                    </td>
                  }
                  {!!scopes.social &&
                    <td className='px-5 py-3'>{renderCell('profile.instagram', (handle) =>
                        <a
                            href={`https://www.instagram.com/${handle}`}
                            target="_blank"
                            className="text-cyan-500"
                        >
                            {!!handle && `@${handle}`}
                        </a>
                    )}
                    </td>
                  }
                  {/* {!!scopes.plusOne &&
                    <td className='px-5 py-3'>
                        {renderCell('plusOne', (plusOne) => (
                            <div className='max-w-xs truncate w-10'>{!!plusOne ? <SvgCheck/> : '--'}</div>
                        ))}
                    </td>
                  } */}
                  {!!scopes.plusOne &&
                    <td className='px-5 py-3'>
                        {renderCell('is_primary_guest', (isPrimaryGuest) => (
                            <div className='max-w-xs truncate w-10'>{isPrimaryGuest ? '--' : <SvgCheck/>}</div>
                        ))}
                    </td>
                  }
                  <td className='px-5 py-3'>
                      {renderCell('notes', (notes) => (
                          <div className='max-w-xs truncate'>
                              {notes?.length ? `${notes.length} note${notes.length > 1 ? 's' : ''}` : 'No notes'}
                          </div>
                      ))}
                  </td>
                  </>
                }
                <td className={`px-2 py-3 sticky right-0`}>
                    {isAwaitingOrganizerApproval ?
                        <Button
                            icon={SvgClock}
                            onClick={null}
                            disabled={true}
                            variant={'secondary'}
                            size={'icon'}
                            radius={'full'}
                            className={'shadow-lg'}
                        />
                        :
                        <>
                            {invite.status === 'Rejected' ?
                                <Button
                                    icon={SvgUndo}
                                    onClick={() => handleUndoReject(invite)}
                                    variant={'secondary'}
                                    size={'icon'}
                                    radius={'full'}
                                    className={'shadow-lg'}
                                />
                                :
                                <div className='flex flex-row gap-3'>
                                    <Button
                                        icon={SvgCheck}
                                        onClick={() => handleApprove(invite)}
                                        variant={'green'}
                                        size={'icon'}
                                        radius={'full'}
                                        className={'shadow-lg'}
                                    />
                                    <Button
                                        icon={SvgClose}
                                        onClick={() => handleReject(invite)}
                                        variant={'secondary'}
                                        size={'icon'}
                                        radius={'full'}
                                        className={'shadow-lg'}
                                    />
                                </div>
                            }
                        </>
                    }
                </td>
            </tr>
            {invite.plusOne?.map(plusOne =>
                <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} text-xs md:text-sm relative`}>
                    <td className={`px-5 py-3 sticky left-0 bg-white ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                        <div className='ml-2 flex flex-row items-center gap-1'>
                            <SvgSubDirectoryRight className={'text-gray-400'}/>
                            <p className={'text-gray-400'}>
                                +1
                            </p>
                            {renderPlusOneCell(plusOne, '', (name) => (
                                <div className='max-w-xs truncate font-semibold'>
                                    {`${plusOne.profile.first_name} ${plusOne.profile.last_name}`}
                                </div>
                            ))}
                        </div>
                    </td>
                    {!mobile &&
                      <td className='px-2 md:px-5 py-2 md:py-3 whitespace-nowrap'>{renderPlusOneCell(plusOne, "host.profile.display_name", (displayName) =>
                        displayName || `${renderPlusOneCell(plusOne, 'host.profile.first_name', content => content) || ""} ${renderPlusOneCell(plusOne, 'host.profile.last_name', content => content) || ""}`
                      )}</td>
                    }
                    <td className='px-2 md:px-5 py-2 md:py-3'>
                        <div className='w-min whitespace-nowrap'>
                            <GroupTag group={plusOne?.group || ""}/>
                        </div>
                    </td>
                    {!mobile &&
                      <>
                      <td className='whitespace-nowrap px-5 py-3'>{renderPlusOneCell(plusOne, 'profile.phone_number', (phoneNumber) =>
                          <p>
                              {parsePhoneNumber(`+1${phoneNumber}`)?.formatNational()}
                          </p>
                      )}</td>
                      <td className='px-5 py-3'>{renderPlusOneCell(plusOne, 'profile.email')}</td>
                      {!!scopes.age &&
                        <td className='px-5 py-3'>
                            {renderPlusOneCell(plusOne, 'profile.date_of_birth', (dob) => (
                                <div className='max-w-xs truncate'>
                                    {!!dob ? calculateAge(dob) : "--"}
                                </div>
                            ))}
                        </td>
                      }
                      {!!scopes.gender &&
                        <td className='px-5 py-3'>
                            {renderPlusOneCell(plusOne, 'profile.gender', (gender) => (
                                <div className='max-w-xs truncate'>{gender === 'Prefer Not To Say' ? '--' : gender === 'Male' ? 'M' : 'F'}</div>
                            ))}
                        </td>
                      }
                      {!!scopes.social &&
                        <td className='px-5 py-3'>{renderPlusOneCell(plusOne, 'profile.instagram', (handle) =>
                            <a
                                href={`https://www.instagram.com/${handle}`}
                                target="_blank"
                                className="text-cyan-500"
                            >
                                {`@${handle}`}
                            </a>
                        )}
                        </td>
                      }
                      {!!scopes.plusOne &&
                        <>
                          {/* <td className='px-5 py-3'>
                              {renderPlusOneCell(plusOne, '', (plusOne) => (
                                  <div className='max-w-xs truncate w-10'></div>
                              ))}
                          </td> */}
                          <td className='px-5 py-3'>
                              {renderPlusOneCell(plusOne, 'isPlusOne', () => (
                                  <div className='max-w-xs truncate w-10'><SvgCheck/></div>
                              ))}
                          </td>
                        </>
                      }
                      <td className='px-5 py-3'>
                          {renderPlusOneCell(plusOne, 'notes', (notes) => (
                              <div className='max-w-xs truncate'>
                                  {plusOne.notes?.length ? `${plusOne.notes.length} note${plusOne.notes.length > 1 ? 's' : ''}` : 'No notes'}
                              </div>
                          ))}
                      </td>
                      </>
                    }
                    <td className={`px-2 py-3 sticky right-0`}>
                        {isAwaitingOrganizerApproval ?
                            <Button
                                icon={SvgClock}
                                onClick={null}
                                disabled={true}
                                variant={'secondary'}
                                size={'icon'}
                                radius={'full'}
                                className={'shadow-lg'}
                            />
                            :
                            <>
                                {invite.status === 'Rejected' ?
                                    null
                                    :
                                    <Button
                                        icon={SvgClose}
                                        onClick={() => handleReject(plusOne)}
                                        variant={'secondary'}
                                        size={'icon'}
                                        radius={'full'}
                                        className={'shadow-lg'}
                                    />
                                }
                            </>
                        }
                    </td>
                </tr>
            )
            }
        </>
    );
}

export default connect(mapStateToProps, null)(HostApprovalsTableItem);
