import React, {useEffect, useState} from 'react';
import SvgCopy from "../../icons/SvgCopy";
import SvgTrash from "../../icons/SvgTrash";
import AccessibleToggle from "../../atoms/AccessibleToggle";
import SvgCheck from "../../icons/SvgCheck";
import SvgCheckCircle from "../../icons/SvgCheckCircle";

function HostInviteLinkListItem({invite, onCopy, onToggle, onDelete}) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => setCopied(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [copied]);

    const handleCopy = () => {
        onCopy();
        setCopied(true);
    };


    return (
        <div
            className={`flex flex-row items-center w-full gap-3 p-3 rounded-xl border-[1.5px] ${!invite.deactivated ? 'bg-green-50 text-green-500' : 'bg-primary-50/50  text-primary'}`}>
            <div className='flex flex-1 flex-col w-full'>
                {copied ? <p className='font-semibold'>Link copied!</p> :
                    <p className='font-semibold'>{invite.deactivated && `(Paused)`} {invite.label}:</p>
                }
                <p className='flex-1 truncate w-full'>
                    {invite.short_link.slice(0, 32)}{invite.short_link.length > 32 ? "..." : ''}
                </p>
            </div>
            <AccessibleToggle hideCheck value={!invite.deactivated} onToggle={onToggle}/>
            <button onClick={handleCopy} className='text-primary'>
                {copied ? <SvgCheckCircle className={'scale-90 text-green-500'}/> :
                    <SvgCopy className={'scale-90 text-slate-500 hover:text-green-500'}/>}
            </button>
            <button onClick={onDelete} className='text-slate-500 hover:text-red-400'>
                <SvgTrash/>
            </button>
        </div>
    );
}

export default HostInviteLinkListItem;
