import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import LottieWrapper from "../atoms/LottieWrapper";
import check from '../../assets/video/Check.json'

function AcceptedInviteSuccessModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            noPadding
            confetti
        >
            <div className="flex flex-col items-center gap-5 justify-center relative p-5">
                <LottieWrapper
                    json={check}
                    loop={false}
                />
                <div className='flex flex-col items-center text-center gap-2 -mt-3'>
                    <p className='text-2xl font-medium'>
                        You've accepted your invite
                    </p>
                    <p className='text-gray-500'>
                        You are confirmed on the Guest List. We will see you at the event!
                    </p>
                </div>
                <div className='flex flex-col gap-3 w-full cursor-pointer z-10'>
                    <Button onClick={props.close} variant={'secondary'}>
                        Done
                    </Button>
                </div>
            </div>
        </ModalComponent>
    );
}

export default AcceptedInviteSuccessModal;
