import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";

function InviteScannerPaywallModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header="Scanner Limit Reached!"
            description={props.tier.toLowerCase() === 'free' ?
              'Free tier events are not eligible to add scanners. The owner of this event must upgrade their account in order to add scanners.'
              : `You've reached the maximum number of scanners that can be invited on a ${props.tier.toLowerCase()} tier account. Remove an existing scanner or upgrade this event to add more scanners.`}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={props.close} variant={'secondary'}>
                      Close
                    </Button>
                </div>
            }
        >
        </ModalComponent>
    );
}

export default InviteScannerPaywallModal;
