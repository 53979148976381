import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { fetchCheckoutSession } from 'api/stripe';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const StripeCheckout = ({ priceId }) => {

  const fetchClientSecret = async () => {
    const session = await fetchCheckoutSession(priceId);
    return session.client_secret;
  }

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{fetchClientSecret}}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  )
}

export default StripeCheckout;
