import GuestEventItem from "./GuestEventItem";
import React from "react";
import ListZeroState from "../components/ListZeroState";
import zero_state_image from '../../assets/img/casual-life-3d-business-cards-stack-mockup.png'

function GuestPastEventsList({events}) {
    return (
        <div className='flex flex-col'>
            {events.length > 0 &&
                <div className='flex flex-row justify-between'>
                    <p className='text-xl font-bold'>
                        Past Events
                    </p>
                </div>
            }
            <div className='flex flex-col gap-2 pt-3'>
                <ListZeroState
                    image={zero_state_image}
                    title={'No events to show'}
                    description={"You don't have event history yet."}
                />
                {events.map(event =>
                    <GuestEventItem
                        event={event}
                        key={event.id}
                        isPastEvent={true}
                    />
                )}
            </div>
        </div>
    )
}

export default GuestPastEventsList
