import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            {...props}
            fill={'currentColor'}
        >
            <path
                d="M200-200v-560 560zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v301q0 17-11.5 28T800-420q-17 0-28.5-11.5T760-460v-300H200v560h240q17 0 28.5 11.5T480-160q0 17-11.5 28.5T440-120H200zm120-160q17 0 28.5-11.5T360-320q0-17-11.5-28.5T320-360q-17 0-28.5 11.5T280-320q0 17 11.5 28.5T320-280zm0-160q17 0 28.5-11.5T360-480q0-17-11.5-28.5T320-520q-17 0-28.5 11.5T280-480q0 17 11.5 28.5T320-440zm0-160q17 0 28.5-11.5T360-640q0-17-11.5-28.5T320-680q-17 0-28.5 11.5T280-640q0 17 11.5 28.5T320-600zm320 160q17 0 28.5-11.5T680-480q0-17-11.5-28.5T640-520H480q-17 0-28.5 11.5T440-480q0 17 11.5 28.5T480-440h160zm0-160q17 0 28.5-11.5T680-640q0-17-11.5-28.5T640-680H480q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600h160zM440-320q0 17 11.5 28.5T480-280h2q17 0 28.5-11.5T522-320q0-17-11.5-28.5T482-360h-2q-17 0-28.5 11.5T440-320zm240 160h-80q-17 0-28.5-11.5T560-200q0-17 11.5-28.5T600-240h80v-80q0-17 11.5-28.5T720-360q17 0 28.5 11.5T760-320v80h80q17 0 28.5 11.5T880-200q0 17-11.5 28.5T840-160h-80v80q0 17-11.5 28.5T720-40q-17 0-28.5-11.5T680-80v-80z"/>
        </svg>
    )
}

export default SvgComponent
