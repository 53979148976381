import React from 'react';

const TextInputWrapper = ({icon: Icon, autoComplete, textarea, ...props}) => {
    return (
        <div className='flex flex-col w-full gap-2'>
            {props.label &&
                <label htmlFor={props.id}
                       className="block text-sm ml-0 font-medium text-slate-500 font-body">{props.label}</label>
            }
            {textarea ?
                <div className="flex items-center border rounded-xl border-slate-300 p-2">
                    <textarea
                        {...props}
                        id={props.id}
                        autoComplete={props.autoComplete}
                        className={`${!props.icon && 'mx-2'} flex-1 font-medium text-slate-800 placeholder-slate-500 outline-none`}
                        style={{border: 'none'}}
                    />
                </div>
                :
                <div className={`flex items-center border rounded-xl border-slate-300 p-2 ${props.disabled && "bg-gray-100 cursor-not-allowed"}`}>
                    {Icon && <Icon className="text-slate-500 mx-2 w-5 h-5"/>}
                    <input
                        {...props}
                        id={props.id}
                        type={props.type}
                        autoComplete={props.autoComplete}
                        className={`${!props.icon && 'mx-2'} ${props.disabled && 'cursor-not-allowed'} flex-1 font-medium text-slate-800 placeholder-slate-500 outline-none`}
                        style={{border: 'none'}}
                    />
                </div>
            }
        </div>
    );
};

export default TextInputWrapper;
