import React from "react";
import ListZeroState from "../components/ListZeroState";
import zero_state_image from '../../assets/img/3d-casual-life-clock-and-calendar.png'
import GuestEventItem from "./GuestEventItem";

function GuestUpcomingEventsList({events}) {

    return (
        <div className='flex flex-col'>
            {events.length > 0 &&
                <div className='flex flex-row justify-between'>
                    <p className='text-xl font-bold'>
                        Confirmed Events
                    </p>
                </div>
            }
            <div className='flex flex-col gap-2 pt-3'>
                <ListZeroState
                    image={zero_state_image}
                    imageClassName={'hue-rotate-[30deg]'}
                    title={'No events to show'}
                    description={"You aren't approved to attend any upcoming events yet."}
                />
                {events.map(event =>
                    <GuestEventItem
                        event={event}
                        key={event.id}
                        showDownloadTicket={true}
                    />
                )}
            </div>
        </div>
    )
}

export default GuestUpcomingEventsList
