export const gender_options = [{title: "Male"}, {title: "Female"}, {title: "Prefer Not to Specify"}]
export const group_options = [{title: 'VIP'}, {title: 'Tier 1'}, {title: 'Tier 2'}, {title: 'Tier 3'}]

export const status_options = [{title: 'Attending'}, {title: 'Pending Host'}, {title: 'Pending Organizer'}, {title: 'Approved'}, {title: 'Rejected'}]

export const host_options = [{title: "Mark Arnold"}, {title: 'Amy Redding'}, {title: 'Paul Anthony'}]

export const attendee_table_headers =["Name", "Host", "Group", "Phone", "Email", "Age", "Gender", "Instagram", "Is +1?", 'Response', '']

export const host_attendee_table_headers =["Name", "Host", "Group", "Phone", "Email", "Age", "Gender", "Instagram", 'Generic +1s', "Is +1?", 'Response', '']
export const invite_table_headers =["Name", "+1", "Is +1?", "Host", 'Response', 'Action']

export const host_invite_table_headers =["Name", "Phone", "Email", "Time", ""]

export const state_location_options = [
    {title: 'AL'}, // Alabama
    {title: 'AK'}, // Alaska
    {title: 'AZ'}, // Arizona
    {title: 'AR'}, // Arkansas
    {title: 'CA'}, // California
    {title: 'CO'}, // Colorado
    {title: 'CT'}, // Connecticut
    {title: 'DE'}, // Delaware
    {title: 'FL'}, // Florida
    {title: 'GA'}, // Georgia
    {title: 'HI'}, // Hawaii
    {title: 'ID'}, // Idaho
    {title: 'IL'}, // Illinois
    {title: 'IN'}, // Indiana
    {title: 'IA'}, // Iowa
    {title: 'KS'}, // Kansas
    {title: 'KY'}, // Kentucky
    {title: 'LA'}, // Louisiana
    {title: 'ME'}, // Maine
    {title: 'MD'}, // Maryland
    {title: 'MA'}, // Massachusetts
    {title: 'MI'}, // Michigan
    {title: 'MN'}, // Minnesota
    {title: 'MS'}, // Mississippi
    {title: 'MO'}, // Missouri
    {title: 'MT'}, // Montana
    {title: 'NE'}, // Nebraska
    {title: 'NV'}, // Nevada
    {title: 'NH'}, // New Hampshire
    {title: 'NJ'}, // New Jersey
    {title: 'NM'}, // New Mexico
    {title: 'NY'}, // New York
    {title: 'NC'}, // North Carolina
    {title: 'ND'}, // North Dakota
    {title: 'OH'}, // Ohio
    {title: 'OK'}, // Oklahoma
    {title: 'OR'}, // Oregon
    {title: 'PA'}, // Pennsylvania
    {title: 'RI'}, // Rhode Island
    {title: 'SC'}, // South Carolina
    {title: 'SD'}, // South Dakota
    {title: 'TN'}, // Tennessee
    {title: 'TX'}, // Texas
    {title: 'UT'}, // Utah
    {title: 'VT'}, // Vermont
    {title: 'VA'}, // Virginia
    {title: 'WA'}, // Washington
    {title: 'WV'}, // West Virginia
    {title: 'WI'}, // Wisconsin
    {title: 'WY'}, // Wyoming
];

export const TOAST_OPTIONS = {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
}
