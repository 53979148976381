import React, {useCallback, useEffect, useState} from "react";
import TextInputWrapper from "../../atoms/TextInputWrapper";
import SelectComponent from "../../atoms/SelectComponent";
import {state_location_options, TOAST_OPTIONS} from "../../../utils/consts";
import {Button} from "../../atoms/Button";
import SvgClose from "../../icons/SvgClose";
import DropzoneUploader from "../../atoms/DropzoneUploader";
import ConfirmationModal from "../../modals/ConfirmationModal";
import SvgAddPhoto from "../../icons/SvgAddPhoto";
import {setImageList, updateEventDetails} from '../../../api/events';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import {DateTime} from 'luxon';
import {toast} from "react-toastify";

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        userEvents: state.userReducer.userEvents,
    }
}

function OrganizerManageDetailsView(props) {
    const [eventName, setEventName] = useState('')
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [stateLocation, setStateLocation] = useState('')
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [images, setImages] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [confirmEmailUpdateModalOpen, setConfirmEmailUpdateModalOpen] = useState(false)

    const [detailsComplete, setDetailsComplete] = useState(false);
    const [timeComplete, setTimeComplete] = useState(false);
    const [promptComplete, setPromptComplete] = useState(false);
    const [imagesComplete, setImagesComplete] = useState(false);
    const [isValidEvent, setIsValidEvent] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        setDetailsComplete(eventName.trim() !== '' && description.trim() !== '' && address.trim() !== '' && city.trim() !== '' && stateLocation.trim() !== '');
    }, [eventName, description, address, city, stateLocation]);

    useEffect(() => {
        setTimeComplete(startTime !== '' && endTime !== '');
    }, [startTime, endTime]);

    useEffect(() => {
        setPromptComplete(prompt.trim() !== '');
    }, [prompt]);

    useEffect(() => {
        setImagesComplete(images.length > 0);
    }, [images]);

    useEffect(() => {
        const event = props.userEvents?.[props?.currentEventId];
        if (!event) return;

        setEventName(event?.title || "");
        setDescription(event?.description || "");
        setAddress(event?.location?.address || "");
        setCity(event?.location?.city || "");
        setStateLocation(event?.location?.state || "");
        setStartTime(DateTime.fromISO(event?.start_timestamp).toJSDate())
        setEndTime(DateTime.fromISO(event?.end_timestamp).toJSDate())
        setPrompt(event?.prompt);
        setImages(event?.image_urls)
        setIsValidEvent(DateTime.fromISO(event?.start_timestamp) > DateTime.now())
    }, [props.currentEventId])

    const handleEventNameChange = useCallback((event) => {
        setEventName(event.target.value);
    }, []);

    const handleDescriptionChange = useCallback((event) => {
        setDescription(event.target.value);
    }, []);

    const handleAddressChange = useCallback((event) => {
        setAddress(event.target.value);
    }, []);

    const handleCityChange = useCallback((event) => {
        setCity(event.target.value);
    }, []);

    const handlePromptChange = useCallback((event) => {
        setPrompt(event.target.value);
    }, []);

    const handleStateLocationChange = useCallback((value) => {
        setStateLocation(value);
    }, []);

    const handleStartTimeChange = useCallback((value) => {
        setStartTime(value);
    }, []);

    const handleEndTimeChange = useCallback((value) => {
        setEndTime(value);
    }, []);

    const handleImageUpload = async (image) => {
        try {
            const _images = [...images, image];
            setImages(_images);
            await toast.promise(
                setImageList(props.currentEventId, _images),
                {
                    pending: 'Uploading image...',
                    success: 'Image uploaded successfully!',
                    error: 'Failed to upload image!'
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to upload image.', TOAST_OPTIONS);
        }
    }

    const handleImageDelete = async (imageIndex) => {
        try {
            const _images = images.filter((img, i) => i !== imageIndex);
            setImages(_images);
            await toast.promise(
                setImageList(props.currentEventId, _images),
                {
                    pending: 'Deleting image...',
                    success: 'Image deleted successfully!',
                    error: 'Failed to delete image!'
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to delete image.', TOAST_OPTIONS);
        }
    }

    const openConfirmEmailUpdateModal = useCallback(() => {
        setConfirmEmailUpdateModalOpen(true);
    }, []);

    const closeConfirmEmailUpdateModal = useCallback(() => {
        setConfirmEmailUpdateModalOpen(false);
    }, []);

    const saveEventChanges = async () => {
        try {
            setSubmitLoading(true);
            const eventDetails = {
                title: eventName,
                description,
                location: {
                    address,
                    city,
                    state: stateLocation,
                },
                prompt,
                start_timestamp: DateTime.fromJSDate(startTime).toISO(),
                end_timestamp: DateTime.fromJSDate(endTime).toISO(),
            };
            const eventDetailsPromise = updateEventDetails(props?.currentEventId, eventDetails)
            toast.promise(
                eventDetailsPromise,
                {
                    pending: 'Updating event details...',
                    success: 'Event details updated successfully.',
                    error: 'Failed to update event details.'
                },
                TOAST_OPTIONS
            )
            const result = await eventDetailsPromise;
            setSubmitLoading(false);
            window.location.reload();
        } catch (error) {
            toast.error('Failed to update event details.')
        }
    }

    const invalidDates = !!startTime && !!endTime && startTime >= endTime;
    const allComplete = detailsComplete && timeComplete && promptComplete && imagesComplete && !invalidDates;
    return (
        <div className='flex flex-col flex-1 relative w-full items-center'>
            <div className='w-full md:w-[540px] flex flex-col gap-12 md:mt-12'>
                <div className='flex flex-col gap-3 '>
                    <div className='mt-5 md:hidden flex flex-row items-center justify-between'>
                        <p className='text-2xl font-semibold'>
                            Edit Event Details
                        </p>
                    </div>
                    <p className='text-lg font-bold hidden md:flex'>
                        Edit Event Details
                    </p>
                    <TextInputWrapper
                        label={'Event name'}
                        value={eventName}
                        placeholder={'Enter the name of your event'}
                        onChange={handleEventNameChange}
                    />
                    <TextInputWrapper
                        label={'Description'}
                        value={description}
                        placeholder={'Provide a brief description'}
                        onChange={handleDescriptionChange}
                        textarea
                    />
                    <TextInputWrapper
                        label={'Address'}
                        value={address}
                        placeholder={'Street address'}
                        onChange={handleAddressChange}
                    />
                    <div className='flex flex-row gap-3'>
                        <TextInputWrapper
                            label={'City'}
                            value={city}
                            placeholder={'City'}
                            onChange={handleCityChange}
                        />
                        <SelectComponent
                            outerLabel={'State'}
                            label={'State'}
                            value={stateLocation}
                            setValue={handleStateLocationChange}
                            options={state_location_options}
                            scrollable
                        />
                    </div>
                    {isValidEvent &&
                        <div className='flex flex-col md:flex-row gap-3 w-full'>
                            <div className="flex flex-col w-full md:flex-1">
                                <div className='text-sm font-medium text-slate-500 mb-1.5'>
                                    Start time
                                </div>
                                <DatePicker
                                    className={'' +
                                        'flex items-center border rounded-xl border-slate-300 p-2 px-4 font-medium placeholder:text-slate-500 w-full' +
                                        ' outline-none'
                                    }
                                    showTimeSelect
                                    selected={startTime}
                                    minDate={new Date()}
                                    dateFormat="Pp"
                                    onChange={handleStartTimeChange}
                                />
                            </div>
                            <div className="flex flex-col w-full md:flex-1">
                                <div className='text-sm font-medium text-slate-500 mb-1.5'>
                                    End time
                                </div>
                                <DatePicker
                                    className={'' +
                                        'flex items-center border rounded-xl border-slate-300 p-2 px-4 font-medium placeholder:text-slate-500 w-full' +
                                        ' outline-none'
                                    }
                                    showTimeSelect
                                    selected={endTime}
                                    minDate={new Date()}
                                    dateFormat="Pp"
                                    onChange={handleEndTimeChange}
                                />
                            </div>
                        </div>
                    }
                    {invalidDates &&
                        <p className="text-red-500 font-semibold ml-2">* Start time must be before end time</p>
                    }
                    <div className='flex flex-row gap-3'>
                    <TextInputWrapper
                            label={'Request prompt'}
                            value={prompt}
                            placeholder={'e.g. Why should you be there? What vibes do you bring to a party?'}
                            onChange={handlePromptChange}
                            textarea
                        />
                    </div>
                    <div className='flex flex-row gap-3 mx-auto mt-3'>
                        <Button onClick={openConfirmEmailUpdateModal} disabled={!allComplete} loading={submitLoading}>
                            Save Changes
                        </Button>
                    </div>
                </div>
                <div className='flex flex-col gap-3 '>
                    <p className='text-lg font-bold'>
                        Event Images
                    </p>
                    <DropzoneUploader
                        label={'Drag \'n\' drop images here, or click to select one'}
                        labelActive={'Drop the cover photo here ...'}
                        Icon={SvgAddPhoto}
                        onFileUpload={handleImageUpload}
                        doesAcceptImages
                    />
                    <div className='grid grid-cols-2 gap-4'>
                        {images.map((image, i) =>
                            <div
                                key={`event-details-image-${i}`}
                                className='flex flex-col relative items-center justify-center border border-gray-100 rounded-xl overflow-hidden'>
                                <img src={image} alt={'gallery'} className='max-w-[260px]'/>
                                <Button className='absolute top-2 right-2' size={'icon'} radius={'full'}
                                        variant={'destructive'}
                                        onClick={() => handleImageDelete(i)}
                                        icon={SvgClose}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmationModal
                isOpen={confirmEmailUpdateModalOpen}
                confirmText={'Update'}
                onConfirm={saveEventChanges}
                close={closeConfirmEmailUpdateModal}
                title={'Update Event?'}
                description={'Are you sure you want to update this event?'}
                confirmVariant={'default'}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(OrganizerManageDetailsView);
