import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import QRCode from "react-qr-code";
import Marquee from "react-fast-marquee";
import SvgLogo from "../atoms/SvgLogo";
import {DateTime} from "luxon";
import GroupTag from "../atoms/GroupTag";
import SvgAppleWallet from '../icons/SvgAppleWallet';
import { getGuestPass } from '../../api/guests';
import { connect } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const mapStateToProps = (state) => {
  return {
    mobile: state.userReducer.mobile,
  }
}

function QrCodeModal(props) {

    const formattedDate = DateTime.fromISO(props.eventStart, {zone: 'utc'})
        .toLocal() // Convert to local time zone
        .toFormat('LLL dd, yyyy \'at\' h:mm a'); // Format the date and time

    const handleAddToWallet = async () => {
      await getGuestPass(props.eventId, props.profileId)
    }

    const handlePdfDownload = async () => {
      const pdfContent = document.getElementById("pdf-content");
      html2canvas(pdfContent).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save('pass.pdf')
      }).catch(err => console.log(err))
    }

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            noPadding
        >
            <div className="flex flex-col items-center md:gap-5 justify-center relative p-5">
              <div id="pdf-content" className="flex flex-col items-center md:gap-5 justify-center relative p-5">
                <p className='text-2xl font-medium mb-3 md:mb-0'>
                    Your digital wristband
                </p>
                <div className='text-center min-w-[50%] bg-primary-50/30 border rounded-lg p-2 border-primary'>
                    <p className='font-semibold'>
                        {props.eventTitle}
                    </p>
                    <p className='font-semibold text-sm text-primary'>
                        {formattedDate}
                    </p>
                </div>
                <div className='relative w-full flex flex-col items-center'>
                    <div
                        className='absolute left-0 top-0 bottom-0 gap-3 flex flex-col justify-center z-0 opacity-15 right-0'>
                        <Marquee speed={20} gradient gradientWidth={40}>
                            {[1, 2, 3, 4, 5, 6].map(i =>
                                <SvgLogo className={'h-10 w-16'} key={'a' + i}/>
                            )}
                        </Marquee>
                        <Marquee direction={'right'} speed={20} gradient gradientWidth={40}>
                            {[1, 2, 3, 4, 5, 6].map(i =>
                                <SvgLogo className={'h-10 w-16'} key={'a' + i}/>
                            )}
                        </Marquee>
                        <Marquee speed={20} gradient gradientWidth={40}>
                            {[1, 2, 3, 4, 5, 6].map(i =>
                                <SvgLogo className={'h-10 w-16'} key={'a' + i}/>
                            )}
                        </Marquee>
                    </div>
                    <div className={'relative shadow-xl border border-slate-200 p-3 my-5 bg-red-50/50 rounded-[19px]'}>
                        <QRCode
                            size={200}
                            style={{width: 200, height: 200}}
                            value={JSON.stringify({
                                eventId: props.eventId,
                                profileId: props.profileId,
                                timestamp: new Date(),
                                group: props.group
                            })}
                            bgColor={'transparent'}
                            fgColor={'#ff7360'}
                            viewBox={`0 0 200 200`}
                        />
                        <svg
                            className='absolute animate-pulse overflow-visible top-0 left-0 right-0 bottom-0  z-[2] w-full h-full'
                            viewBox="0 0 200 200"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="200" rx="16" ry="16" height="200" stroke="currentColor"
                                  strokeWidth="1.5" fill="none"
                                  className="stroke-primary-100 animate-dashb" strokeDasharray="90%"/>
                            <rect x="0.5" y="0.5" width="200" rx="16" ry="16" height="200" stroke="currentColor"
                                  strokeWidth="1.5" fill="none"
                                  className="stroke-primary animate-dash" strokeDasharray="60%"/>
                        </svg>
                    </div>
                </div>
                <div className='flex flex-col items-center gap-3 -mt-1 mb-3'>
                    <p className='font-semibold text-xl'>
                        {props.guestFirstName} {props.guestLastName}
                    </p>
                    <GroupTag group={props.group}/>
                    {/* Apple wallet/PDF tickets are not secure, hide for now */}
                    {true && (props.mobile ?
                        <button onClick={handleAddToWallet}>
                            <SvgAppleWallet size={120}/>
                        </button>
                        :
                        <Button onClick={handlePdfDownload} size={'sm'}>
                            Download PDF
                        </Button>
                    )}
                </div>
              </div>
                <div className='flex flex-col gap-3 w-full cursor-pointer z-10'>
                    <Button onClick={props.close} variant={'secondary'}>
                        Close
                    </Button>
                </div>
            </div>
        </ModalComponent>
    );
}

export default connect(mapStateToProps, null)(QrCodeModal);
