import ModalComponent from '../atoms/ModalComponent';
import {Button} from '../atoms/Button';

export default function BulkUpdloadHelperModal(props) {

    const guests = [
        {
            name: 'John Smith',
            email: 'john@web.com',
            phone: 6504446555,
        },
        {
            name: 'Arnold Gary',
            email: 'arnold@web.com',
            phone: '',
        },
        {
            name: 'Phil Herd',
            email: 'phil@web.com',
            phone: 6505556555,
        },
        {
            name: 'Stacy Gregory',
            email: '',
            phone: 6504446555,
        }
    ]
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'md'}
            toggle={props.close}
            header={'How to Bulk Upload'}
            headerToggle={true}
        >
            <div>
                <p>

                    Bulk uploading is simple. Just add a raw .csv file in this format: name, email, phone number. The
                    first row should be headers with those labels. You must have either email or phone for each person,
                    and phone numbers must be 10 digits no special characters.
                </p>
                <br/>
                <p>
                    Here's what that might look like:
                </p>
                <div className='w-full border border-slate-300 border-separate rounded-xl mt-3'>
                    <div className='border-separate border-b border-slate-200 flex flex-row p-2'>
                        <div className='flex-1'>
                            name
                        </div>
                        <div className='flex-1'>
                            email
                        </div>
                        <div className='flex-1'>
                            phone
                        </div>
                    </div>
                    {guests.map(item =>
                        <div className='flex flex-row flex-1 p-2'>
                            <div className='flex-1'>
                                {item.name}
                            </div>
                            <div className='flex-1'>
                                {item.email}
                            </div>
                            <div className='flex-1'>
                                {item.phone}
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex w-full mt-4 justify-center">
                    <Button
                        onClick={props.close}
                        variant={'secondary'}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </ModalComponent>
    )
}
