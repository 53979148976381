import React, {useState, useEffect, useRef} from 'react';
import {Button} from './Button';
import {wait} from "@testing-library/user-event/dist/utils";

const AnimatedTabs = ({tabs, activeTab, className, changeTab, textClassName}) => {
    const indicatorRef = useRef(null);
    const [state, setState] = useState({
        indicatorWidth: 0,
        indicatorLeft: 0,
        activeTabIndex: 0,
        routeChanging: false,
        hoveringIndex: null,
    });

    const {indicatorWidth, indicatorLeft, activeTabIndex} = state;

    const onChange = () => {
        const activeTabIndex = tabs.findIndex((item) => item.title === activeTab);
        if (activeTabIndex !== -1) {
            setState((prevState) => ({
                ...prevState,
                activeTabIndex,
                routeChanging: false,
            }));
            updateIndicatorPosition();
        }
    };

    useEffect(() => {
        updateIndicatorPosition();
        // Setup resize event listener
        window.addEventListener('resize', updateIndicatorPosition);
        return () => window.removeEventListener('resize', updateIndicatorPosition);
    }, [activeTab, tabs]);

    useEffect(() => {
        onChange();
    }, [activeTab]);

    useEffect(() => {
        if (state.routeChanging || activeTab !== activeTab) {
            onChange();
        }
    }, [state.routeChanging, activeTab]);

    const updateIndicatorPosition = () => {
        const activeTabButton = indicatorRef.current;
        if (activeTabButton) {
            const tabWidth = activeTabButton.offsetWidth;
            const tabLeft = activeTabButton.offsetLeft;

            setState((prevState) => ({
                ...prevState,
                indicatorWidth: tabWidth,
                indicatorLeft: tabLeft,
            }));
        }
    };

    const handleTabClick = (title, index) => {
        setState((prevState) => ({
            ...prevState,
            routeChanging: true,
            activeTabIndex: index,
        }));
        changeTab(title);
    };

    useEffect(() => {
        wait(10).then(() =>
            updateIndicatorPosition()
        )
    }, []);

    return (
        <div className={`relative mr-auto ${className}`}>
            <div
                className="absolute bottom-0 top-0 z-[0] bg-gray-100 rounded-xl"
                style={{
                    width: indicatorWidth,
                    left: indicatorLeft,
                    transition: '0.3s',
                    zIndex: 0,
                }}
            ></div>
            <div className="flex flex-row justify-around flex-1 relative md:gap-2" style={{zIndex: 2}}>
                {tabs.map((item, index) => (
                    <Button
                        key={`animated-tabs-button${index}`}
                        variant={activeTabIndex === index ? 'ghost' : 'ghost'}
                        size={'defaultRounded'}
                        className={activeTabIndex === index && 'hover:bg-transparent'}
                        ref={activeTabIndex === index ? indicatorRef : null}
                        onClick={() => handleTabClick(item.title, index)}
                        onMouseEnter={() => setState((prevState) => ({...prevState, hoveringIndex: index}))}
                        onMouseLeave={() => setState((prevState) => ({...prevState, hoveringIndex: null}))}
                    >
                        {item.icon && (
                            <item.icon
                                style={{transition: 'all 100ms ease'}}
                                className={`w-5 h-5 mr-1 ${activeTabIndex === index ? 'scale-110 opacity-100 text-white' : state.hoveringIndex === index ? 'opacity-100 text-neutral-400' : 'scale-100'}`}
                            />
                        )}
                        <div className='relative'>
                            <p
                                className={`py-1 px-2 md:px-3 font-body text-xs sm:block md:hidden font-bold ${
                                    activeTabIndex === index ? 'text-gray-800' : state.hoveringIndex === index ? 'text-neutral-400' : ''
                                }  ${textClassName}`}
                            >
                                {item.title}
                            </p>
                            <p
                                className={`py-1 px-1 md:px-3 font-body text-[1.2rem] hidden md:block font-bold ${
                                    activeTabIndex === index ? 'text-gray-800' : state.hoveringIndex === index ? 'text-neutral-400' : ''
                                } ${textClassName}`}
                            >
                                {item.title}
                            </p>
                            {!!item.badge &&
                                <div
                                    className='absolute -top-2 -right-4 bg-red-500 mr-3 h-4 min-w-4 px-2 rounded-full text-white text-xs font-semibold'>
                                    <p>
                                        {item.badge}
                                    </p>
                                </div>
                            }
                        </div>
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default AnimatedTabs;
