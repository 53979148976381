import React, {useState, useEffect} from 'react';
import TextInputWrapper from "../atoms/TextInputWrapper";
import SelectComponent from "../atoms/SelectComponent";
import {gender_options, group_options, host_options, status_options} from "../../utils/consts";
import SvgSearch from "../icons/SvgSearch";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        guests: state.userReducer.guests,
        groups: state.userReducer.userEvents?.[state.userReducer?.currentEventId]?.groups || [],
    }
}

function GuestListTableFilters({
                                   searchFilter,
                                   handleFilterChange,
                                   genderFilter,
                                   groupFilter,
                                   statusFilter,
                                   hostFilter,
                                   hideFilters,
                                   currentEventId,
                                   guests,
                                   groups,
                               }) {
    const [groupOptions, setGroupOptions] = useState([]);
    const [hostOptions, setHostOptions] = useState([]);

    useEffect(() => {
        const _guests = guests?.[currentEventId] || [];
        // setGroupOptions([..._guests.reduce((s, g) => s.add(g.group), new Set())].map(e => ({title: e})).filter(e => !!e.title));
        setGroupOptions(groups.map(g => ({title: g})));
        setHostOptions([..._guests.filter(g => !!g.host).reduce((s, g) => s.add(`${g.host?.profile.first_name} ${g.host?.profile.last_name}`), new Set())].map(e => ({title: e})));
    }, [currentEventId, guests])

    return (
        <div className='flex flex-row items-center gap-3 gap-y-2 mb-4 flex-wrap'>
            <div className='w-full md:w-[300px]'>
                <TextInputWrapper
                    label={'Search'}
                    value={searchFilter}
                    placeholder={'Search attendees...'}
                    onChange={(e) => handleFilterChange('searchFilter')(e.target.value)}
                    autoComplete="given-name"
                    icon={SvgSearch}
                />
            </div>
            <div className='flex flex-row items-center flex-wrap gap-3 gap-y-2'>
                <SelectComponent
                    outerLabel={'Group'}
                    label={'All'}
                    value={groupFilter}
                    setValue={handleFilterChange('groupFilter')}
                    options={groupOptions}
                    showClear
                />
                <SelectComponent
                    outerLabel={'Gender'}
                    label={'All'}
                    value={genderFilter}
                    setValue={handleFilterChange('genderFilter')}
                    options={gender_options}
                    showClear
                />
                {!hideFilters.includes('host') &&
                    <SelectComponent
                        outerLabel={'Host'}
                        label={'All'}
                        value={hostFilter}
                        setValue={handleFilterChange('hostFilter')}
                        options={hostOptions}
                        showClear
                    />
                }
                {!hideFilters.includes('status') &&
                    <SelectComponent
                        outerLabel={'Status'}
                        label={'All'}
                        value={statusFilter}
                        setValue={handleFilterChange('statusFilter')}
                        options={status_options}
                        showClear
                    />
                }
            </div>
        </div>
    );
}

export default connect(mapStateToProps, null)(GuestListTableFilters);
