import invited_image from "../../assets/img/3d-casual-life-megaphone-hiring.png";
import approved_image from "../../assets/img/casual-life-3d-round-check-mark-yellow.png";
import {Button} from "../atoms/Button";
import React from "react";
import parsePhoneNumber from "libphonenumber-js";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    mobile: state.userReducer.mobile,
  }
}

function GuestAcceptorDeclineInviteView({
                                            isGuestPlusOne,
                                            userObject,
                                            myHostId,
                                            updateGuestStatus,
                                            openConfirmRejectInviteModal,
                                            mobile,
                                        }) {
    return (
        <div className='w-full flex flex-col gap-3 md:w-1/2 mx-auto text-center mt-5'>
            {/*{isGuestPlusOne ?*/}
            {/*    <img src={invited_image}*/}
            {/*         alt={'invite'} className='w-1/3 md:w-1/2 mx-auto my-5 hue-rotate-[390deg] saturate-200'/>*/}
            {/*    :*/}
            {/*    <img src={approved_image}*/}
            {/*         alt={'invite'} className='w-1/3 md:w-[100px] mx-auto my-5 hue-rotate-[-32deg] saturate-[1.05]'/>*/}
            {/*}*/}
            {isGuestPlusOne ?
                <p className='text-lg font-semibold text-balance'>
                    You've been invited by {userObject?.profile?.first_name} {userObject?.profile?.last_name}
                </p>
                :
                <p className='text-lg font-semibold text-balance'>
                    You've been approved!
                </p>
            }
            <p>
                {isGuestPlusOne ?
                    `${userObject?.profile?.first_name} ${userObject?.profile?.last_name} is requesting you as a +1 for this event; the Host must confirm prior to your addition to Guest List. The Host will be able to see some of your details.`
                    : !!myHostId ?
                        `You’ve been invited by ${`${userObject?.profile?.first_name} ${userObject?.profile?.last_name}`} to this event. ${mobile ? "Tap" : "Click"} below to confirm your
                                        spot on the Guest List.`
                        :
                        `You have been approved to attend this event. ${mobile ? "Tap": "Click"} below to confirm your spot on the Guest List.`
                }
            </p>
            <div className='flex flex-col border-primary rounded-xl border bg-primary-50/30 py-2'>
                <p className='text-xs text-gray-500'>
                    {isGuestPlusOne ? 'Event guest' : 'Your host'}
                </p>
                <p className='font-semibold'>
                    {userObject?.profile?.first_name} {userObject?.profile?.last_name}
                </p>
                <p className='text-xs text-primary-500'>
                    {parsePhoneNumber(`+1${userObject?.profile?.phone_number}` || "")?.formatNational() || "No phone"}
                </p>
            </div>
            <Button variant={'black'}
                    onClick={() => updateGuestStatus('Attending')}>
                Accept invite
            </Button>
            <Button onClick={openConfirmRejectInviteModal} variant={'destructive'}>
                Decline invite
            </Button>
        </div>
    )
}

export default connect(mapStateToProps, null)(GuestAcceptorDeclineInviteView)
