import React, {useCallback, useState} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import PhoneInputWrapper from "../atoms/PhoneInputWrapper";
import TextInputWrapper from "../atoms/TextInputWrapper";
import CheckboxItem from "../atoms/CheckboxItem";
import { addHost } from '../../api/hosts';
import {TOAST_OPTIONS} from "../../utils/consts";
import {toast} from "react-toastify";

function AddHostModal(props) {
    const [phone, setPhone] = useState('');
    const [amount, setAmount] = useState('')
    const [requiresApproval, setRequiresApproval] = useState(true)

    const handleAmountChange = useCallback((event) => {
        setAmount(event.target.value);
    }, [])

    const handlePhoneChange = useCallback((phone) => {
        setPhone(phone);
    }, [])

    const addNewHost = () => {
        if (!phone) {
            toast.warn("Phone number is required.", TOAST_OPTIONS);
            return;
        }

        toast.promise(
            addHost(props.currentEventId, phone, {
                invite_limit: Number(amount),
                requires_approval: requiresApproval,
                frozen: false
            }),
            {
                pending: 'Adding new host...',
                success: 'New host added successfully!',
                error: 'Failed to add new host.'
            },
            TOAST_OPTIONS
        ).then(() => {
            setPhone('');
            setAmount('');
            setRequiresApproval(true);
            props.close();
        }).catch(error => {
            console.error("Error adding new host:", error);
        });
    }


    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={'Add a new host'}
            description={'Add a phone number and allot a set amount of additional invites for your host.'}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={addNewHost}>
                        Add host
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col gap-3 w-full'>
                <PhoneInputWrapper
                    label={'Phone *'}
                    value={phone}
                    onChange={handlePhoneChange}
                />
                <TextInputWrapper
                    label={'Allotted # of additional invites'}
                    value={amount}
                    placeholder={'ie.) 50'}
                    type={'number'}
                    onChange={handleAmountChange}
                />
                <CheckboxItem
                    name={'requires-approval'}
                    label={'Requires approval'}
                    subLabel={'Uncheck to allow this user to add guests to the list without approval'}
                    checked={requiresApproval}
                    onChange={(e) => setRequiresApproval(!requiresApproval)}
                />
            </div>
        </ModalComponent>
    );
}

export default AddHostModal;
