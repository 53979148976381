import React, {useCallback, useState, useEffect} from 'react';
import {sample_event, sample_summary_data} from "../../utils/dummy";
import OrganizerGuestListView from "../../components/organizer_view/views/OrganizerGuestListView";
import AnimatedTabs from "../../components/atoms/AnimatedTabs";
import EventPageHeaderComponent from "../../components/components/EventPageHeaderComponent";
import OrganizerHostManagementView from "../../components/organizer_view/views/OrganizerHostManagementView";
import OrganizerManageDetailsView from "../../components/organizer_view/views/OrganizerManageDetailsView";
import OrganizerScannerManagementView from "../../components/organizer_view/views/OrganizerScannerManagementView";
import OrganizerApprovalsTableView from "../../components/organizer_view/views/OrganizerApprovalsTableView";
import {getGuestsForEvent, getHostsForEvent, getInvitedUsers} from '../../api/events';
import {connect} from 'react-redux';
import Spinner from "../../components/atoms/Spinner";

const mapStateToProps = (state) => {
    return {
        guests: state.userReducer.guests,
        currentEventId: state.userReducer.currentEventId,
    }
}

function OrganizerEventPage(props) {
    const [filteredEventGuests, setFilteredEventGuests] = useState([]);
    const [guests, setGuests] = useState([]);
    const [invitedGuests, setInvitedGuests] = useState([]);
    const [eventLoading, setEventLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('Guest List');
    const [numNotifications, setNumNotifications] = useState(0);

    useEffect(() => {
        getHostsAndGuests();
    }, [props.event])

    useEffect(() => {
        const _guests = (props.guests?.[props.event.id] || []).filter(g => !!g.status);
        const primaryGuests = _guests.filter(guest => guest.is_primary_guest);
        setFilteredEventGuests(primaryGuests)
    }, [props.guests, props.event.id])


    const getHostsAndGuests = async () => {
        setEventLoading(true)

        const guests = await getGuestsForEvent(props.event.id);
        setGuests(guests);

        const hosts = await getHostsForEvent(props.event.id);

        const invitedUsers = await getInvitedUsers(props.event.id);
        setInvitedGuests(invitedUsers);

        setEventLoading(false);
    }

    useEffect(() => {
      const numNotifications = props.guests[props.currentEventId]?.filter(g => g.status === 'Pending Organizer' && !!g.is_new_notification).length;
      setNumNotifications(numNotifications);
    }, [props.guests, props.currentEventId])

    const switchView = useCallback(() => {
        switch (activeTab) {
            case 'Guest List':
                return (
                    <OrganizerGuestListView setFilteredGuests={() => {
                    }}/>
                )
            case 'Approvals':
                return (
                    <OrganizerApprovalsTableView event={props.event}/>
                )
            case 'Hosts':
                return (
                    <OrganizerHostManagementView/>
                )
            case 'Event Details':
                return (
                    <OrganizerManageDetailsView event={props.event}/>
                )
            case 'Scanners':
                return (
                    <OrganizerScannerManagementView event={props.event}/>
                )
            default:
                return null
        }
    }, [activeTab, props.event])

    return eventLoading ?
        <div className='flex flex-col items-center justify-center min-h-[100dvh]'>
            <Spinner size={24}/>
        </div>
        : (
            <div className='flex flex-col flex-1 w-full p-5'>
                <EventPageHeaderComponent
                    event={props.event}
                    filteredGuests={filteredEventGuests}
                    invitedGuests={invitedGuests}
                />
                <div className='flex flex-col flex-1'>
                    <div className='sticky top-14 bg-white z-[20] flex flex-col border-b border-gray-200'>
                        <div className='mr-auto'>
                            <AnimatedTabs
                                activeTab={activeTab}
                                changeTab={setActiveTab}
                                tabs={[
                                    {title: 'Guest List'},
                                    {
                                        title: 'Approvals',
                                        badge: numNotifications,
                                    },
                                    {title: 'Hosts'},
                                    {title: 'Scanners'},
                                    {title: 'Event Details'},
                                ]}
                                className={'my-3'}
                            />
                        </div>
                    </div>
                    {switchView()}
                </div>
            </div>
        )
}

export default connect(mapStateToProps, null)(OrganizerEventPage);
