import TableHeaders from "../../atoms/TableHeaders";
import OrganizerGuestListTableItem from "./OrganizerGuestListTableItem";
import React, {useCallback, useState} from "react";
import {attendee_table_headers, host_attendee_table_headers} from "../../../utils/consts";
import EditAttendeeModalOrganizer from "../../modals/EditAttendeeModalOrganizer";
import EditAttendeeModalHost from "../../modals/EditAttendeeModalHost";
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    event: state.userReducer.userEvents[state.userReducer.currentEventId],
  }
}

function OrganizerGuestListTable({attendees, viewingAs, event}) {
    const [isEditAttendeeModalOpen, setIsEditAttendeeModalOpen] = useState(false)
    const [selectedGuest, setSelectedGuest] = useState({});

    const openEditAttendeeModal = useCallback((attendee) => {
        setSelectedGuest(attendee)
        setIsEditAttendeeModalOpen(true)
    }, [])

    const closeEditAttendeeModal = useCallback(() => {
        setIsEditAttendeeModalOpen(false)
    }, [])

    // map column name to required scope
    const colScopes = {
      'Age': 'age',
      'Gender': 'gender',
      'Instagram': 'social',
      'Is +1?': 'plusOne',
      'Generic +1s': 'plusOne',
    }
    const headers = (viewingAs === 'host' ? host_attendee_table_headers : attendee_table_headers).filter(col => {
      return !colScopes[col] || !!event.guest_scopes[colScopes[col]];
    }).filter(col => viewingAs === 'host' ? !["Host"].includes(col): true)
    return (
        <div
            className='w-full flex justify-start overflow-y-hidden overflow-x-scroll border border-gray-200 rounded-xl'>
            <table className='w-full'>
                <TableHeaders columns={headers}/>
                <tbody>
                {attendees.length > 0 ?
                    attendees.map((attendee, index) => (
                        <OrganizerGuestListTableItem
                            key={`organizer-guest-list-${index}`}
                            attendee={attendee}
                            index={index}
                            openEditAttendeeModal={openEditAttendeeModal}
                            viewingAs={viewingAs}
                            scopes={event.guest_scopes}
                        />
                    ))
                    :
                    <tr className="h-[100px] text-center  bg-gray-50">
                        <td colSpan="99">
                            No guests are attending this event
                        </td>
                    </tr>
                }
                </tbody>
            </table>
            {viewingAs === 'organizer' &&
                <EditAttendeeModalOrganizer
                    isOpen={isEditAttendeeModalOpen}
                    close={closeEditAttendeeModal}
                    attendee={selectedGuest}
                />
            }
            {viewingAs === 'host' &&
                <EditAttendeeModalHost
                    isOpen={isEditAttendeeModalOpen}
                    close={closeEditAttendeeModal}
                    attendee={selectedGuest}
                    event={event}
                />
            }
        </div>
    )
}

export default connect(mapStateToProps, null)(OrganizerGuestListTable);
