import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={5}
    className="feather feather-key"
    viewBox="0 0 256 256"
    {...props}
  >
    <path d="M128,128h88a88,88,0,1,1-20.11-56" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="24"/>
  </svg>
)
export default SvgComponent


