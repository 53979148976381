import React, {useCallback, useState, useEffect} from 'react';
import {calculateAge} from "../../../utils/helpers";
import SvgCheck from "../../icons/SvgCheck";
import {Button} from "../../atoms/Button";
import SvgMoreVertical from "../../icons/SvgMoreVertical";
import SvgSubDirectoryRight from "../../icons/SvgSubDirectoryRight";
import parsePhoneNumber from 'libphonenumber-js';
import profilePage from "../../../pages/ProfilePage";
import GroupTag from "../../atoms/GroupTag";

function OrganizerGuestListTableItem({attendee, index, openEditAttendeeModal, viewingAs, scopes}) {
    const [plusOne, setPlusOne] = useState(null);

    useEffect(() => {
        setPlusOne(attendee.is_primary_guest ? attendee.plusOne : attendee);
    }, [attendee])

    const renderCell = useCallback((key, customRender = null) => {
        const content = key.split(".").reduce((o, k) => o?.[k], attendee);
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs truncate inline'>{content || '--'}</div>;
    }, [attendee]);

    const renderPlusOneCell = useCallback((plusOne, key, customRender = null) => {
        const content = key.split(".").reduce((o, k) => o?.[k], plusOne);
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs inline truncate'>{content || '--'}</div>;
    }, [plusOne]);

    return (
        <>
            {attendee.is_primary_guest &&
                <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} text-xs md:text-sm`}>
                    <td className={`px-2 md:px-5 py-3 sticky left-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                        {renderCell('profile', (profile) => {
                                return (
                                    <div className='max-w-xs truncate font-semibold'>
                                        {`${profile?.first_name} ${profile?.last_name}`}
                                    </div>
                                )
                            }
                        )}
                    </td>
                    {viewingAs === 'organizer' &&
                        <td className='px-2 md:px-5 py-2 md:py-3 whitespace-nowrap'>{renderCell("host.profile.display_name", (displayName) =>
                            displayName || `${renderCell('host.profile.first_name', content => content || "")} ${renderCell('host.profile.last_name', content => content || "")}`
                        )}</td>
                    }
                    <td className='px-2 md:px-5 py-2 md:py-3'>
                        <div className='w-min whitespace-nowrap'>
                            <GroupTag group={attendee?.group}/>
                        </div>
                    </td>
                    <td className='whitespace-nowrap px-2 md:px-5 py-2 md:py-3 '>{renderCell('profile.phone_number', (phoneNumber) =>
                        <p>
                            {parsePhoneNumber(`+1${phoneNumber}` || "")?.formatNational() || "No phone"}
                        </p>
                    )}</td>
                    <td className='px-2 md:px-5 py-2 md:py-3'>{renderCell('profile.email')}</td>
                    {
                        !!scopes.age &&
                        <td className='px-2 md:px-5 py-2 md:py-3'>
                            {renderCell('profile.date_of_birth', (dob) => (
                                <div className='max-w-xs truncate'>
                                    {!!dob ? calculateAge(dob) : "--"}
                                </div>
                            ))}
                        </td>
                    }
                    {
                        !!scopes.gender &&
                        <td className='px-2 md:px-5 py-2 md:py-3'>
                            {renderCell('profile.gender', (gender) => (
                                <div
                                    className='max-w-xs truncate'>{gender === 'Prefer Not To Say' || !gender ? '--' : gender === 'Male' ? 'M' : 'F'}</div>
                            ))}
                        </td>
                    }
                    {
                        !!scopes.social &&
                        <td className='px-2 md:px-5 py-2 md:py-3'>{renderCell('profile.instagram', (handle) =>
                            <a
                                href={`https://www.instagram.com/${handle}`}
                                target="_blank"
                                className="text-cyan-500"
                            >
                                {!!handle && `@${handle}`}
                            </a>
                        )}
                        </td>
                    }
                    {
                        viewingAs === 'host' && !!scopes.plugOne &&
                        <td className='px-2 md:px-5 py-2 md:py-3'>{attendee.invite_limit === 0 ? 'No' : attendee.invite_limit}</td>
                    }
                    {
                        !!scopes.plusOne &&
                        <td className='px-2 md:px-5 py-2 md:py-3'>
                            {renderCell('is_primary_guest', (isPrimaryGuest) => (
                                <div className='max-w-xs truncate w-10'>{isPrimaryGuest ? '' : <SvgCheck/>}</div>
                            ))}
                        </td>
                    }
                    <td className='px-2 md:px-5 py-2 md:py-3'>
                        {renderCell('justification', (justification) => (
                            <div className='max-w-xs truncate'>
                                {justification}
                            </div>
                        ))}
                    </td>
                    <td className={`px-2 py-3 sticky right-0`}>
                        <div className='flex flex-row gap-3'>
                            <Button
                                icon={SvgMoreVertical}
                                onClick={() => openEditAttendeeModal(attendee)}
                                variant={'secondary'}
                                size={'icon'}
                                radius={'full'}
                                className={'shadow-lg'}
                            />
                        </div>
                    </td>
                </tr>
            }
            {
                attendee?.plusOne?.map(plusOne =>
                    <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} relative text-xs md:text-sm`}>
                        <td className={`px-2 md:px-5 py-2 md:py-3 sticky left-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                            <div className='ml-2 flex flex-row items-center gap-1'>
                                <SvgSubDirectoryRight className={'text-gray-400 w-3 h-3 md:w-5 md:h-5'}/>
                                <p className={'text-gray-400'}>
                                    +1
                                </p>
                                {renderPlusOneCell(plusOne, '', () => (
                                    <div className='max-w-xs truncate font-semibold'>
                                        {`${plusOne.profile?.first_name} ${plusOne.profile?.last_name}`}
                                    </div>
                                ))}
                            </div>
                        </td>
                        {viewingAs === 'organizer' &&
                            <td className='px-2 md:px-5 py-2 md:py-3 whitespace-nowrap'>{renderPlusOneCell(plusOne, "host.profile.display_name", (displayName) =>
                                displayName || `${renderPlusOneCell(plusOne, 'host.profile.first_name', content => content || "")} ${renderPlusOneCell(plusOne, 'host.profile.last_name', content => content || "")}`
                            )}</td>
                        }
                        {/* +1s are in whatever group their parent guest is */}
                        <td className='px-2 md:px-5 py-2 md:py-3'>
                            <div className='w-min whitespace-nowrap'>
                                <GroupTag group={plusOne?.group}/>
                            </div>
                        </td>
                        <td className='whitespace-nowrap px-2 md:px-5 py-2 md:py-3'>{renderPlusOneCell(plusOne, 'profile.phone_number', (phoneNumber) =>
                            <p>
                                {parsePhoneNumber(`+1${phoneNumber}` || "")?.formatNational() || "No phone"}
                            </p>
                        )}</td>
                        <td className='px-2 md:px-5 py-2 md:py-3'>{renderPlusOneCell(plusOne, 'profile.email')}</td>
                        {!!scopes.age &&
                            <td className='px-2 md:px-5 py-2 md:py-3'>
                                {renderPlusOneCell(plusOne, 'profile.date_of_birth', (dob) => (
                                    <div className='max-w-xs truncate'>
                                        {!!dob ? calculateAge(dob) : "--"}
                                    </div>
                                ))}
                            </td>
                        }
                        {!!scopes.gender &&
                            <td className='px-2 md:px-5 py-2 md:py-3'>
                                {renderPlusOneCell(plusOne, 'profile.gender', (gender) => (
                                    <div
                                        className='max-w-xs truncate'>{gender === 'Prefer Not To Say' ? '--' : gender === 'Male' ? 'M' : 'F'}</div>
                                ))}
                            </td>
                        }
                        {!!scopes.social &&
                            <td className='px-2 md:px-5 py-2 md:py-3'>{renderPlusOneCell(plusOne, 'profile.instagram', (handle) =>
                                <a
                                    href={`https://www.instagram.com/${handle}`}
                                    target="_blank"
                                    className="text-cyan-500"
                                >
                                    {!!handle && `@${handle}`}
                                </a>
                            )}
                            </td>
                        }
                        {viewingAs === 'host' &&
                            <td className='px-2 md:px-5 py-2 md:py-3'>{'--'}</td>  // +1s cannot have generic +1s
                        }
                        {!!scopes.plusOne &&
                            <td className='px-2 md:px-5 py-2 md:py-3'>
                                {renderPlusOneCell(plusOne, 'isPlusOne', () => (
                                    <div className='max-w-xs truncate w-10'><SvgCheck/></div>
                                ))}
                            </td>
                        }
                        <td className='px-2 md:px-5 py-2 md:py-3'>
                            {renderCell('justification', (justification) => (
                                <div className='max-w-xs truncate'>
                                    {justification}
                                </div>
                            ))}
                        </td>
                        <td className={`px-2 py-3 sticky right-0`}>
                            <div className='flex flex-row gap-3'>
                            </div>
                        </td>
                    </tr>
                )
            }
        </>
    )
        ;
}

export default OrganizerGuestListTableItem;
