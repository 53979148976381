import {sample_guest_events} from "../../utils/guest_dummy";
import GuestEventItem from "./GuestEventItem";
import React, {useCallback, useState} from "react";
import SvgCaretDown from "../icons/SvgCaretDown";
import CollapseComponent from "../atoms/CollapseComponent";

function GuestPendingEventsList({events}) {
    const [isListCollapsed, setIsListCollapsed] = useState(false)

    const toggleCollapse = useCallback(() => {
        setIsListCollapsed(!isListCollapsed)
    }, [isListCollapsed])

    if (events.length > 0) {
        return (
            <div
                className='border border-primary bg-primary-50/30 rounded-2xl flex flex-col -mx-2 overflow-hidden'>
                <div className='flex flex-row justify-between p-3 hover:bg-primary-50/80 text-primary' onClick={toggleCollapse}>
                    <div className='flex flex-row items-center gap-3'>
                    <span className="relative flex h-2.5 w-2.5 flex flex-col items-center justify-center">
                        <span
                            className="animate-ping  absolute inline-flex h-full w-full rounded-full bg-primary-200 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-primary"></span>
                    </span>
                        <p className='text-xl font-bold'>
                            Pending Requests ({events.length})
                        </p>
                    </div>
                    <SvgCaretDown/>
                </div>
                <CollapseComponent isOpen={!isListCollapsed}>
                    <div className='flex flex-col gap-2 p-3'>
                        {events.map(event =>
                            <GuestEventItem event={event} key={event.id}/>
                        )}
                    </div>
                </CollapseComponent>
            </div>
        )
    }
}

export default GuestPendingEventsList
