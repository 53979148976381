import React, {useCallback, useState, useEffect} from "react";
import OrganizerScannerListItem from "../tables/OrganizerScannerListItem";
import {sample_hosts, sample_organizers, sample_scanners} from "../../../utils/dummy";
import AddScannerModal from "../../modals/AddScannerModal";
import {Button} from "../../atoms/Button";
import SvgUserPlus from "../../icons/SvgUserPlus";
import {connect} from 'react-redux';
import InviteScannerPaywallModal from '../../modals/InviteScannerPaywallModal';

const mapStateToProps = (state) => {
    return {
        tier: state.userReducer.userProfile.tier,
    }
}

const FREE_TIER_SCANNER_LIMIT = 0;
const PRO_TIER_SCANNER_LIMIT = 2;

function OrganizerScannerManagementView(props) {
    const [isAddScannerModalOpen, setIsAddScannerModalOpen] = useState(false)
    const [organizers, setOrganizers] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [scanners, setScanners] = useState([]);
    const [scannerIds, setScannerIds] = useState([])
    const [scannerPaywallOpen, setScannerPaywallOpen] = useState(false);
    const [numScanners, setNumScanners] = useState(0);

    useEffect(() => {
        setOrganizers(props.event.organizers)
        const organizerIds = props.event.organizers.map(o => o.profile_id)
        setHosts(props.event.hosts.filter(h => !organizerIds.includes(h.profile_id)))
        const hostIds = props.event.hosts.map(h => h.profile_id);
        setScannerIds(props.event.scanners.map(s => s.profile_id))
        const nonScannerIds = organizerIds.concat(hostIds);
        const invitedScanners = props.event.invited_users.filter(u => u.type === 'Scanner').map(s => ({...s, profile_id: s.id, profile: {
            name: s.name,
            phone_number: s.phone_number,
            email: s.email,
        }}));
        setScanners(props.event.scanners.filter(s => !nonScannerIds.includes(s.profile_id)).concat(invitedScanners))
    }, [props.event])

    useEffect(() => {
      setNumScanners(scanners.length + scannerIds.length)
    }, [scanners, scannerIds])

    const openAddScannerModal = () => {
      if (props.tier === 'Free' && (numScanners >= FREE_TIER_SCANNER_LIMIT)) {
          setScannerPaywallOpen(true);
          return;
      } else if (props.tier === 'Pro' && (numScanners >= PRO_TIER_SCANNER_LIMIT)) {
          setScannerPaywallOpen(true);
          return;
      }

      setIsAddScannerModalOpen(true)
    };

  const openPaywall = (scannerId) => {
    setScannerPaywallOpen(true);
  }

    const closeAddScannerModal = useCallback(() => {
        setIsAddScannerModalOpen(false)
    }, [])

    return (
        <div className='flex flex-col gap-5 md:gap-8 flex-1 relative'>
            <div className='mt-4 md:hidden flex flex-row items-center justify-between'>
                <p className='text-2xl font-semibold'>
                    Scanners
                </p>
                {hosts.length > 0 &&
                    <Button icon={SvgUserPlus} onClick={openAddScannerModal} className='ml-auto'>
                        Add a scanner
                    </Button>
                }
            </div>
            <Button icon={SvgUserPlus} onClick={openAddScannerModal}
                    className='ml-auto absolute hidden md:block right-0 -top-14  z-[10]'>
                Add a scanner
            </Button>
            <div>
                <p className='text-lg font-bold md:pt-4'>
                    Organizers
                </p>
                <ul>
                    {organizers.map((item) =>
                        <OrganizerScannerListItem user={item} isScanner={scannerIds.includes(item.profile.id)} shouldOpenPaywall={(props.tier === 'Free' && (numScanners >= FREE_TIER_SCANNER_LIMIT)) ? true : (props.tier === 'Pro' && (numScanners >= PRO_TIER_SCANNER_LIMIT)) ? true : false} openPaywall={() => setScannerPaywallOpen(true)} addScannerId={(scannerId) => setScannerIds(scannerIds => [...scannerIds, scannerId])}/>
                    )}
                    <div
                        className="mx-auto flex-col my-5 items-center text-gray-500 text-center rounded-full hidden only:flex">
                        <p className='mb-5'>No organizers to show</p>
                    </div>
                </ul>
            </div>
            <div>
                <p className='text-lg font-bold'>
                    Hosts
                </p>
                <ul>
                    {hosts.map((item) =>
                        <OrganizerScannerListItem user={item} isScanner={scannerIds.includes(item.profile.id)} shouldOpenPaywall={(props.tier === 'Free' && (numScanners >= FREE_TIER_SCANNER_LIMIT)) ? true : (props.tier === 'Pro' && (numScanners >= PRO_TIER_SCANNER_LIMIT)) ? true : false} openPaywall={() => setScannerPaywallOpen(true)} addScannerId={(scannerId) => setScannerIds(scannerIds => [...scannerIds, scannerId])}/>
                    )}
                    {hosts.length === 0 &&
                        <div
                            className="mx-auto flex-col my-5 items-center text-gray-500 text-center rounded-full hidden only:flex">
                            <p className='mb-5'>No hosts to show</p>
                        </div>
                    }
                </ul>
            </div>
            <div>
                <div>
                    <p className='text-lg font-bold'>
                        Scanners
                    </p>
                </div>
                <ul>
                    {scanners.length > 0 ?
                        scanners.map((item) =>
                            <OrganizerScannerListItem user={item} isScanner={true} shouldOpenPaywall={(props.tier === 'Free' && (numScanners >= FREE_TIER_SCANNER_LIMIT)) ? true : (props.tier === 'Pro' && (numScanners >= PRO_TIER_SCANNER_LIMIT)) ? true : false} openPaywall={() => setScannerPaywallOpen(true)} addScannerId={(scannerId) => setScannerIds(scannerIds => [...scannerIds, scannerId])}/>
                        ) :
                        <div className="mx-auto text-gray-500 my-5 text-center rounded-full">
                            <p className='mb-5'>No additional scanners to show</p>
                        </div>
                    }
                </ul>
                <div className="mx-auto text-gray-500 my-5 text-center rounded-full">
                    <Button icon={SvgUserPlus} onClick={openAddScannerModal}
                            className=''>
                        Add a scanner
                    </Button>
                </div>
            </div>
            <AddScannerModal
                isOpen={isAddScannerModalOpen}
                close={closeAddScannerModal}
                eventId={props.event.id}
            />
            <InviteScannerPaywallModal
              isOpen={scannerPaywallOpen}
              close={() => setScannerPaywallOpen(false)}
              tier={props.tier}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(OrganizerScannerManagementView);
