import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width={292}
            height={213}
            viewBox="0 0 292 213"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M184.003 5C169.405 5 155.531 8.097 143 13.67c.143 0 .286 0 .429.01 1.915.115 3.24 1.048 3.964 2.277 11.301-4.602 23.66-7.146 36.61-7.146 53.676 0 97.195 43.516 97.195 97.189 0 53.673-43.519 97.189-97.195 97.189-13.102 0-25.604-2.602-37.01-7.299-.629 1.305-1.801 2.373-3.336 2.725 12.36 5.393 25.995 8.385 40.336 8.385C239.776 207 285 161.779 285 106S239.785 5 184.003 5z"
                fill="#FF7360"
                stroke="#FF7360"
                strokeWidth={4}
            />
            <path
                d="M199.127 61L85 119.152A99.19 99.19 0 0092.53 146L208 87.163A99.148 99.148 0 00199.127 61zM89.17 121.304L197.385 66.16a95.24 95.24 0 016.312 18.927L94.472 140.745a95.694 95.694 0 01-5.302-19.441z"
                fill="#FF7360"
                stroke="#FF7360"
                strokeWidth={4}
            />
            <path
                d="M208.372 95L95 152.68A101.514 101.514 0 00111.064 175l95.891-48.79A99.467 99.467 0 00209 106.16c0-3.771-.219-7.494-.628-11.16zm-4.803 28.664l-91.668 46.644a97.761 97.761 0 01-11.67-16.023l104.812-53.33c.095 1.748.143 3.486.143 5.205 0 5.841-.542 11.72-1.617 17.504zM177.224 33l-89.38 45.8A100.116 100.116 0 0084 106.333c0 1.905.076 3.791.18 5.667L195 55.21A101.994 101.994 0 00177.224 33zM91.055 81.429l85.488-43.81a98.862 98.862 0 0113.059 16.076L87.797 105.867a96.325 96.325 0 013.268-24.438h-.01z"
                fill="#FF7360"
                stroke="#FF7360"
                strokeWidth={4}
            />
            <path
                d="M145.851 13C120.416 23.59 100.19 43.816 90 69l81-41.082A104.246 104.246 0 00145.851 13zm0 4.139a100.288 100.288 0 0117.98 10.142l-65.61 33.28c10.428-19.095 27.299-34.565 47.63-43.413v-.01z"
                fill="#FF7360"
                stroke="#FF7360"
                strokeWidth={4}
            />
            <path
                d="M117 179.985A103.07 103.07 0 00146.723 199c27.871-11.689 49.427-35.134 58.277-64l-88 44.985zm29.723 14.871a99.178 99.178 0 01-22.962-14.052l74.402-38.03c-9.648 23.159-27.957 41.803-51.44 52.091v-.009z"
                fill="#FF7360"
                stroke="#FF7360"
                strokeWidth={4}
            />
            <path
                d="M145.69 195.509c-11.634 4.945-24.428 7.68-37.859 7.68-53.583 0-97.026-43.516-97.026-97.189 0-53.673 43.443-97.189 97.026-97.189a96.46 96.46 0 0135.861 6.89c.209-.125.447-.23.695-.315.646-.429 1.455-.696 2.416-.724.589-.448 1.322-.753 2.197-.848A100.178 100.178 0 00107.831 5C52.145 5 7 50.221 7 106s45.145 101 100.831 101c14.496 0 28.28-3.078 40.741-8.595-1.493-.447-2.454-1.572-2.882-2.896z"
                fill="#FF7360"
                stroke="#FF7360"
                strokeWidth={4}
            />
        </svg>
    )
}

export default SvgComponent
