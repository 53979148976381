import React, {useCallback, useState} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import TextInputWrapper from "../atoms/TextInputWrapper";

function RequestToAttendModal(props) {
    const [reason, setReason] = useState('');

    const handleReasonChange = (e) => {
        setReason(e.target.value);
    }


    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={'Request to attend'}
            description={'Organizers and hosts will be able to see your details when reviewing your request.'}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={() => {props.submit(reason); props.close()}}>
                        Submit request
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col gap-3 w-full'>
                <TextInputWrapper
                    label={'Reason (optional)'}
                    placeholder={props.event?.prompt || 'Let us know why you should attend this event...'}
                    value={reason}
                    textarea
                    onChange={handleReasonChange}
                />
            </div>
        </ModalComponent>
    );
}

export default RequestToAttendModal;
