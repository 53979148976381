import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

function ConfettiComponent() {
    const {width, height} = useWindowSize()
    const colors = [
        '#ff7360', // Original color
        '#ff9985', // Lighter tint
        '#ff514d', // Slightly darker shade
        '#e66659', // Less saturated shade
        '#ffbeb2', // Very light tint
    ]
    return (
        <div className='z-50'>
            <Confetti
                width={width}
                height={height}
                recycle={false}
                friction={.99}
                colors={colors}
                className={'z-[100]'}
            />
        </div>
    )
}

export default ConfettiComponent
