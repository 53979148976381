import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import HostEventPage from "./HostEventPage";
import OrganizerEventPage from "./OrganizerEventPage";
import {connect} from 'react-redux';
import {
  getEventById,
  getUserTypeForEvent,
  checkCapacity,
} from '../../api/events';
import Spinner from "../../components/atoms/Spinner";
import GuestEventPage from "./GuestEventPage";
import EventFullModal from 'components/modals/EventFullModal';

const mapStateToProps = (state) => {
    return {
        authSession: state.userReducer.authSession,
        currentEventId: state.userReducer.currentEventId,
        userEvents: state.userReducer.userEvents,
        currentEventView: state.userReducer.currentEventView,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentEventId: (currentEventId) => {
            dispatch({
                type: "SET_CURRENT_EVENT_ID",
                currentEventId
            })
        },
    }
}

const EventPage = (props) => {
    const {eventId} = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState(null);
    const [eventLoading, setEventLoading] = useState(true);
    const [userType, setUserType] = useState('');
    const [userTypeLoading, setUserTypeLoading] = useState(true);
    const [eventFull, setEventFull] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setUserTypeLoading(true);
        if (!props.currentEventId || !props.authSession?.user) return;

        const user = props.authSession.user;
        getUserTypeForEvent(props.currentEventId, user.id)
            .then(res => setUserType(res))
            .catch(err => console.log(err))
        setUserTypeLoading(false)
    }, [props.authSession, props.currentEventId])

    useEffect(() => {
        getEvent();
    }, [eventId, props.userEvents])

    const getEvent = async () => {
        if (!props.authSession) return;

        if (!eventId) {
            const _eventId = Object.keys(props.userEvents)?.[0];
            navigate(`/event/${_eventId}`)
            return;
        }
        props.setCurrentEventId(eventId);

        try {
            setEventLoading(true)
            const canView = await checkCapacity(eventId);
            if (canView) {
              const event = await getEventById(eventId);
              setEvent(event);
            } else {
              setEventFull(true);
            }

            setEventLoading(false);
        } catch (err) {
            console.log(err);
            navigate("/")
        }
    }

    useEffect(() => {
        setLoading(eventLoading || userTypeLoading)
    }, [eventLoading, userTypeLoading])

    const view = props.currentEventView;
    return (
        loading ?
            <div className='flex flex-col items-center justify-center min-h-[100dvh]'>
                <Spinner size={24}/>
            </div>
            :
        eventFull ?
            <div className="flex flex-col justify-center items-center w-full mt-14 bg-red-500">
              <EventFullModal isOpen={true} close={() => navigate("/")} />
            </div>
            :
            <div className='w-full max-w-[1400px] mx-auto pt-14'>
                {userType === 'organizer' && (view === 'Organizer' || !view) ? <OrganizerEventPage event={event}/> :
                    (userType === 'host' && (view === 'Host' || !view)) || (userType === 'organizer' && view === 'Host')? <HostEventPage event={event}/> :
                        userType === 'guest' || view === 'Guest' ? <GuestEventPage event={event}/> :
                            <GuestEventPage event={event}/>}
            </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
