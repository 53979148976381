import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

const CollapseComponent = ({ isOpen, style, className, children }) => {
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(isOpen ? 'auto' : '0');

    const updateHeight = () => {
        setContentHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0');
    };

    useLayoutEffect(() => {
        updateHeight(); // Initial height update when isOpen changes
        // Setup ResizeObserver to watch for content size changes
        const resizeObserver = new ResizeObserver(() => {
            if (isOpen) {
                updateHeight();
            }
        });

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        // Cleanup
        return () => resizeObserver.disconnect();
    }, [isOpen]);

    // Also handle window resize events
    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, [isOpen]);

    return (
        <div
            style={{ ...style, height: contentHeight, overflow: 'hidden' }}
            className={`transition-height duration-300 ease-in-out ${className}`}
        >
            <div ref={contentRef}>
                {children}
            </div>
        </div>
    );
};

export default CollapseComponent;
