import React from "react";
import DropdownComponent from "./DropdownComponent";
import SvgClose from "../icons/SvgClose";
import SvgCaretDown from "../icons/SvgCaretDown";

const SelectComponent = ({ className, outerLabel, value, label, showClear, setValue, scrollable, options, disabled, node, maxWidth }) => {
    return (
        <div className={`flex flex-col gap-2 ${className}`}>
            {outerLabel &&
                <div className='text-sm font-medium text-slate-500'>
                    {outerLabel}
                </div>
            }
            <DropdownComponent
                ToggleComponent={
                    <div
                        className="flex flex-1 items-center min-w-24 border rounded-xl border-slate-300 cursor-pointer hover:bg-slate-50 p-2 md:p-2 px-4 md:px-4">
                        <div className={`flex flex-1 flex-row items-center overflow-hidden ${!maxWidth && 'max-w-[100px]'} md:max-w-[240px] ${value ? '' : 'text-slate-500'} font-medium`}>
                            <p className='line-clamp-1'>
                                {value || label}
                            </p>
                            {(value && showClear) && (
                                <div className='text-slate-500 hover:text-red-400 cursor-pointer ml-1 mr-2'
                                     onClick={() => setValue(null)}>
                                    <SvgClose className='h-4 w-4'/>
                                </div>
                            )}
                        </div>
                        <SvgCaretDown className='w-5 h-5 ml-auto'/>
                    </div>
                }
                scrollable={scrollable}
                value={value}
                selectDropdown
                setValue={setValue}
                options={options}
                disabled={disabled}
                node={node}
            />
        </div>
    );
};

export default SelectComponent;
