import supabase from './supabase';
import store from '../Store';
import axios from 'api/axios';
import parsePhoneNumber from 'libphonenumber-js';
import { generateInviteLink, isDisabled } from 'api/hosts';
import { sendEmail } from 'api/mailgun';
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';

export const updateGuestDetails = async (eventId, guestId, guestDetails, parentId = null) => {
    // Used by organizer
    const { error } = await supabase
        .from("guests")
        .update(guestDetails)
        .eq("event_id", eventId)
        .eq("profile_id", guestId)
    
    // update plus ones
    await supabase
        .from("guests")
        .update(guestDetails)
        .eq("event_id", eventId)
        .eq("parent_guest", guestId)

    // update parent if used +1 modal to update
    if (!!parentId) {
        const { error: error2 } = await supabase
            .from("guests")
            .update(guestDetails)
            .eq("event_id", eventId)
            .eq("profile_id", parentId)
    }
    store.dispatch({
        type: "UPDATE_GUEST_FOR_EVENT",
        eventId,
        guestId,
        guestUpdates: {
            group: guestDetails.group,
            host: store.getState().userReducer.hosts[eventId]?.filter(h => h.profile_id === guestDetails.host_id)[0],
            host_id: guestDetails.host_id,
            ...(guestDetails.is_new_notification !== undefined && {is_new_notification: guestDetails.is_new_notification})
        }
    })
}


export const hostUpdateGuestDetails = async (eventId, guestId, hostId, group, numInvitesGiven, newHostNumber) => {
    // Used by host
    if (newHostNumber < 0) return;

    const { error } = await supabase
        .from("guests")
        .update({group, invite_limit: numInvitesGiven})
        .eq("event_id", eventId)
        .eq("profile_id", guestId)
    
        if (error) {
            console.log("ER", error)
        }

    // update plus ones
    await supabase
        .from("guests")
        .update({group, invite_limit: numInvitesGiven})
        .eq("event_id", eventId)
        .eq("parent_guest", guestId)

    const { error: error2 } = await supabase
        .from("hosts")
        .update({invite_limit: newHostNumber})
        .eq("event_id", eventId)
        .eq("profile_id", hostId)
    
        if (error2) {
            console.log("Error in updating count", error2)
        }
}

export const deleteGuest = async (eventId, guestId) => {
    const guest = (await supabase
        .from("guests")
        .delete()
        .eq("event_id", eventId)
        .eq("profile_id", guestId)
        .select("status, profile:profiles(email, phone_number), event:events(title)")
        ).data?.[0];

    // delete profile
    if (!!guest) {
      await supabase
        .from("profiles")
        .delete()
        .eq("id", guestId)
    }

    // Notify guest that they have been removed
    if (guest?.status === 'Attending') {
      if (!!guest?.profile?.email) {
        await sendEmail(
          guest.profile.email,
          "You've Been Removed From an Event",
          `You have been removed from event "${guest.event.title}" on Opassity. Your ticket will no longer work at this event. If you believe this was a mistake, please contact the host of the event to be reinvited.`
        );
      } 
      if (!!guest?.profile?.phone_number) {
        sendSms(eventId, guestId, guest.profile.phone_number, `You have been removed from event "${guest.event.title}" on Opassity. Your ticket will no longer work at this event.`);
      }
    }
    
    store.dispatch({
        type: "DELETE_GUEST_FOR_EVENT",
        eventId,
        guestId
    })
}

export const deleteInvite = async (eventId, inviteId) => {
    const { error } = await supabase
        .from("invited_users")
        .delete()
        .eq("id", inviteId)
    store.dispatch({
        type: "UPDATE_USER_EVENT",
        eventId: eventId,
        eventDetails: {
            invited_users: store.getState().userReducer.userEvents[eventId].invited_users.filter(u => u.id !== inviteId),
        }
    });
}

export const setGuestStatus = async (eventId, guestId, status, ignoreRedux=false, updateParent=true) => {
    const updates = {status: status}
    const { data, error } = await supabase
        .from("guests")
        .update({status: status, ...(status === 'Pending Organizer' && {is_new_notification: true})})
        .eq("event_id", eventId)
        .eq("profile_id", guestId)
        .select("host_id, profile:profiles(phone_number, email), host:hosts(invite_limit)")

    if (!ignoreRedux) {
        store.dispatch({
            type: "UPDATE_GUEST_FOR_EVENT",
            eventId,
            guestId,
            updateParent,
            guestUpdates: {
                status
            }
        })
    }

    // send email/sms notification
    if (status === 'Approved') {
      if (data.length > 0) {
        const profile = data[0];

        await sendSms(eventId, profile.host_id, profile.profile.phone_number);
        await sendEmail(
          profile.profile.email,
          "You've Been Invited to an Event",
          "You've been invited to an event on Opassity! Click here to accept your spot: " + await generateInviteLink(eventId, profile.host_id)
        );
      }
    } else if (status === 'Not Attending') {
      await supabase.from("hosts").update({ "invite_limit":  (data?.[0]?.host?.invite_limit || 0) + 1}).eq("event_id", eventId).eq("profile_id", data?.[0]?.host_id)
    }
    
}


export const requestInvite = async (eventId, hostId, guestId, status, justification) => {
    const updates = {status: status}
    const endpoint = "/event/request";
    const res = await axios.put(endpoint, {
        eventId,
        hostId,
        guestId,
        status,
        justification
    })
}

export const getGuestsByHostId = async (eventId, hostId) => {
    if (!eventId || !hostId)
        throw new Error("Host id must be provided")

    const guests = store.getState().userReducer.guests?.[eventId]?.filter(g =>
        g.host_id === hostId
    )

    return guests;
}

// Function a host calls when they are inviting a guest
export const quickInviteGuest = async (eventId, hostId, phoneNumber = null, name = null, email = null, overrideNoAccount=false) => {
    const disabled = isDisabled(eventId, hostId);
    if (disabled)
      return;

    const event = (await supabase
                    .from("events")
                    .select("*")
                    .eq("id", eventId)
                  )?.data?.[0];
    if (!event)
      throw new Error("NoEvent")

    // Close link 2 hours before event
    const inviteCutoff = DateTime.fromISO(event.start_timestamp).minus({hours: 2})
    if (DateTime.utc() >= inviteCutoff)
      throw new Error("EventClosed")
    
    // ensure host has available invites
    const { data: d, error: e } = await supabase
        .from("hosts")
        .select("*")
        .eq("event_id", eventId)
        .eq("profile_id", hostId);
    const host = d[0];
    const inviteLimit = host.invite_limit;
    const requiresApproval = host.requires_approval;

    if (inviteLimit === 0)
        throw new Error("ZeroInvites")

    // ensure event tier has available invites
    const { data: guests} = await supabase
                          .from("guests")
                          .select("*")
                          .eq("event_id", eventId)
    const { data: invites } = await supabase
                              .from("invited_users")
                              .select("*")
                              .eq("event_id", eventId)
    const owner = (await supabase
                  .from("profiles")
                  .select("tier, email")
                  .eq("id", event.owner_id)).data?.[0];
    const tier = owner?.tier;
    const numGuests = guests.length + invites.length;
                  
    if (tier === 'Free' && numGuests >= 100)
      throw new Error("FreePaywall")
    if (tier === 'Pro' && numGuests >= 500)
      throw new Error("ProPaywall")

    // add invite by name only
    if (!phoneNumber && !email) {
        if (!name)
            throw new Error("NameRequired");
        if (host.requires_approval)
            throw new Error("ApprovalRequired")

        const splitName = name.split(" ");
        const firstName = splitName.length === 1 ? splitName[0] : splitName.slice(0, 1)[0];
        const lastName = splitName.length === 1 ? "" : splitName.slice(1, splitName.length).join(" ");

        // check if name already added
        const exists = (await supabase
                        .from("guests")
                        .select("profile:profiles!inner(first_name, last_name)")
                        .eq("profiles.first_name", firstName)
                        .eq("profiles.last_name", lastName)
                        ).data?.[0];
      
      if (!!exists)
        throw new Error("AlreadyInvited")

        const endpoint = "/event/anonymous";
        const res = await axios.post(endpoint, {
            firstName,
            lastName,
            hostId,
            eventId
        })
        store.dispatch({
            type: "INSERT_GUEST_FOR_EVENT",
            eventId,
            guest: res.data.guest
        })
        await decrementInvites("hosts", eventId, hostId, inviteLimit);
        return
    }

    const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .or(`phone_number.eq.${phoneNumber},email.eq.${email}`)

    // No acount exists, add to invited users
    if (data.length === 0) {
        const res = await supabase
            .from("invited_users")
            .select("id")
            .eq("event_id", eventId)
            .eq("phone_number", phoneNumber)
            .eq("email", email)
        if (res.data.length !== 0)
            throw new Error("AlreadyInvited")
        if (!overrideNoAccount && !email)
            throw new Error("NoAccount")
        const { data, status } = await supabase
            .from("invited_users")
            .insert({
                event_id: eventId,
                invited_by: hostId,
                phone_number: phoneNumber,
                name,
                email,
                type: 'Guest',
                type_attributes: {
                    is_primary_guest: true,
                    host_id: hostId,
                    status: requiresApproval ? 'Pending Organizer' : 'Approved'
                }
            })
            .select()
        if (status === 409) {
            throw new Error("AlreadyInvited")
        }
        
        store.dispatch({
            type: "UPDATE_USER_EVENT",
            eventId: eventId,
            eventDetails: {
                invited_users: [
                    ...store.getState().userReducer.userEvents[eventId].invited_users,
                    data?.[0]
                ]
            }
        });
        await decrementInvites("hosts", eventId, hostId, inviteLimit);
        if (!!email)
          await sendEmail(
            email,
            "You've Been Invited to an Event",
            "You've been invited to an event on Opassity! Click here to accept your spot: " + await generateInviteLink(eventId, hostId)
          );
        
        // send tier capacity warning emails
        if (tier === "Free" && numGuests === 75) {
          await sendEmail(
            owner?.email,
            "Approaching Guest Limit",
            `<p>This is a notification to inform you that event ${event.title} is approaching the maximum number of guests (75/100) on Opassity's Free tier. Check out our <a href="https://opassity.com/pricing">pricing page</a> for more information on upgrading your account.</p>`
          );
        } else if (tier === 'Pro' && numGuests === 400) {
          await sendEmail(
            owner?.email,
            "Approaching Guest Limit",
            `<p>This is a notification to inform you that event ${event.title} is approaching the maximum number of guests (400/500) on Opassity's Pro tier. Check out our <a href="https://opassity.com/pricing">pricing page</a> for more information on upgrading your account.</p>`
          );
        }

        return
    }

    const profile = data[0];

    // Existing account has blocked this host
    const blocked = (await supabase
                      .from("blocked_hosts")
                      .select("*")
                      .eq("profile_id", profile.id)
                      .eq("host_id", hostId)
                    )?.data?.[0]
    if (!!blocked)
      throw new Error("HostBlocked")

    // Add existing account
    const { data: data1, error: error1, status } = await supabase
        .from("guests")
        .insert({
            event_id: eventId,
            profile_id: profile.id,
            is_primary_guest: true,
            host_id: hostId,
            status: requiresApproval ? "Pending Organizer" : "Approved",
        })
        .select()

    if (status === 409) {
        throw new Error("AlreadyInvited")
    }
    await decrementInvites("hosts", eventId, hostId, inviteLimit);

    // send invite messages
    if (!requiresApproval) {
      if (!!phoneNumber) {
        await sendSms(eventId, hostId, phoneNumber);
      } else if (!!email) {
        // send email
        await sendEmail(
          email,
          "You've Been Invited to an Event",
          "You've been invited to an event on Opassity! Click here to accept your spot: " + await generateInviteLink(eventId, hostId)
        );
      } else {
        throw new Error("Should never reach here, must have email or phone number")
      }
    }

    store.dispatch({
        type: "UPDATE_USER_EVENT",
        eventId: eventId,
        eventDetails: {
            invited_users: [
                ...store.getState().userReducer.userEvents[eventId].invited_users,
                data?.[0]
            ]
        }
    })

    // send tier capacity warning emails
    if (tier === "Free" && numGuests === 75) {
      await sendEmail(
        owner?.email,
        "Approaching Guest Limit",
        `<p>This is a notification to inform you that event ${event.title} is approaching the maximum number of guests (75/100) on Opassity's Free tier. Check out our <a href="https://opassity.com/pricing">pricing page</a> for more information on upgrading your account.</p>`
      );
    } else if (tier === 'Pro' && numGuests === 400) {
      await sendEmail(
        owner?.email,
        "Approaching Guest Limit",
        `<p>This is a notification to inform you that event ${event.title} is approaching the maximum number of guests (400/500) on Opassity's Pro tier. Check out our <a href="https://opassity.com/pricing">pricing page</a> for more information on upgrading your account.</p>`
      );
    }
}

const decrementInvites = async (type, eventId, profileId, inviteLimit) => {
    if (inviteLimit === 0) return;

    const { error } = await supabase
        .from(type)
        .update({invite_limit: inviteLimit - 1})
        .eq("event_id", eventId)
        .eq("profile_id", profileId)

    if (error) {
        throw new Error(error.message)
    }
}

// Function a guest calls when they are inviting another guest
export const sendPlusOneInvite = async (eventId, invitedBy, hostId, phoneNumber, email, name, inviteLimit, overrideNoAccount=false) => {
    const event = (await supabase
        .from("events")
        .select("*")
        .eq("id", eventId)
      )?.data?.[0];
    if (!event)
        throw new Error("NoEvent")

    // Close link 2 hours before event
    const inviteCutoff = DateTime.fromISO(event.start_timestamp).minus({hours: 2})
    if (DateTime.utc() >= inviteCutoff)
        throw new Error("EventClosed")

    const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("phone_number", phoneNumber)

    // account does not exist, add as invited user
    if (data.length === 0) {
        const res = await supabase
            .from("invited_users")
            .select("id")
            .eq("event_id", eventId)
            .eq("phone_number", phoneNumber)
            .eq("email", email)
        if (res.data.length !== 0)
            throw new Error("AlreadyInvited")
        if (!overrideNoAccount && !email)
            throw new Error("NoAccount")

        const { data, error, status } = await supabase
            .from("invited_users")
            .insert({
                event_id: eventId,
                invited_by: invitedBy,
                phone_number: phoneNumber,
                type: 'Guest',
                name,
                email,
                type_attributes: {
                    is_primary_guest: false,
                    host_id: hostId,
                    status: inviteLimit > 0 ? "Approved" : "Pending Host"
                }
            })
            .select()

        if (status === 409) {
            throw new Error("AlreadyInvited")
        }
        
        await decrementInvites("guests", eventId, invitedBy, inviteLimit);
        sendSms(eventId, hostId, phoneNumber);
        return;
    }
    
    // fetch existing account
    const profile = data[0];
    const { data: data1, error: error1, status } = await supabase
        .from("guests")
        .insert({
            event_id: eventId,
            profile_id: profile.id,
            parent_guest: invitedBy,
            is_primary_guest: false,
            host_id: hostId,
            status: inviteLimit > 0 ? "Approved" : 'Pending Host',
        })
        .select()

    if (status === 409) {
        throw new Error("AlreadyInvited")
    }

    if (inviteLimit > 0) {
        sendSms(eventId, hostId, phoneNumber);
    }
    await decrementInvites("guests", eventId, invitedBy, inviteLimit);
}

const sendSms = async (eventId, hostId, phoneNumber, message=null) => {
    const { data, error } = await supabase.from("profiles").select("guests(*)").eq("phone_number", phoneNumber).eq("guests.event_id", eventId);
    const initiatedRequest = !!data && !!data?.[0]?.guests?.[0]?.requested;
    const defaultMessage = initiatedRequest ?
      "You're now going to an event on Opassity! Click here to view the event: " + await generateInviteLink(eventId, hostId) :
      "You've been invited to an event on Opassity! Click here to accept your spot: " + await generateInviteLink(eventId, hostId)

    const endpoint = "/utils/sms";
    try {
        const res = await axios.post(endpoint, {
            phoneNumber,
            message: message || defaultMessage,
        })
    } catch (err) {
        console.log(err)
    }
}

export const createGuest = async (eventId, profileId, justification, isPrimaryGuest = true, status = "Pending Organizer") => {
    const { data, error } = await supabase
        .from("guests")
        .insert({
            event_id: eventId,
            profile_id: profileId,
            is_primary_guest: isPrimaryGuest,
            status,
            justification
        })
        .select()
    
        if (!!error) {
            console.log(error)
        }
}

export const getGuestPass = async (eventId, profileId) => {
  const endpoint = `/utils/pass?event_id=${eventId}&profile_id=${profileId}`
  const res = await axios.get(endpoint, { responseType: "arraybuffer" });
  const data = new Blob([res.data], { type: "application/vnd.apple.pkpass" })
  saveAs(data, "pass.pkpass")
}

