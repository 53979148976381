import React from 'react';
import {Button} from "../atoms/Button";

function OnboardingButtons({goNextStep, goBackStep, nextDisabled, backHidden, finish}) {
    return (
        <div className='flex flex-col gap-3'>
            <Button variant={'default'} disabled={nextDisabled} onClick={goNextStep}>
                {finish ? 'Finish' : 'Continue'}
            </Button>
            {!backHidden &&
                <Button variant={'ghost'} onClick={goBackStep}>
                    Go Back
                </Button>
            }
        </div>
    );
}

export default OnboardingButtons;
