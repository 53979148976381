import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={24}
            viewBox="0 -960 960 960"
            width={24}
            fill={'currentColor'}
            {...props}
        >
            <path
                d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h360v80H200v560h560v-360h80v360q0 33-23.5 56.5T760-120H200zm480-480v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80zM240-280h480L570-480 450-320l-90-120-120 160zm-40-480v560-560z"/>
        </svg>
    )
}

export default SvgComponent
