import React, {useCallback, useState} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import PhoneInputWrapper from "../atoms/PhoneInputWrapper";
import {addScannerByPhoneNumber} from '../../api/scanners';
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/consts";

function AddScannerModal(props) {
    const [phone, setPhone] = useState('');

    const handlePhoneChange = useCallback((phone) => {
        setPhone(phone);
    }, [])

    const handleAddScanner = () => {
        toast.promise(
            addScannerByPhoneNumber(props.eventId, phone),
            {
                pending: 'Adding scanner...',
                success: 'Scanner invited successfully!',
                error: 'Failed to invite scanner.'
            },
            TOAST_OPTIONS
        ).then(() => {
            props.close();
        })
            .catch((error) => {
                console.error("Error adding scanner:", error);
            });
    }


    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={'Add a new scanner'}
            description={'Add a phone number to invite a scanner.'}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={handleAddScanner}>
                        Add scanner
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col gap-3 w-full'>
                <PhoneInputWrapper
                    label={'Phone'}
                    value={phone}
                    onChange={handlePhoneChange}
                />
            </div>
        </ModalComponent>
    );
}

export default AddScannerModal;
