import React, {useEffect, useState} from 'react';
import {Button} from "../components/atoms/Button";
import image from '../assets/img/casual-life-3d-yellow-padlock-with-key.png'
import SvgRefresh from "../components/icons/SvgRefresh";
import SvgLogo from "../components/atoms/SvgLogo";

function PhoneVerifyPage() {
    const [ellipsis, setEllipsis] = useState('');

    const animateEllipsis = () => {
        const ellipsisStates = ['', '.', '..', '...'];
        setEllipsis(prevState => {
            const currentIndex = ellipsisStates.indexOf(prevState);
            const nextIndex = (currentIndex + 1) % ellipsisStates.length;
            return ellipsisStates[nextIndex];
        });
    };

    useEffect(() => {
        const intervalId = setInterval(animateEllipsis, 500);
        return () => clearInterval(intervalId);
    }, []);

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className='w-full max-w-[1400px] mx-auto flex flex-col items-center flex-1'>
            <div
                className='w-full text-center md:w-[740px] flex flex-col items-center justify-center min-h-screen gap-3 px-5'>
                <div className='absolute top-0 left-0 right-0 flex flex-col items-center mt-12'>
                    <SvgLogo className={'h-8'}/>
                </div>
                {/*<img src={image} alt={'locked'} className='w-[120px] md:w-[200px] mx-auto mb-5 pl-3 md:pl-8 hue-rotate-[330deg] saturate-[1.4]'/>*/}
                <p className='text-3xl font-bold'>
                    Waiting for phone verification{ellipsis}
                </p>
                <p className='text-balance'>
                    Please verify your connection on your device. Refresh the page when completed to proceed.
                </p>
                <Button onClick={handleRefresh} icon={SvgRefresh}>
                    Refresh
                </Button>
            </div>
        </div>
    )
}

export default PhoneVerifyPage;
