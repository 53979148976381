import React from "react";
import parsePhoneNumber from 'libphonenumber-js';
import SvgVerified from "../icons/SvgVerified";

function UserInfoComponent({user}) {
    return (
        <div className='flex flex-row items-center gap-3'>
            <div
                className='w-14 h-14 rounded-full object-cover bg-gray-100 flex flex-col items-center justify-center'>
                {user.profile?.display_name?.slice(0,1) || (user.profile?.first_name ? `${user.profile.first_name.slice(0,1)}${user.profile?.last_name?.slice(0,1) || ""}` : 'No name')}
            </div>
            <div className='flex flex-col gap-1 flex-1'>
                <div className='flex flex-row items-center gap-2'>
                    <div className='flex flex-col gap-0'>
                        {!user.requires_approval &&
                            <div className='flex flex-row items-center gap-1 -mb-1 text-primary'>
                                <SvgVerified className={'w-3 h-3'}/>
                                <p className='text-xs font-semibold'>
                                    Auto Approval
                                </p>
                            </div>
                        }
                        <p className='text-xl font-semibold'>
                            {user.profile?.display_name || (user.profile?.first_name ? `${user.profile.first_name} ${user.profile?.last_name || ""}` : 'No name')}
                        </p>
                    </div>
                    {user.role?.length > 0 &&
                        <div className='flex flex-row items-center gap-1'>
                            {user.role.map(role =>
                                <div className='text-xs bg-gray-100 p-1 rounded-lg font-semibold ' key={role}>
                                    {role}
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div className='flex flex-row text-sm text-gray-500 flex-wrap gap-y-0 gap-2 items-center'>
                    <p>
                        {parsePhoneNumber(`+1${user.profile?.phone_number}` || "")?.formatNational() || 'No phone'}
                    </p>
                    <p>
                        -
                    </p>
                    <p>
                        {user.profile?.email || 'No email'}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default UserInfoComponent
