import React, {useState} from 'react';
import OnboardingStepHeader from "./OnboardingStepHeader";
import OnboardingButtons from "./OnboardingButtons";
import SelectComponent from "../atoms/SelectComponent";
import DatePicker from "react-datepicker";
import {gender_options} from "../../utils/consts";
import TextInputWrapper from "../atoms/TextInputWrapper";
import SvgInstagram from "../icons/SvgInstagram";
import MoreInfoModal from '../modals/OnboardingDetailsModal';
import {addYears, isBefore} from "date-fns";
import { DateTime } from 'luxon';

export default function OnboardingPersonalDetails({
  goNextStep,
  dob,
  setDob,
  gender,
  setGender,
  goBackStep,
  instagram,
  setInstagram
}) {
    const [errorMessage, setErrorMessage] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [rawInput, setRawInput] = useState("");
    const [dobString, setDobString] = useState("");
    const [dobError, setDobError] = useState("");

    const handleGenderChange = (value) => {
        if (errorMessage) setErrorMessage('');
        setGender(value);
    };

    const handleInstagramChange = (event) => {
        if (errorMessage) setErrorMessage('');
        setInstagram(event.target.value);
    }

    const isEligibleAge = (date) => {
        const cutoffDate = addYears(new Date(), -17);
        return isBefore(date, cutoffDate);
    }


    const getInput = (e) => {
      const key = e.key;
      let input = rawInput;
      if (key === "Backspace") 
        input = input.slice(0, input.length - 1);
      if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(key))
        input = input + key;
      input = input.slice(0, 8);
      setRawInput(input);
      return input;
    }

    const handleDobChange = (input) => {
      let outputString = "";
      outputString += input.slice(0, 2);
      if (input.length > 2) {
        outputString += "/";
        outputString += input.slice(2,4);
      }
      if (input.length > 4) {
        outputString += "/";
        outputString += input.slice(4,8);
      }
      setDobString(outputString);
      const dt = DateTime.fromFormat(input, "MMddyyyy");
      if (dt.invalid) {
        setDob(null);
      } else {
        if (dt > DateTime.now() || dt < DateTime.now().set({years: DateTime.now().year - 100})) {
          setDob(null);
        } else {
          setDob(dt.toJSDate())
        }
      }
    }

    const handleKeyDown = (e) => {
      const input = getInput(e);
      handleDobChange(input);
    }

    const handleSubmit = () => {
        if (!isEligibleAge(dob)) {
            setErrorMessage('You must be at least 17 years old to proceed.');
        } else if (gender) {
            goNextStep();
        } else {
            setErrorMessage('Please select your gender to proceed.');
        }
    };

    return (
        <div className='flex flex-col gap-8 w-screen px-4 md:w-[460px]'>
            <OnboardingStepHeader
                title={'Tell us more about you'}
                description={
                  <div>
                    <button className="cursor-pointer font-semibold text-primary-400 hover:text-primary-600" onClick={() => setModalOpen(true)}>
                      Why is this being requested?
                    </button>
                  </div>
                }
            />
            <div className='flex flex-col gap-5 w-full items-center'>
                <div className='flex flex-col w-full'>
                    <div className='text-sm font-medium text-slate-500 mb-1.5'>
                        <p className="inline">Date of birth</p>
                        <p className="inline text-red-500 pl-1">*</p>
                    </div>
                    <div className="hidden sm:inline flex-1 p-0 m-0 w-full">
                      <DatePicker
                          wrapperClassName="w-full"
                          className={'' +
                              'flex items-center border rounded-xl border-slate-300 p-2 px-4 font-medium placeholder:text-slate-500 w-full' +
                              ' outline-none' + ` ${(dobError) ? 'border-red-500' : ''}`
                          }
                          placeholderText={'ie. 03/05/1990'}
                          selected={dob}
                          value={dobString}
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          maxDate={new Date()}
                          onKeyDown={handleKeyDown}
                          onFocus={() => setDobError("")}
                          onBlur={() => setDobError(!!dobString && !dob)}
                      />
                    </div>
                    <div className="sm:hidden p-0 m-0">
                      <input
                        className="flex items-center border rounded-xl border-slate-300 p-2 px-4 font-medium placeholder:text-slate-500 w-full outline-none"
                        style={{borderColor: (dobError) ? 'red' : ''}}
                        placeholder={"ie. 03/05/1990"}
                        value={dobString}
                        type="tel"
                        onKeyDown={handleKeyDown}
                        onFocus={() => setDobError("")}
                        onBlur={() => setDobError(!!dobString && !dob)}
                      />
                    </div>
                </div>
                <div className='flex flex-col gap-3 w-full'>
                    <SelectComponent
                        outerLabel={
                          <span>
                            <p className="inline">Gender</p>
                            <p className="inline text-red-500 pl-1">*</p>
                          </span>
                        }
                        label={'Select your gender'}
                        value={gender}
                        maxWidth
                        setValue={handleGenderChange}
                        options={gender_options}
                    />
                </div>
                <div className='flex flex-col gap-3 w-full'>
                    <TextInputWrapper
                        label={
                          <span>
                            <p className="inline">Your Instagram</p>
                            <p className="inline ml-2 text-xs">(this may be requested to join some events)</p>
                          </span>
                        }
                        value={instagram}
                        icon={SvgInstagram}
                        placeholder={'Your username'}
                        onChange={handleInstagramChange}
                        autoComplete="none"
                        autoCapitalize="none"
                    />
                </div>
                <p className={`text-xs h-4 ${errorMessage ? 'text-red-400' : 'text-transparent'}`}>
                    {errorMessage}
                </p>
            </div>
            <OnboardingButtons
                finish
                goNextStep={handleSubmit}
                goBackStep={goBackStep}
                nextDisabled={!dob || !gender}
            />
            <MoreInfoModal title={'Why we ask for this info'} text={'Events are better when properly curated, and your age, gender, and social media profile are useful pieces of information that help some event organizers understand their attendees. Not every organizer will need or use this information, but it is requested regardless (only once, initially) and will not be asked again.'} isOpen={modalOpen} close={() => setModalOpen(false)}/>
        </div>
    );
}

