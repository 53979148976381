import {connect} from 'react-redux';
import TextInputWrapper from "../components/atoms/TextInputWrapper";
import React, {useCallback, useEffect, useState} from "react";
import SelectComponent from "../components/atoms/SelectComponent";
import {state_location_options, TOAST_OPTIONS} from "../utils/consts";
import DatePicker from "react-datepicker";
import DropzoneUploader from "../components/atoms/DropzoneUploader";
import SvgAddPhoto from "../components/icons/SvgAddPhoto";
import {Button} from "../components/atoms/Button";
import SvgClose from "../components/icons/SvgClose";
import {toast} from "react-toastify";
import {setImageList, createEvent} from "../api/events";
import {all} from "axios";
import AccessibleToggle from "../components/atoms/AccessibleToggle";
import SvgCheck from "../components/icons/SvgCheck";
import SvgLogoMark from "../components/atoms/SvgLogoMark";
import {Link} from "react-router-dom";
import SvgChevronRight from "../components/icons/SvgChevronRight";
import {DateTime} from 'luxon';
import GroupTag from "../components/atoms/GroupTag";
import CollapseComponent from "../components/atoms/CollapseComponent";
import HorizontalDivider from "../components/atoms/HorizontalDivider";

const mapStateToProps = (state) => {
    return {
        userId: state.userReducer.authSession?.user?.id,
        authSession: state.userReducer.authSession,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setEventView: (view) => {
            dispatch({
                type: "SET_EVENT_VIEW",
                view
            })
        }
    }
}

function CreateEventPage(props) {
    const [eventName, setEventName] = useState('')
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [stateLocation, setStateLocation] = useState('')
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [images, setImages] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [plusOneToggleValue, setPlusOneToggleValue] = useState(true)
    const [instagramToggleValue, setInstagramToggleValue] = useState(true)
    const [genderToggleValue, setGenderToggleValue] = useState(true)
    const [ageToggleValue, setAgeToggleValue] = useState(true)
    const [groupName, setGroupName] = useState('');
    const [groupNames, setGroupNames] = useState([]);
    const [hasGroups, setHasGroups] = useState(true)

    const [detailsComplete, setDetailsComplete] = useState(false);
    const [timeComplete, setTimeComplete] = useState(false);
    const [promptComplete, setPromptComplete] = useState(false);
    const [imagesComplete, setImagesComplete] = useState(false);
    const [isValidEvent, setIsValidEvent] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        setDetailsComplete(eventName.trim() !== '' && description.trim() !== '' && address.trim() !== '' && city.trim() !== '' && stateLocation.trim() !== '');
    }, [eventName, description, address, city, stateLocation]);

    useEffect(() => {
        setTimeComplete(startTime !== '' && endTime !== '');
    }, [startTime, endTime]);

    useEffect(() => {
        setPromptComplete(prompt.trim() !== '');
    }, [prompt]);

    useEffect(() => {
        setImagesComplete(images.length > 0);
    }, [images]);

    useEffect(() => {
        const isValid = (
            (!!startTime && !!endTime && startTime < endTime)
        )
        setIsValidEvent(isValid);
    }, [startTime, endTime])

    const handleEventNameChange = useCallback((event) => {
        setEventName(event.target.value);
    }, []);

    const handleDescriptionChange = useCallback((event) => {
        setDescription(event.target.value);
    }, []);

    const handleAddressChange = useCallback((event) => {
        setAddress(event.target.value);
    }, []);

    const handleCityChange = useCallback((event) => {
        setCity(event.target.value);
    }, []);

    const handlePromptChange = useCallback((event) => {
        setPrompt(event.target.value);
    }, []);

    const handleStateLocationChange = useCallback((value) => {
        setStateLocation(value);
    }, []);

    const handleStartTimeChange = useCallback((value) => {
        setStartTime(value);
    }, []);

    const handleEndTimeChange = useCallback((value) => {
        setEndTime(value);
    }, []);

    const handleAddGroup = () => {
        const trimmedGroupName = groupName.trim();
        if (trimmedGroupName !== '' && !groupNames.includes(trimmedGroupName)) {
            setGroupNames([...groupNames, trimmedGroupName]);
        }
        setGroupName(''); // Clear the input regardless of whether the name was added or not
    };

    const handleRemoveGroup = (name) => {
        setGroupNames(groupNames.filter(group => group !== name));
    };

    const handleImageUpload = async (image) => {
        try {
            const _images = [...images, image];
            setImages(_images);
            await toast.promise(
                setImageList(props.currentEventId, _images),
                {
                    pending: 'Uploading image...',
                    success: 'Image uploaded successfully!',
                    error: 'Failed to upload image!'
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to upload image.', TOAST_OPTIONS);
        }
    }

    const handleImageDelete = async (imageIndex) => {
        try {
            const _images = images.filter((img, i) => i !== imageIndex);
            setImages(_images);
            await toast.promise(
                setImageList(props.currentEventId, _images),
                {
                    pending: 'Deleting image...',
                    success: 'Image deleted successfully!',
                    error: 'Failed to delete image!'
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to delete image.', TOAST_OPTIONS);
        }
    }

    const submitEvent = async () => {
        if (!isValidEvent) return;

        try {
            setSubmitLoading(true);
            const eventId = await createEvent(props.userId, {
                title: eventName,
                description,
                location: {
                    address,
                    city,
                    state: stateLocation
                },
                start_timestamp: startTime,
                end_timestamp: endTime,
                image_urls: images,
                prompt,
                guest_scopes: {
                    ...(!!ageToggleValue && {age: true}),
                    ...(!!instagramToggleValue && {social: true}),
                    ...(!!genderToggleValue && {gender: true}),
                    ...(!!plusOneToggleValue && {plusOne: true}),
                },
                groups: groupNames,
            })
            props.setEventView("Organizer")
            window.location.href = `/event/${eventId}`;
            setSubmitLoading(false);
        } catch (err) {
            console.log(err)
            toast.error(err.message)
        }
    }

    const HeaderComponent = ({number, label, completed, description}) => {
        return (
            <div className='flex flex-row md:items-center gap-5 mb-3'>
                <div className='flex flex-col md:flex-row gap-3 md:gap-5 flex-1'>
                    <div
                        className={`flex flex-col items-center justify-center w-10 h-10 rounded-full ${completed ? 'bg-green-500' : 'bg-primary'} text-white font-bold`}>
                        {number}
                    </div>
                    <div className={'flex-1'}>
                        <p className='text-lg font-bold'>
                            {label}
                        </p>
                        {!!description &&
                            <p className='text-sm text-gray-500'>
                                {description}
                            </p>
                        }
                    </div>
                </div>
                {!!completed && <div
                    className='bg-green-50 h-10 w-10 flex flex-col items-center justify-center rounded-full ml-auto'>
                    <SvgCheck className={'text-green-500'}/></div>}
            </div>
        )
    }

    const invalidDates = !!startTime && !!endTime && startTime >= endTime;
    const allComplete = detailsComplete && timeComplete && promptComplete && imagesComplete && !invalidDates;
    return (
        <div className='flex flex-col flex-1 relative w-full items-center mt-4 pb-32'>
            <div className='w-full md:w-[600px] flex flex-col gap-12 md:mt-12 px-4'>
                <div
                    className='flex flex-row items-center justify-between sticky top-0 bg-white py-3 z-50 w-full md:w-[600px]'>
                    <div className='flex-1'>
                        <Link to={'/'}
                              className={'flex flex-row items-center gap-2 text-slate-500 hover:text-slate-800'}>
                            <SvgChevronRight className={'rotate-180'}/>
                            <p>
                                Back
                            </p>
                        </Link>
                    </div>
                    <SvgLogoMark className={'w-32 h-10 md:mr-8'}/>
                    <div className='flex-1'/>
                </div>
                <div className='flex flex-col gap-1'>
                    <p className='text-3xl font-semibold mt-0'>
                        Create your event
                    </p>
                    <p>
                        Follow four simple steps to get your event up on Opassity.
                    </p>
                </div>
                <div className='flex flex-col gap-3'>
                    <HeaderComponent number={1} label={'Provide the details'}
                                     description={'Add basic info about your event'} completed={detailsComplete}/>
                    <div className='md:ml-14 flex flex-col gap-3'>
                        <TextInputWrapper
                            label={'Event name'}
                            value={eventName}
                            placeholder={'Enter the name of your event'}
                            onChange={handleEventNameChange}
                        />
                        <TextInputWrapper
                            label={'Description'}
                            value={description}
                            placeholder={'Provide a brief description'}
                            onChange={handleDescriptionChange}
                            textarea
                        />
                        <AccessibleToggle
                            label="Allow plus ones?"
                            value={plusOneToggleValue}
                            onToggle={setPlusOneToggleValue}
                        />
                        <TextInputWrapper
                            label={'Address'}
                            value={address}
                            placeholder={'Street address'}
                            onChange={handleAddressChange}
                        />
                        <div className='flex flex-row gap-3'>
                            <TextInputWrapper
                                label={'City'}
                                value={city}
                                placeholder={'City'}
                                onChange={handleCityChange}
                            />
                            <SelectComponent
                                outerLabel={'State'}
                                label={'State'}
                                value={stateLocation}
                                setValue={handleStateLocationChange}
                                options={state_location_options}
                                scrollable
                            />
                        </div>
                    </div>
                </div>
                <HorizontalDivider className='bg-slate-200'/>
                <div className='flex flex-col gap-3 '>
                    <HeaderComponent number={2} label={'When will it happen?'}
                                     description={'Define the start and end times of your event'}
                                     completed={!invalidDates && timeComplete}/>
                    <div className='md:ml-14 flex flex-col gap-3'>
                        <div className='flex flex-col md:flex-row md:w-full gap-3'>
                            <div className='flex flex-col md:flex-row gap-3 w-full'>
                                <div className="flex flex-col w-full md:flex-1">
                                    <div className='text-sm font-medium text-slate-500 mb-1.5'>
                                        Start time
                                    </div>
                                    <DatePicker
                                        className={'' +
                                            'flex items-center border rounded-xl border-slate-300 p-2 px-4 font-medium placeholder:text-slate-500 w-full' +
                                            ' outline-none'
                                        }
                                        showTimeSelect
                                        selected={startTime}
                                        dateFormat="Pp"
                                        minDate={new Date()}
                                        onChange={handleStartTimeChange}
                                    />
                                </div>
                                <div className="flex flex-col w-full md:flex-1">
                                    <div className='text-sm font-medium text-slate-500 mb-1.5'>
                                        End time
                                    </div>
                                    <DatePicker
                                        className={'' +
                                            'flex items-center border rounded-xl border-slate-300 p-2 px-4 font-medium placeholder:text-slate-500 w-full' +
                                            ' outline-none'
                                        }
                                        showTimeSelect
                                        selected={endTime}
                                        dateFormat="Pp"
                                        minDate={new Date()}
                                        onChange={handleEndTimeChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {invalidDates &&
                            <p className="text-red-500 font-semibold text-xs ml-2">* Start time must be before end
                                time</p>
                        }
                    </div>
                </div>
                <HorizontalDivider className='bg-slate-200'/>
                <div className='flex flex-col gap-3 '>
                    <HeaderComponent number={3} label={'Collected info from guests'}
                                     description={'Tell us what information you want from guests that request an RSVP'}
                                     completed={promptComplete}
                    />
                    <div className='md:ml-14 flex flex-col gap-3'>
                        <TextInputWrapper
                            label={'Request prompt'}
                            value={prompt}
                            placeholder={'e.g. Why should you be there? What vibes do you bring to a party?'}
                            onChange={handlePromptChange}
                            textarea
                        />
                        <AccessibleToggle
                            label="Guest Instagram account"
                            value={instagramToggleValue}
                            onToggle={setInstagramToggleValue}
                        />
                        <AccessibleToggle
                            label="Guest gender"
                            value={genderToggleValue}
                            onToggle={setGenderToggleValue}
                        />
                        <AccessibleToggle
                            label="Guest age"
                            value={ageToggleValue}
                            onToggle={setAgeToggleValue}
                        />
                    </div>
                </div>
                <HorizontalDivider className='bg-slate-200'/>
                <div className='flex flex-col gap-3 '>
                    <HeaderComponent number={4}
                                     label={!hasGroups ? 'No groups for this event' : 'Add groups for your event'}
                                     description={'Define groups to help organize your guest list'}
                                     completed={groupNames.length > 0 || !hasGroups}/>
                    <div className='md:ml-14 flex flex-col gap-3'>
                        <AccessibleToggle
                            label="Use groups for your event"
                            value={hasGroups}
                            onToggle={setHasGroups}
                        />
                        <CollapseComponent isOpen={hasGroups}>
                            <div className='flex flex-row items-end gap-3'>
                                <div className='flex-1'>
                                    <TextInputWrapper
                                        label={'Name of group'}
                                        value={groupName}
                                        onChange={(e) => setGroupName(e.target.value)}
                                        onKeyDown={(e) => (e.key === 'Enter' && groupName.length > 0) && handleAddGroup()}
                                        placeholder={'e.g. VIP, Tier 1...'}
                                    />
                                </div>
                                <Button disabled={groupName.length === 0} onClick={handleAddGroup} className='mb-0.5'>
                                    Submit
                                </Button>
                            </div>
                            <div className='flex flex-row items-center gap-3 flex-wrap mt-3'>
                                {groupNames.map((name, index) => (
                                    <GroupTag group={name} key={name} onRemove={() => handleRemoveGroup(name)}/>
                                ))}
                            </div>
                        </CollapseComponent>
                    </div>
                </div>
                <HorizontalDivider className='bg-slate-200'/>
                <div className='flex flex-col gap-3 '>
                    <HeaderComponent number={5} label={'Add at least one image'}
                                     description={'Add a cover or flyer first, other images optional'}
                                     completed={imagesComplete}/>
                    <div className='md:ml-14 flex flex-col gap-3'>
                        <DropzoneUploader
                            label={'Drag \'n\' drop images here, or click to select one'}
                            labelActive={'Drop the cover photo here ...'}
                            Icon={SvgAddPhoto}
                            onFileUpload={handleImageUpload}
                            doesAcceptImages
                        />
                        <div className='grid grid-cols-2 gap-4'>
                            {images.map((image, i) =>
                                <div
                                    key={`event-details-image-${i}`}
                                    className='flex flex-col relative items-center justify-center border border-gray-100 rounded-xl overflow-hidden'>
                                    <img src={image} alt={'gallery'} className='max-w-[260px]'/>
                                    <Button className='absolute top-2 right-2' size={'icon'} radius={'full'}
                                            variant={'destructive'}
                                            onClick={() => handleImageDelete(i)}
                                            icon={SvgClose}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <HorizontalDivider className='bg-slate-200'/>
                <div>
                    <p className='text-center text-sm text-slate-500'>
                        Submit and run your event completely free for events with fewer than 100 guests. Need more
                        spots? Learn more <Link to={'/pricing'}><span className='text-primary'>here</span></Link>.
                    </p>
                </div>
                <Button className='-mt-4' disabled={!allComplete} onClick={submitEvent} loading={submitLoading}>
                    Submit your event
                </Button>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventPage);
