import React, {useCallback, useState, useEffect} from 'react';
import SvgCheck from "../../icons/SvgCheck";
import {Button} from "../../atoms/Button";
import SvgSubDirectoryRight from "../../icons/SvgSubDirectoryRight";
import {calculateAge} from "../../../utils/helpers";
import SvgClose from "../../icons/SvgClose";
import SvgUndo from "../../icons/SvgUndo";
import {setGuestStatus} from '../../../api/guests';
import parsePhoneNumber from 'libphonenumber-js';
import {TOAST_OPTIONS} from "../../../utils/consts";
import {toast} from "react-toastify";
import GroupTag from "../../atoms/GroupTag";

function OrganizerApprovalsTableItem({invite, index, tab, scopes}) {
    const [plusOne, setPlusOne] = useState(null);

    useEffect(() => {
        setPlusOne(invite.is_primary_guest ? invite.plusOne : invite);
    }, [invite, tab])

    const renderCell = (key, customRender = null) => {
        const content = key.split(".").reduce((o, k) => o?.[k], invite);
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs flex flex-row whitespace-nowrap truncate'>{content || '--'}</div>;
    }

    const renderPlusOneCell = (key, customRender = null) => {
        if (!plusOne) return;
        const content = key.split(".").reduce((o, k) => o?.[k], plusOne);
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs flex flex-row whitespace-nowrap truncate'>{content || '--'}</div>;
    }

    const handleAction = async (guest, status, actionName) => {
        try {
            await toast.promise(
                setGuestStatus(guest.event_id, guest.profile_id, status),
                {
                    pending: `${actionName}...`,
                    success: actionName === 'Approval' ? `${guest.profile?.first_name || ""} ${guest.profile?.last_name || ""} has been approved` : `${actionName} success`,
                    error: `Failed to ${actionName.toLowerCase()}.`
                },
                TOAST_OPTIONS
            );

            // Handle guest's plusOne if exists
            if (!!guest.plusOne) {
                await toast.promise(
                    setGuestStatus(guest.event_id, guest.plusOne.profile_id, status),
                    {
                        pending: `${actionName} plus one...`,
                        success: `Plus one ${actionName.toLowerCase()} successfully!`,
                        error: `Failed to ${actionName.toLowerCase()} plus one.`
                    },
                    TOAST_OPTIONS
                );
            }
        } catch (error) {
            // Error handling beyond the toast notification, if necessary
            console.error(`Error in ${actionName}:`, error);
        }
    };

    const handleUndoReject = (guest) => {
        handleAction(guest, 'Pending Organizer', 'Undo Rejection');
    };

    const handleApprove = (guest) => {
        handleAction(guest,
            guest.requested ? 'Attending' : 'Approved',
            'Approval'
        );
    };

    const handleReject = (guest) => {
        handleAction(guest, 'Rejected', 'Rejection');
    };

    return (
        <>
            <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} text-xs md:text-sm`}>
                <td className={`px-5 py-3 sticky left-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                    <div className='relative'>
                        {renderCell('profile', (profile) => (
                            <div className='max-w-xs truncate font-semibold' key={profile.id}>
                                {`${profile?.first_name} ${profile?.last_name}`}
                            </div>
                        ))}

                    </div>
                </td>
                <td className='px-2 md:px-5 py-2 md:py-3 whitespace-nowrap'>{renderCell("host.profile.display_name", (displayName) =>
                    displayName || `${renderCell('host.profile.first_name', content => content || "")} ${renderCell('host.profile.last_name', content => content || "")}`
                )}</td>
                <td className='px-2 md:px-5 py-2 md:py-3'>
                    <div className='w-min whitespace-nowrap'>
                        <GroupTag group={invite?.group}/>
                    </div>
                </td>
                <td className='whitespace-nowrap px-5 py-3'>{renderCell('profile.phone_number', (phoneNumber) =>
                    <p>
                        {parsePhoneNumber(`+1${phoneNumber}`)?.formatNational()}
                    </p>
                )}</td>
                <td className='px-5 py-3'>{renderCell('profile.email')}</td>
                {!!scopes.age &&
                    <td className='px-5 py-3'>
                        {renderCell('profile.date_of_birth', (dob) => (
                            <div className='max-w-xs truncate'>
                                {!!dob ? calculateAge(dob) : "--"}
                            </div>
                        ))}
                    </td>
                }
                {!!scopes.gender &&
                    <td className='px-5 py-3'>
                        {renderCell('profile.gender', (gender) => (
                            <div
                                className='max-w-xs truncate'>{gender === 'Prefer Not To Say' ? '--' : gender === 'Male' ? 'M' : 'F'}</div>
                        ))}
                    </td>
                }
                {!!scopes.social &&
                    <td className='px-5 py-3'>{renderCell('profile.instagram', (handle) =>
                        <a
                            href={`https://www.instagram.com/${handle}`}
                            target="_blank"
                            className="text-cyan-500"
                        >
                            {`@${handle}`}
                        </a>
                    )}
                    </td>
                }
                {!!scopes.plusOne &&
                    <td className='px-5 py-3'>
                        {renderCell('is_primary_guest', (isPrimaryGuest) => (
                            <div className='max-w-xs truncate w-10'>{isPrimaryGuest ? '--' : <SvgCheck/>}</div>
                        ))}
                    </td>
                }
                <td className='px-5 py-3'>
                    {renderCell('justification', (justification) => (
                        <div className='max-w-xs truncate'>
                            {justification}
                        </div>
                    ))}
                </td>
                <td className={`px-2 py-3 sticky right-0`}>
                    {invite.status === 'Rejected' ?
                        <Button
                            icon={SvgUndo}
                            onClick={() => handleUndoReject(invite)}
                            variant={'secondary'}
                            size={'icon'}
                            radius={'full'}
                            className={'shadow-lg'}
                        />
                        :
                        <div className='flex flex-row gap-3'>
                            <Button
                                icon={SvgCheck}
                                onClick={() => handleApprove(invite)}
                                variant={'green'}
                                size={'icon'}
                                radius={'full'}
                                className={'shadow-lg'}
                            />
                            <Button
                                icon={SvgClose}
                                onClick={() => handleReject(invite)}
                                variant={'secondary'}
                                size={'icon'}
                                radius={'full'}
                                className={'shadow-lg'}
                            />
                        </div>
                    }
                </td>
            </tr>
            {invite?.plusOnes?.map(plusOne => (tab === 'Rejected' || plusOne?.status !== 'Rejected') &&
                <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} relative text-xs md:text-sm`}>
                    <td className={`px-5 py-3 sticky left-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                        <div className='ml-2 flex flex-row items-center gap-1'>
                            <SvgSubDirectoryRight className={'text-gray-400'}/>
                            <p className={'text-gray-400'}>
                                +1
                            </p>
                            {renderPlusOneCell('', () => (
                                <div className='max-w-xs truncate font-semibold'>
                                    {`${plusOne.profile.first_name} ${plusOne.profile.last_name}`}
                                </div>
                            ))}
                        </div>
                    </td>
                    <td className='px-2 md:px-5 py-2 md:py-3 whitespace-nowrap'>{renderCell("host.profile.display_name", (displayName) =>
                        displayName || `${renderPlusOneCell('host.profile.first_name', content => content || "")} ${renderPlusOneCell('host.profile.last_name', content => content || "")}`
                    )}</td>
                    <td className='px-2 md:px-5 py-2 md:py-3'>
                        <div className='w-min whitespace-nowrap'>
                            <GroupTag group={plusOne?.group}/>
                        </div>
                    </td>
                    <td className='whitespace-nowrap px-5 py-3'>{renderPlusOneCell('profile.phone_number', (phoneNumber) =>
                        <p>
                            {parsePhoneNumber(`+1${phoneNumber}`)?.formatNational()}
                        </p>
                    )}</td>
                    <td className='px-5 py-3'>{renderPlusOneCell('profile.email')}</td>
                    <td className='px-5 py-3'>
                        {renderPlusOneCell('profile.date_of_birth', (dob) => (
                            <div className='max-w-xs truncate'>
                                {!!dob ? calculateAge(dob) : "--"}
                            </div>
                        ))}
                    </td>
                    <td className='px-5 py-3'>
                        {renderPlusOneCell('profile.gender', (gender) => (
                            <div
                                className='max-w-xs truncate'>{gender === 'Prefer Not To Say' ? '--' : gender === 'Male' ? 'M' : 'F'}</div>
                        ))}
                    </td>
                    <td className='px-5 py-3'>{renderPlusOneCell('profile.instagram', (handle) =>
                        <a
                            href={`https://www.instagram.com/${handle}`}
                            target="_blank"
                            className="text-cyan-500"
                        >
                            {`@${handle}`}
                        </a>
                    )}
                    </td>
                    <td className='px-5 py-3'>
                        {renderPlusOneCell('isPlusOne', () => (
                            <div className='max-w-xs truncate w-10'><SvgCheck/></div>
                        ))}
                    </td>
                    <td className='px-5 py-3'>
                        {renderPlusOneCell('justification', (justification) => (
                            <div className='max-w-xs truncate'>
                                {justification}
                            </div>
                        ))}
                    </td>
                    <td className={`px-2 py-3 sticky right-0`}>
                        {invite.status === 'Rejected' ?
                            invite.is_primary_guest ? null :
                                <Button
                                    icon={SvgUndo}
                                    onClick={() => handleUndoReject(plusOne)}
                                    variant={'secondary'}
                                    size={'icon'}
                                    radius={'full'}
                                    className={'shadow-lg'}
                                />
                            :
                            <div className='flex flex-row gap-3'>
                                <Button
                                    icon={SvgCheck}
                                    onClick={() => handleApprove(plusOne)}
                                    variant={'green'}
                                    size={'icon'}
                                    radius={'full'}
                                    className={'shadow-lg'}
                                />
                                <Button
                                    icon={SvgClose}
                                    onClick={() => handleReject(plusOne)}
                                    variant={'secondary'}
                                    size={'icon'}
                                    radius={'full'}
                                    className={'shadow-lg'}
                                />
                            </div>
                        }
                    </td>
                </tr>
            )
            }
        </>
    );
}

export default OrganizerApprovalsTableItem;
