import React from "react";
import SvgClose from "../icons/SvgClose";

const bgColors = [
    'bg-red-50', 'bg-green-50', 'bg-blue-50', 'bg-yellow-50', 'bg-purple-50',
    'bg-pink-50', 'bg-indigo-50', 'bg-orange-50', 'bg-teal-50',
];

const textColors = [
    'text-red-500', 'text-green-500', 'text-blue-500', 'text-yellow-500', 'text-purple-500',
    'text-pink-500', 'text-indigo-500', 'text-orange-500', 'text-teal-500',
];

function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0;
    }
    return Math.abs(hash);
}

function GroupTag({group, onRemove}) {
    group = group ?? "No Group";
    const index = group.length > 0 ? hashCode(group) % bgColors.length : bgColors.length - 1;
    const bgColor = bgColors[index];
    const textColor = textColors[index];

    return (
        <div className={`px-3 rounded-lg flex items-center flex-row gap-1 ${bgColor}`}>
            <p className={`capitalize ${textColor}`}>
                {group}
            </p>
            {!!onRemove &&
                <SvgClose className={'w-4 h-4 opacity-50 hover:opacity-100 cursor-pointer'} onClick={onRemove}/>
            }
        </div>
    );
}

export default GroupTag;
