import React, {useCallback, useState, useEffect} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import TextInputWrapper from "../atoms/TextInputWrapper";
import CheckboxItem from "../atoms/CheckboxItem";
import SvgDelete from "../icons/SvgDelete";
import ConfirmationModal from "./ConfirmationModal";
import { updateHostDetails, deleteHost } from '../../api/hosts';
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/consts";

function EditHostModal(props) {
    const [amount, setAmount] = useState(null)
    const [requiresApproval, setRequiresApproval] = useState(true)
    const [confirmDeleteHostModalOpen, setConfirmDeleteHostModalOpen] = useState(false)
    const [isClosed, setIsClosed] = useState(false)

    useEffect(() => {
        setAmount(props.selectedHost?.invite_limit)
        setRequiresApproval(props.selectedHost.requires_approval)
        setIsClosed(props.selectedHost.frozen)
    }, [props.selectedHost])

    const handleAmountChange = useCallback((event) => {
        const num = event.target.value
        setAmount(parseInt(num, 10))
    }, [])

    const openConfirmDeleteHostModal = useCallback(() => {
        setConfirmDeleteHostModalOpen(true)
    }, [])

    const closeConfirmDeleteHostModal = useCallback(() => {
        setConfirmDeleteHostModalOpen(false)
    }, [])

    const saveHostChanges = () => {
        if (amount < 0) {
            toast.error("Invite limit cannot be negative")
            return;
        }
        const isRegisteredUser = !!props.selectedHost?.profile_id;
        const updates = isRegisteredUser ? {
            invite_limit: amount,
            requires_approval: requiresApproval,
            frozen: isClosed,
        } : {
            type_attributes: {
                invite_limit: amount,
                requires_approval: requiresApproval,
                frozen: isClosed,
            }
        }

        toast.promise(
            updateHostDetails(props.currentEventId, props.selectedHost.profile_id || props.selectedHost.id, updates, isRegisteredUser),
            {
                pending: 'Updating host details...',
                success: 'Host details updated successfully!',
                error: 'Failed to update host details.'
            },
            TOAST_OPTIONS
        ).then(() => {
            props.close();
        }).catch(error => {
            console.error("Error updating host details:", error);
        });
    }

    const removeHost = () => {
        toast.promise(
            deleteHost(props.currentEventId, props.selectedHost.profile_id || props.selectedHost.id, !!props.selectedHost?.profile_id),
            {
                pending: 'Removing host...',
                success: 'Host removed successfully!',
                error: 'Failed to remove host.'
            },
            TOAST_OPTIONS
        ).then(() => {
            props.close();
        }).catch(error => {
            console.error("Error removing host:", error);
        });
    }

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={"Host settings"}
            description={'Manage this host and make any necessary changes.'}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={saveHostChanges}>
                        Save Changes
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col gap-3 w-full'>
                <TextInputWrapper
                    label={'Allotted # of additional invites'}
                    value={amount}
                    placeholder={'ie.) 50'}
                    type={'number'}
                    onChange={handleAmountChange}
                />
                <div className='px-3'>
                    <CheckboxItem
                        name={'requires-approval'}
                        label={'Requires approval'}
                        subLabel={'Uncheck to allow this user to add guests to the list without approval'}
                        checked={requiresApproval}
                        onChange={(e) => setRequiresApproval(!requiresApproval)}
                    />
                </div>
                <div className='p-3 rounded-lg bg-red-50'>
                    <CheckboxItem
                        name={'requires-approval'}
                        label={'Freeze host'}
                        subLabel={'Stop taking RSVPs from this host'}
                        checked={isClosed}
                        onChange={(e) => setIsClosed(!isClosed)}
                    />
                </div>
                <Button variant={'ghost'} icon={SvgDelete}
                        onClick={openConfirmDeleteHostModal}
                        className='text-red-400 hover:text-red-500'>
                    Delete this host
                </Button>
            </div>
            <ConfirmationModal
                isOpen={confirmDeleteHostModalOpen}
                title={'Are you sure you want to remove this host?'}
                description={"Any guests that are already approved will remain in the system."}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
                onConfirm={removeHost}
                close={closeConfirmDeleteHostModal}

            />
        </ModalComponent>
    );
}

export default EditHostModal;
