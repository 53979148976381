import SvgCalendar from "../icons/SvgCalendar";
import {DateTime} from "luxon";
import SvgClock from "../icons/SvgClock";
import TooltipComponent from "../atoms/TooltipComponent";
import React from "react";
import {Button} from "../atoms/Button";
import {useNavigate} from "react-router-dom";
import ImageLightbox from "../atoms/ImageLightbox";


function GuestEventItem({event, showDownloadTicket, isPastEvent}) {
    const navigate = useNavigate();
    return (
        <div className='shadow-xl bg-white flex flex-col gap-3 border border-slate-200 rounded-xl p-3'>
            {event.image_urls?.length > 0 && <ImageLightbox images={event.image_urls} />}
            {/*{event.image_urls?.length > 0 &&*/}
            {/*    <img alt={event.title + ' cover'} src={event.image_urls[0]}*/}
            {/*         className='w-full max-h-28 md:max-h-40 rounded-lg object-cover'/>*/}
            {/*}*/}
            <div className='flex flex-col gap-1'>
                <p className='text-lg font-semibold'>
                    {event.title}
                </p>
                {/*<p className='whitespace-nowrap truncate'>*/}
                {/*    {event.description}*/}
                {/*</p>*/}
            </div>
            <div className='flex flex-row items-center gap-4 md:gap-8 text-xs md:text-sm'>
                <p className='inline-flex'>
                    <SvgCalendar className={'w-4 h-4 md:h-5 md:w-5 mr-1'}/>
                    {`${DateTime.fromISO(event.start_timestamp).toFormat(`LLL d, y`)}`}
                </p>
                {!isPastEvent &&
                    <p className='inline-flex'>
                        <SvgClock className={'w-4 h-4 md:h-5 md:w-5 mr-1'}/>
                        {`Starts ${DateTime.fromISO(event.start_timestamp).toFormat("hh:mm a")}`}
                    </p>
                }
            </div>
            <div className='flex flex-col gap-1'>
                <div className='flex flex-col md:flex-row gap-1'>
                    <Button variant={'secondary'} className='w-full' onClick={() => navigate(`/event/${event?.id}`)}>
                        View Event
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default GuestEventItem
