import React, {useState, useEffect} from "react";
import TableHeaders from "../../atoms/TableHeaders";
import {host_invite_table_headers} from "../../../utils/consts";
import {sample_host_pending_invites} from "../../../utils/dummy";
import HostPendingInviteItem from "../tables/HostPendingInviteItem";
import HostInvitePanel from "../invites/HostInvitePanel";
import {connect} from 'react-redux';
import EditPendingInviteModal from '../../modals/EditPendingInviteModal';

const mapStateToProps = (state) => {
    return {
        hostId: state.userReducer.authSession.user.id,
        currentEventId: state.userReducer.currentEventId,
        guests: state.userReducer.guests,
    }
}

function HostInvitesView(props) {
    const [pendingInvites, setPendingInvites] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState(null);

    useEffect(() => {
      const invitedUsers = props.event.invited_users.filter(u => u.invited_by === props.hostId)
      const approvedUsers = props.guests[props.currentEventId].filter(g => g.status === 'Approved').map(g => ({
        name: `${g.profile?.first_name || ""} ${g.profile?.last_name || ""}`,
        phone_number: g.profile?.phone_number,
        email: g.profile?.email,
        created_at: g.created_at
      }))
      const pendingUsers = invitedUsers.concat(approvedUsers);
      setPendingInvites(pendingUsers);
    }, [props.event, props.guests, props.currentEventId, props.hostId])

    const openEditModal = (attendee) => {
      setSelectedInvite(attendee);
      setIsEditModalOpen(true);
    }

    const closeEditModal = () => {
      setSelectedInvite(null);
      setIsEditModalOpen(false);
    }

    return (
        <div className='flex flex-col md:flex-row items-start w-full flex-1 md:mt-4 gap-5'>
            <div className='mt-5 md:hidden flex flex-row items-center justify-between'>
                <p className='text-2xl font-semibold'>
                    Invites
                </p>
            </div>
            <div className='flex flex-col flex-1 w-full overflow-hidden gap-3'>
                <p className='text-lg font-bold'>
                    Pending Direct Invites
                </p>
                <div
                    className='flex-1 flex justify-start border border-gray-200 overflow-y-hidden overflow-x-scroll rounded-xl'>
                    <table className='w-full'>
                        <TableHeaders columns={host_invite_table_headers}/>
                        <tbody>
                        {pendingInvites.length > 0 ?
                            pendingInvites.map((attendee, index) => (
                                <HostPendingInviteItem
                                    attendee={attendee}
                                    index={index}
                                    openEditPendingInviteModal={openEditModal}
                                />
                            )) :
                            <tr className="h-[100px] text-center bg-gray-50">
                                <td colSpan="9">
                                    Invite guests using the button <span
                                    className='hidden md:inline'>to the right</span><span
                                    className='inline md:hidden'>below</span>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <HostInvitePanel/>
            <EditPendingInviteModal
              isOpen={isEditModalOpen}
              close={closeEditModal}
              eventId={props.event.id}
              selectedInvite={selectedInvite}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(HostInvitesView);
