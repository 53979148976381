import React, {useState, useEffect} from 'react';
import TooltipComponent from "../atoms/TooltipComponent";
import SvgCalendar from "../icons/SvgCalendar";
import SvgClock from "../icons/SvgClock";
import {DateTime} from 'luxon';
import {dateOrdinal} from '../../utils/helpers';
import EventPageHeaderDetails from "./EventPageHeaderDetails";

function EventPageHeaderComponent({event, filteredGuests, invitedGuests, hostObject}) {
    const [numInvitedGuests, setNumInvitedGuests] = useState(0);
    const [numAttendingGuests, setNumAttendingGuests] = useState(0);
    const [numRejectedGuests, setNumRejectedGuests] = useState(0);
    const [numMaleGuests, setNumMaleGuests] = useState(0);
    const [numFemaleGuests, setNumFemaleGuests] = useState(0);
    const [percentGenderSplit, setPercentGenderSplit] = useState(0);

    useEffect(() => {
        const explodedGuests = filteredGuests.reduce((a, g) => [
            ...a,
            g,
            ...(g.plusOne || [])
        ].filter(g => !!g), []);

        // setNumInvitedGuests(explodedGuests.filter(g => !!g.status && g.status !== 'Pending Host').length)
        setNumInvitedGuests(invitedGuests.length + explodedGuests.filter(g => ["Pending Host", "Pending Organizer", "Approved"].includes(g.status)).length)
        setNumAttendingGuests(explodedGuests.filter(g => g.status === 'Attending').length)
        setNumRejectedGuests(explodedGuests.filter(g => g.status === 'Rejected').length)
        const numMales = explodedGuests.filter(g => g.status === "Attending").filter(g => g.profile?.gender === 'Male').length
        const numFemales = explodedGuests.filter(g => g.status === "Attending").filter(g => g.profile?.gender === 'Female').length
        setNumMaleGuests(numMales)
        setNumFemaleGuests(numFemales)
        setPercentGenderSplit(numMales >= numFemales ? (numMales / (numFemales + numMales) * 100 || 0).toFixed(0) : (numFemales / (numMales + numFemales) * 100 || 0).toFixed(0))
    }, [filteredGuests])
    return (
        <div className='gap-5 lg:gap-16 max-w-[100vw] flex flex-col lg:flex-row lg:justify-between lg:items-center my-4'>
            <EventPageHeaderDetails event={event}/>
            <div className='flex flex-row items-center justify-start gap-2 sm:gap-5 lg:gap-9 bg-primary py-2 lg:py-6 px-6 rounded-xl'>
                {[
                    !!hostObject && {label: "# Invites Left", val: hostObject?.invite_limit || 0},
                    {label: "Invited", val: numInvitedGuests || 0},
                    {label: "Attending", val: numAttendingGuests || 0},
                    {label: "Rejected", val: numRejectedGuests || 0},
                    {
                        label: "Gender Split",
                        val: `${percentGenderSplit}% ${numMaleGuests >= numFemaleGuests ? 'M' : 'F'}`
                    },
                ].map((item, index) => {
                        if (item) {
                            return (
                                <div key={`${item.label}-${index}`} className='flex flex-col whitespace-nowrap '>
                                    <p className='text-lg md:text-2xl text-white font-semibold'>
                                        {item.val}
                                    </p>
                                    <p className='text-[11px] md:text-sm text-white/90 -mt-1 md:mt-0'>
                                        {item.label}
                                    </p>
                                </div>
                            )
                        }
                    }
                )}
            </div>
        </div>
    )
}

export default EventPageHeaderComponent;
