import React from "react";
import EventPageHeaderDetails from "../components/EventPageHeaderDetails";


function GuestEventPageHeader({event, hideLocation}) {
    return (
        <EventPageHeaderDetails event={event} isGuest hideLocation={hideLocation}/>
    )
}

export default GuestEventPageHeader
