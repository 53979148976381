import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import {deleteInvite} from '../../api/guests';

export default function EditPendingInviteModal(props) {

    const handleDeleteInvite = () => {
        deleteInvite(props.eventId, props.selectedInvite.id);
        props.close();
    }

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={"Edit Pending Invite"}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
            id={'settings-modal'}
        >
            <div className="flex flex-row items-center justify-between">
                <div>
                    <p>
                        Delete this invite.
                    </p>
                </div>
                <Button onClick={handleDeleteInvite} variant={'destructive'} className="">
                    Remove
                </Button>
            </div>
        </ModalComponent>
    )
}
