import React, {useCallback, useEffect, useState} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import SvgDelete from "../icons/SvgDelete";
import ConfirmationModal from "./ConfirmationModal";
import SelectComponent from "../atoms/SelectComponent";
import {host_options, TOAST_OPTIONS} from "../../utils/consts";
import {wait} from "@testing-library/user-event/dist/utils";
import {updateGuestDetails, deleteGuest} from '../../api/guests';
import {connect} from 'react-redux';
import {toast} from "react-toastify";

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        hosts: state.userReducer.hosts,
        guests: state.userReducer.guests,
        events: state.userReducer.userEvents,
        eventId: state.userReducer.currentEventId,
    }
}

function EditAttendeeModalOrganizer(props) {
    const [host, setHost] = useState({})
    const [hostOptions, setHostOptions] = useState([]);
    const [group, setGroup] = useState('')
    const [groupOptions, setGroupOptions] = useState([])
    const [confirmDeleteAttendeeModalOpen, setConfirmDeleteAttendeeModalOpen] = useState(false)
    const [isContentMounted, setIsContentMounted] = useState(false);

    useEffect(() => {
        setGroup(props.attendee.group)
        setHost(props.hosts[props.currentEventId].filter(h =>
            `${h.profile?.first_name} ${h.profile?.last_name}` === `${props.attendee.host?.profile?.first_name} ${props.attendee.host?.profile?.last_name}`
        )?.[0] || {});
    }, [props.attendee, props.isOpen])

    useEffect(() => {
        const _hosts = props.hosts[props.currentEventId];
        setHostOptions(_hosts?.map(h => ({title: `${h.profile.first_name} ${h.profile.last_name}`})))
    }, [props.currentEventId, props.hosts])

    useEffect(() => {
        if (props.isOpen) {
            wait(10).then(() => {
                setIsContentMounted(true);
            })
        } else {
            wait(100).then(() => {
                setIsContentMounted(false);
            })
        }
    }, [props.isOpen]);

    const handleGroupChange = useCallback((groupName) => {
        setGroup(groupName);
    }, [])

    const handleHostChange = useCallback((hostName) => {
        setHost(props.hosts[props.currentEventId].filter(h =>
            `${h.profile?.first_name} ${h.profile?.last_name}` === hostName
        )?.[0] || {});
    }, [])

    const openConfirmDeleteAttendeeModal = useCallback(() => {
        setConfirmDeleteAttendeeModalOpen(true)
    }, [])

    const closeConfirmDeleteAttendeeModal = useCallback(() => {
        setConfirmDeleteAttendeeModalOpen(false)
    }, [])

    const saveGuestChanges = () => {
        toast.promise(
            updateGuestDetails(props.currentEventId, props.attendee.profile_id, {
                group,
                host_id: host.profile_id
            }, props.attendee?.parent_guest),
            {
                pending: 'Saving guest changes...',
                success: 'Guest changes saved successfully!',
                error: 'Failed to save guest changes.'
            },
            TOAST_OPTIONS
        ).then(() => {
            props.close();
        }).catch(error => {
            console.error("Error saving guest changes:", error);
        });
    }

    const removeAttendee = () => {
        toast.promise(
            deleteGuest(props.currentEventId, props.attendee.profile_id),
            {
                pending: 'Removing attendee...',
                success: 'Attendee removed successfully!',
                error: 'Failed to remove attendee.'
            },
            TOAST_OPTIONS
        ).then(() => {
            props.close();
        }).catch(error => {
            console.error("Error removing attendee:", error);
        });
    }

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={"Guest settings"}
            description={'Manage this guest and make any necessary changes.'}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={saveGuestChanges}>
                        Save Changes
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
            id={'settings-modal'}
        >
            {isContentMounted &&
                <div className='flex flex-col gap-3 w-full' id={'settings-modal'}>
                    <SelectComponent
                        outerLabel={'Set group'}
                        label={'Select'}
                        value={group}
                        setValue={handleGroupChange}
                        options={(props.events?.[props.eventId]?.groups || ["VIP"]).map(g => ({title: g}))}
                        node={document.getElementById('settings-modal')}
                    />
                    <SelectComponent
                        outerLabel={'Set host'}
                        value={`${host?.profile?.first_name || ''} ${host?.profile?.last_name || ''}`}
                        setValue={handleHostChange}
                        options={hostOptions}
                        node={document.getElementById('settings-modal')}
                    />
                    <Button variant={'ghost'} icon={SvgDelete}
                            onClick={openConfirmDeleteAttendeeModal}
                            className='text-red-400 hover:text-red-500'>
                        Remove this attendee
                    </Button>
                </div>
            }
            <ConfirmationModal
                isOpen={confirmDeleteAttendeeModalOpen}
                title={'Are you sure you want to remove this attendee?'}
                description={"Any +1's that are already approved will also be removed"}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
                onConfirm={removeAttendee}
                close={closeConfirmDeleteAttendeeModal}

            />
        </ModalComponent>
    )
        ;
}

export default connect(mapStateToProps, null)(EditAttendeeModalOrganizer);
