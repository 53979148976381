import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
            fill={'currentColor'}
            {...props}
        >
            <path
                d="M6.5 1C3.46 1 1 3.46 1 6.5v11C1 20.53 3.46 23 6.5 23h11c3.03 0 5.5-2.47 5.5-5.5v-11C23 3.46 20.53 1 17.5 1h-11zm0 2h11C19.43 3 21 4.56 21 6.5v11c0 1.93-1.57 3.5-3.5 3.5h-11C4.56 21 3 19.43 3 17.5v-11C3 4.56 4.56 3 6.5 3zM18 4c-1.11 0-2 .89-2 2a2 2 0 102-2zm-6 2c-3.32 0-6 2.68-6 6 0 3.31 2.68 6 6 6 3.31 0 6-2.69 6-6 0-3.32-2.69-6-6-6zm0 2c2.2 0 4 1.79 4 4 0 2.2-1.8 4-4 4a3.999 3.999 0 110-8z"/>
        </svg>
    )
}

export default SvgComponent
