import TableHeaders from "../../atoms/TableHeaders";
import React, {useState, useEffect} from "react";
import {attendee_table_headers} from "../../../utils/consts";
import HostApprovalsTableItem from "./HostApprovalsTableItem";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        hostId: state.userReducer.authSession?.user?.id,
        event: state.userReducer.userEvents[state.userReducer.currentEventId],
        mobile: state.userReducer.mobile,
    }
}

function HostApprovalsTable({invites, isViewingRejections, tab, hostId, event, mobile}) {
    const [invitesAwaitingOrganizerApproval, setInvitesAwaitingOrganizerApproval] = useState([]);
    const [invitesAwaitingHostApproval, setInvitesAwaitingHostApproval] = useState([]);
    const [rejections, setRejections] = useState([]);

    useEffect(() => {
        const rejectedInvites = invites.filter(g => g.status === 'Rejected');
        const doubleCountedRejectedInvites = rejectedInvites.map(g => g.plusOne?.map(p => p?.profile_id))?.filter(e => !!e)?.reduce((arr, g) => [...arr, ...g], []);
        setRejections(rejectedInvites.filter(g => !doubleCountedRejectedInvites.includes(g.profile_id)));
        const organizerInvites = invites.filter(g => g.status === 'Pending Organizer');
        const doubleCountedOrganizerInvites = organizerInvites.map(g => g.plusOne?.map(p => p?.profile_id))?.filter(e => !!e)?.reduce((arr, g) => [...arr, ...g], []);
        setInvitesAwaitingOrganizerApproval(organizerInvites.filter(g => !doubleCountedOrganizerInvites.includes(g.profile_id)).map(g => ({...g, plusOne: g.plusOne?.filter(p => p.status === g.status)})));
        const hostInvites = invites.filter(g => g.status === 'Pending Host');
        const doubleCountedHostInvites = hostInvites.map(g => g.plusOne?.map(p => p?.profile_id))?.filter(e => !!e)?.reduce((arr, g) => [...arr, ...g], []);
        setInvitesAwaitingHostApproval(hostInvites.filter(g => !doubleCountedHostInvites.includes(g.profile_id)).map(g => ({...g, plusOne: g.plusOne?.filter(p => p.status === g.status)})));
    }, [invites])

    // map column name to required scope
    const colScopes = {
      'Age': 'age',
      'Gender': 'gender',
      'Instagram': 'social',
      'Is +1?': 'plusOne',
    }
    const headers = (mobile) ? 
      ["Name", "Group", ""]
      : attendee_table_headers.filter(col => {
        return !colScopes[col] || !!event.guest_scopes[colScopes[col]]
      })
    return (
        <>
            {!isViewingRejections &&
                <>
                    <p className={'font-bold -mb-3'}>
                        {`Awaiting Organizer Approval (${invitesAwaitingOrganizerApproval.length})`}
                    </p>
                    <div
                        className='w-full flex justify-start overflow-y-hidden overflow-x-scroll border border-gray-200 rounded-xl'>
                        <table className='w-full'>
                            <TableHeaders columns={headers}/>
                            <tbody>
                                {invitesAwaitingOrganizerApproval.length > 0 ?
                                    invitesAwaitingOrganizerApproval.map((invite, index) => (
                                        <HostApprovalsTableItem
                                            invite={invite}
                                            index={index}
                                            isAwaitingOrganizerApproval
                                            scopes={event.guest_scopes}
                                        />
                                    ))
                                    :
                                    <tr className="h-[100px] text-center bg-gray-50">
                                        <td colSpan="99">
                                           No guests are awaiting approval
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <p className={'font-bold -mb-3'}>
                        {`Awaiting My Approval (${invitesAwaitingHostApproval.length})`}
                    </p>
                </>
            }
            <div
                className='w-full flex justify-start overflow-y-hidden overflow-x-scroll border border-gray-200 rounded-xl'>
                <table className='w-full'>
                    <TableHeaders columns={headers}/>
                    <tbody>
                    {isViewingRejections ?
                        invites.length > 0 ?
                            rejections.map((invite, index) => (
                                <HostApprovalsTableItem
                                    invite={invite}
                                    index={index}
                                    scopes={event.guest_scopes}
                                />
                            ))
                            :
                            <tr className="h-[100px] text-center bg-gray-50">
                                <td colSpan="99">
                                    {`No guests ${tab === 'Pending' ? 'are awaiting approval' : 'have been rejected'}`}
                                </td>
                            </tr>
                        :
                        invitesAwaitingHostApproval.length > 0 ?
                            invitesAwaitingHostApproval.map((invite, index) => (
                                <HostApprovalsTableItem
                                    invite={invite}
                                    index={index}
                                    scopes={event.guest_scopes}
                                />
                            ))
                            :
                            <tr className="h-[100px] text-center bg-gray-50">
                                <td colSpan="99">
                                    {`No guests ${tab === 'Pending' ? 'are awaiting approval' : 'have been rejected'}`}
                                </td>
                            </tr>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default connect(mapStateToProps, null)(HostApprovalsTable)
