import React, {useCallback, useState} from "react";
import {Button} from "../../atoms/Button";
import SvgUserPlus from "../../icons/SvgUserPlus";
import PhoneInputWrapper from "../../atoms/PhoneInputWrapper";
import TextInputWrapper from "../../atoms/TextInputWrapper";
import TooltipComponent from "../../atoms/TooltipComponent";
import SvgHelp from "../../icons/SvgHelp";
import {wait} from "@testing-library/user-event/dist/utils";
import SvgCheckCircle from "../../icons/SvgCheckCircle";
import SvgSync from "../../icons/SvgSync";
import {quickInviteGuest} from 'api/guests';
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../../utils/consts";
import InviteGuestModal from 'components/modals/InviteGuestModal';

function HostQuickSendInvite(props) {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({ name: false, email: false, phone: false });
    const [errorMessage, setErrorMessage] = useState('');
    const [processing, setProcessing] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [contactType, setContactType] = useState('phone');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalReason, setModalReason] = useState("");

    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
        setErrors(errors => ({ ...errors, name: false }));
    }, []);

    const handleEmailChange = useCallback((event) => {
        setEmail(event.target.value.toLowerCase());
        setErrors(errors => ({ ...errors, email: false }));
    }, []);

    const handlePhoneChange = (value) => {
        setPhone(value);
        setErrors(errors => ({ ...errors, phone: false }));
    };

    const toggleContactType = () => {
        if (contactType === 'email') {
            setContactType("phone")
            setPhone("")
        } else {
            setContactType("email")
            setEmail("")
        }
    }

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleInviteSend = async () => {
        if (props.disabled) return;
        setProcessing(true);
        setErrorMessage("")

        toast.promise(
            quickInviteGuest(props.eventId, props.hostId, phone, name, email),
            {
                pending: 'Sending invite...',
                success: 'Invite sent successfully!',
                error: 'Failed to send invite.'
            },
            TOAST_OPTIONS
        )
            .then((res) => {
                // Actions to take upon successful invitation
                setIsSent(true);
                // Reset input fields and state after a brief delay
                wait(1000).then(() => { // Assuming wait is a function that returns a promise
                    setEmail('');
                    setPhone('');
                    setName('');
                    setErrorMessage('');
                    setIsSent(false);
                    setProcessing(false);
                    setErrors({ name: false, email: false, phone: false });
                });
            })
            .catch(error => {
                switch(error.message) {
                    case "NoAccount":
                        setModalOpen(true);
                        setModalReason(error.message);
                        break
                    case "ApprovalRequired":
                        setErrorMessage("Please add a phone number or email")
                        break
                    case "ZeroInvites":
                        setErrorMessage("You do not have any invites remaining.")
                        break
                    case "NameRequired":
                        setErrorMessage("You must add a name for this invite.")
                        break
                    case "AlreadyInvited":
                        setErrorMessage("This user has already been invited to this event.")
                        break
                    case "EventClosed":
                        setErrorMessage("This event is no longer accepting invites")
                        break
                    case "HostBlocked":
                        setErrorMessage("Guest has opted out of receiving invites from this host or the platform.")
                        break
                    case "FreePaywall":
                        setErrorMessage("Guest limit reached! Free tier events can only invite 100 guests.")  
                        break;
                    case "ProPaywall":
                        setErrorMessage("Guest limit reached! Pro tier events can only invite 500 guests.")  
                        break;
                    default:
                        break
                }

                // Error handling
                setProcessing(false);
                // Optionally, handle or display error details
            });
    }

    // Simplify the button disabled logic directly in the return statement
    const isButtonDisabled = props.disabled || (
        (name.trim() === '')
        &&
        (
            (contactType === 'email' && !validateEmail(email.trim())) ||
            (contactType === 'phone' && phone.trim().length < 10)
        )
    );

    return (
        <div className='flex flex-col gap-3'>
            <TooltipComponent
                ToggleComponent={
                    <div className='flex flex-row items-center group gap-2'>
                        <p className='text-lg font-bold'>
                            Quick Send Invite
                        </p>
                        <SvgHelp className={'w-4 h-4 opacity-50 group-hover:opacity-100 group-hover:text-primary'}/>
                    </div>
                }
                hover
                placement={'bottom-start'}
                label={'Direct invite with name and contact info.'}
            />
            <div className='flex flex-col md:flex-row gap-3'>
                <TextInputWrapper
                    label={'Name'}
                    placeholder={props.disabled ? "" : 'Enter a name'}
                    value={name}
                    onChange={handleNameChange}
                    error={errors.name ? "Please enter a name." : ""}
                    disabled={props.disabled}
                />
                <div className='relative'>
                    {contactType === 'phone' ?
                        <PhoneInputWrapper
                            label={'Phone'}
                            value={phone}
                            onChange={handlePhoneChange}
                            error={errors.phone ? "Invalid phone number." : ""}
                            disabled={props.disabled}
                        />
                        :
                        <TextInputWrapper
                            id={'email-input'}
                            label={'Email'}
                            value={email}
                            type={'email'}
                            placeholder={props.disabled ? "" : 'john.smith@gmail.com'}
                            onChange={handleEmailChange}
                            error={errors.email ? "Invalid email address." : ""}
                            disabled={props.disabled}
                        />
                    }
                    <div className='absolute -top-[3px] right-0'>
                        <button
                            onClick={toggleContactType}
                            className='text-sm font-medium text-primary hover:text-primary-600 cursor-pointer flex flex-row items-center gap-1'>
                            <SvgSync className={'w-4 h-4'}/> Use {contactType === 'phone' ? 'email' : 'phone'}
                        </button>
                    </div>
                </div>
            </div>
            {!!errorMessage && 
                <p className='text-md mt-2' style={{color: 'red'}}>
                    {errorMessage}
                </p>
            }
            <Button variant={isSent ? 'green' : 'default'}
                    loading={processing}
                    icon={isSent ? SvgCheckCircle : SvgUserPlus}
                    onClick={handleInviteSend}
                    disabled={isButtonDisabled}>
                {isSent ? 'Sent' : 'Send Invite'}
            </Button>
            <InviteGuestModal
                isOpen={modalOpen}
                close={() => setModalOpen(false)}
                reason={modalReason}
                phone={phone}
                name={name}
                email={email}
                hostId={props.hostId}
            />
        </div>
    );
}

export default HostQuickSendInvite;
