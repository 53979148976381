import React, {useState, useEffect, useCallback} from "react";
import UserInfoComponent from "../../atoms/UserInfoComponent";
import {Button} from "../../atoms/Button";
import SvgCheck from "../../icons/SvgCheck";
import {addScanner, removeScanner} from '../../../api/scanners';
import {connect} from 'react-redux';
import {TOAST_OPTIONS} from "../../../utils/consts";
import {toast} from "react-toastify";

const mapStateToProps = (state) => {
    return {
        eventId: state.userReducer.currentEventId,
    }
}

function OrganizerScannerListItem({user, isScanner, eventId, shouldOpenPaywall, openPaywall, addScannerId}) {
    const [_isScanner, setIsScanner] = useState(false);

    useEffect(() => {
        setIsScanner(isScanner);
    }, [isScanner]);

    const handleAddScanner = () => {
      if (shouldOpenPaywall) {
          openPaywall();
          return;
      }
      addScannerId(user.profile_id)

        toast.promise(
            addScanner(eventId, user.profile_id), // Make sure this returns a promise
            {
                pending: 'Adding scanner...',
                success: {
                    render({data}) {
                        setIsScanner(true); // Only set scanner state on success
                        return 'Scanner added successfully!';
                    },
                },
                error: 'Failed to add scanner.'
            },
            TOAST_OPTIONS
        ).catch(error => {
            // Handle any additional logic on error, if necessary
            console.error("Error adding scanner:", error);
        });
    };

    const handleRemoveScanner = useCallback(() => {
        toast.promise(
            removeScanner(eventId, user.profile_id), // Make sure this returns a promise
            {
                pending: 'Removing scanner...',
                success: {
                    render({data}) {
                        setIsScanner(false); // Only unset scanner state on success
                        return 'Scanner removed successfully!';
                    },
                },
                error: 'Failed to remove scanner.'
            },
            TOAST_OPTIONS
        ).catch(error => {
            // Handle any additional logic on error, if necessary
            console.error("Error removing scanner:", error);
        });
    }, [eventId, user.profile_id, setIsScanner]);

    return (
        <li className={`flex flex-col 
         border border-gray-200 rounded-xl mt-2 md:mt-3 shadow-sm p-3
         md:p-5 md:last:border-none md:shadow-none md:flex-row md:justify-between
        `} key={user.id}>
            <UserInfoComponent user={user}/>
            <div className='flex flex-row w-full mt-4 md:mt-0 md:w-fit items-center gap-3.5'>
                {!!_isScanner &&
                    <div className='flex flex-row items-center gap-1'>
                        <p className='text-xs font-bold text-cyan-500 whitespace-nowrap'>
                            Scanner
                        </p>
                        <SvgCheck className={'text-cyan-500 w-3 h-3 md:w-5 md:h-5'}/>
                    </div>
                }
                {_isScanner ?
                    <Button variant={'secondary'} className='w-full md:w-fit' onClick={handleRemoveScanner}>
                        Remove scanner
                    </Button>
                    :
                    <Button onClick={handleAddScanner} className='w-full md:w-fit'>
                        Add as scanner
                    </Button>
                }
            </div>
        </li>
    )
}

export default connect(mapStateToProps, null)(OrganizerScannerListItem);
