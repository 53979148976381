import React, {useCallback, useState} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import PhoneInputWrapper from "../atoms/PhoneInputWrapper";
import TextInputWrapper from "../atoms/TextInputWrapper";
import InviteGuestModal from './InviteGuestModal';
import { sendPlusOneInvite } from 'api/guests';
import SvgSync from "../icons/SvgSync";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgUserPlus from "../icons/SvgUserPlus";
import {wait} from "@testing-library/user-event/dist/utils";

function InvitePlusOneModal(props) {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState({name: false, email: false, phone: false});
    const [errorMessage, setErrorMessage] = useState('');
    const [processing, setProcessing] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [contactType, setContactType] = useState('phone');
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [modalReason, setModalReason] = useState("");

    const handleNameChange = useCallback((event) => {
        setName(event.target.value);
        setErrors(errors => ({...errors, name: false}));
    }, []);

    const handleEmailChange = useCallback((event) => {
        setEmail(event.target.value.toLowerCase());
        setErrors(errors => ({...errors, email: false}));
    }, []);

    const handlePhoneChange = useCallback((value) => {
        setPhone(value);
        setErrors(errors => ({...errors, phone: false}));
    }, []);

    const toggleContactType = () => {
        if (contactType === 'email') {
            setContactType("phone")
            setPhone("")
        } else {
            setContactType("email")
            setEmail("")
        }
    }

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleInviteSend = async (overrideNoAccount=false) => {
        try {
            setProcessing(true);
            await sendPlusOneInvite(props.eventId, props.profileId, props.hostId, phone, email, name, props.inviteLimit, overrideNoAccount);
            setProcessing(false);
        } catch (error) {
            switch(error.message) {
                case "NoAccount":
                    setErrorModalOpen(true);
                    setModalReason(error.message);
                    break
                case "ApprovalRequired":
                case "ZeroInvites":
                    setErrorMessage("You do not have any invites remaining.")
                    break
                case "NameRequired":
                    setErrorMessage("You must add a name for this invite.")
                    break
                case "AlreadyInvited":
                    setErrorMessage("This user has already been invited to this event.")
                    break
                default:
                    break
            }
            setProcessing(false);
            return;
        }
        
        setIsSent(true);
        setEmail('');
        setPhone('');
        setName('');
        setIsSent(false);
        setProcessing(false);
        props.close();
        window.location.reload();
    };

    // Simplify the button disabled logic directly in the return statement
    const isButtonDisabled = 
        (contactType === 'email' && !validateEmail(email.trim())) ||
        (contactType === 'phone' && phone.trim().length < 10);

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={'Send an invite'}
            description={"Add a phone number or email to send an invite. You'll be able to track the status of your invites on the event page."}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button variant={isSent ? 'green' : 'default'}
                            loading={processing}
                            icon={isSent ? SvgCheckCircle : SvgUserPlus}
                            onClick={() => handleInviteSend()}
                            disabled={isButtonDisabled}>
                        {isSent ? 'Sent' : 'Send Invite'}
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col gap-5'>
                <TextInputWrapper
                    label={'Name'}
                    placeholder={'Enter a name'}
                    value={name}
                    onChange={handleNameChange}
                    error={errors.name ? "Please enter a name." : ""}
                />
                <div className='relative'>
                    {contactType === 'phone' ?
                        <PhoneInputWrapper
                            label={'Phone'}
                            value={phone}
                            onChange={handlePhoneChange}
                            error={errors.phone ? "Invalid phone number." : ""}
                        />
                        :
                        <TextInputWrapper
                            id={'email-input'}
                            label={'Email'}
                            value={email}
                            type={'email'}
                            placeholder={'john.smith@gmail.com'}
                            onChange={handleEmailChange}
                            error={errors.email ? "Invalid email address." : ""}
                        />
                    }
                    <p className='text-md mt-2' style={{color: 'red'}}>
                        {errorMessage}
                    </p>
                    <div className='absolute -top-[3px] right-0'>
                        <button
                            onClick={toggleContactType}
                            className='text-sm font-medium text-primary hover:text-primary-600 cursor-pointer flex flex-row items-center gap-1'>
                            <SvgSync className={'w-4 h-4'}/> Use {contactType === 'phone' ? 'email' : 'phone'}
                        </button>
                    </div>
                </div>
                <InviteGuestModal
                    isOpen={errorModalOpen}
                    close={() => setErrorModalOpen(false)}
                    reason={modalReason}
                    phone={phone}
                    name={name}
                    email={email}
                    submit={() => handleInviteSend(true)}
                    hostId={props.hostId}
                />
            </div>
        </ModalComponent>
        
    );
}

export default InvitePlusOneModal;
