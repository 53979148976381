import ModalComponent from '../atoms/ModalComponent';
import SvgClose from '../icons/SvgClose';
import {Button} from '../atoms/Button';

export default function Modal(props) {

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            header={'Apple vs Google Account'}
            headerToggle
        >
            <div>

                <p>Not using the account type that matches your mobile device will prevent you from downloading an entry
                    pass for the event. You will instead have to manually check-in at the entrance with your name and
                    physical ID, which may take longer.</p>
                <br/>
                <p>Using a “hidden” email address may also confuse the host that has invited you to this event, while
                    also making future invitations more difficult to receive and RSVP to.</p>
                <p className={'text-slate-500 text-xs mt-3'}>
                    Please read the Data and
                    Privacy Policy to see how your data is used and stored, or email support with any questions.
                </p>
                <div className="flex w-full mt-4 justify-center">
                    <Button
                        onClick={props.close}
                        className="w-3/4"
                    >
                        Close
                    </Button>
                </div>
            </div>
        </ModalComponent>
    )
}
