import React, {useCallback, useEffect, useState} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import SelectComponent from "../atoms/SelectComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import { hostUpdateGuestDetails } from 'api/guests';
import {connect} from 'react-redux';
import TextInputWrapper from "../atoms/TextInputWrapper";
import { deleteGuest } from '../../api/guests';

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        hosts: state.userReducer.hosts,
        guests: state.userReducer.guests,
        authSession: state.userReducer.authSession,
        events: state.userReducer.userEvents,
        eventId: state.userReducer.currentEventId,
    }
}

function EditAttendeeModalHost(props) {
    const [group, setGroup] = useState('')
    const [groupOptions, setGroupOptions] = useState([]);
    const [myHostObject, setHostObject] = useState({});
    const [isContentMounted, setIsContentMounted] = useState(false);
    const [originalInviteLimit, setOriginalInviteLimit] = useState(0);
    const [inviteLimit, setInviteLimit] = useState(0);

    useEffect(() => {
        setGroup(props.attendee.group)
        const _hosts = props.event.hosts;
        setHostObject(_hosts?.filter(host => host.profile_id === props.authSession.user.id)[0]);
        setInviteLimit(props.attendee?.invite_limit || 0)
        setOriginalInviteLimit(props.attendee?.invite_limit || 0)
    }, [props.attendee, props.isOpen, props.event])

    useEffect(() => {
        if (props.isOpen) {
            wait(10).then(() => {
                setIsContentMounted(true);
            })
        } else {
            wait(100).then(() => {
                setIsContentMounted(false);
            })
        }
    }, [props.isOpen]);

    const handleGroupChange = useCallback((groupName) => {
        setGroup(groupName);
    }, [])

    const handleInviteLimitChange = useCallback((event) => {
        setInviteLimit(event.target.value);
    }, []);


    // Todo: add toasts like the organizer version of this modal
    const saveGuestChanges = async () => {
        // prevent negative invite limit
        const limit = Number(inviteLimit);
        if (limit < 0)
          return;

        // prevent negative host invite limit as a result of allocation
        const invitesChange = limit - originalInviteLimit;
        if (myHostObject.invite_limit - invitesChange < 0)
          return

        await hostUpdateGuestDetails(props.currentEventId, props.attendee.profile_id, props.authSession.user.id, group, inviteLimit, myHostObject.invite_limit - invitesChange)
 
        props.close();
        window.location.reload()
    }

    const handleDeleteGuest = () => {
      deleteGuest(props.event.id, props.attendee.profile_id);
      props.close();
    }

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={"Guest settings"}
            description={'Manage this guest and make any necessary changes. Group changes affect this guest and their +1s.'}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={saveGuestChanges}>
                        Save Changes
                    </Button>
                    <Button onClick={handleDeleteGuest} variant={'destructive'} className="w-full">
                      Remove Guest
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                        Cancel
                    </Button>
                </div>
            }
            id={'settings-modal'}
        >
            {isContentMounted &&
                <div className='flex flex-col gap-3 w-full' id={'settings-modal'}>
                    <SelectComponent
                        outerLabel={'Set group'}
                        label={'Select'}
                        value={group}
                        setValue={handleGroupChange}
                        options={(props.events?.[props.eventId]?.groups || ["VIP"]).map(g => ({title: g}))}
                        node={document.getElementById('settings-modal')}
                    />
                    {!myHostObject?.requires_approval &&
                        <TextInputWrapper
                            label={'Num. generic +1s'}
                            value={inviteLimit}
                            type={'number'}
                            placeholder={'john.smith@gmail.com'}
                            onChange={handleInviteLimitChange}
                        />
                    }
                </div>
            }
        </ModalComponent>
    )
        ;
}

export default connect(mapStateToProps, null)(EditAttendeeModalHost);
