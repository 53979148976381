import React, {useCallback, useState, useEffect} from "react";
import SvgCopy from "../../icons/SvgCopy";
import SvgHelp from "../../icons/SvgHelp";
import SvgTrash from "../../icons/SvgTrash";
import SvgToggle from "../../icons/SvgToggle";
import TooltipComponent from "../../atoms/TooltipComponent";
import {createShortLink, fetchShortLinks, deleteShortLink, updateShortLinkStatus} from 'api/hosts';
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../../utils/consts";
import TextInputWrapper from "../../atoms/TextInputWrapper";
import {Button} from "../../atoms/Button";
import HostInviteLinkListItem from "./HostInviteLinkListItem";
import ConfirmationModal from "../../modals/ConfirmationModal";

function HostInviteLink(props) {
    const [inviteLinks, setInviteLinks] = useState([]);
    const [label, setLabel] = useState("");
    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [linkToDelete, setLinkToDelete] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handle = async () => {
      const shortlinks = await fetchShortLinks(props.eventId, props.hostId);
      setInviteLinks(shortlinks)
    }

    handle();
  }, [])

    const handleGenerateLink = useCallback(async () => {
        if (!label.trim()) {
            toast.error("Label cannot be empty", TOAST_OPTIONS);
            return;
        }
        setIsProcessing(true);
        try {
            const inviteLink = await createShortLink(props.eventId, props.hostId, label);
            const short_link = `${window.location.origin}/invite/${inviteLink.short_link}`;
            const newLink = {id: inviteLink.id, label, short_link, deactivated: false};
            setInviteLinks([...inviteLinks, newLink]);

            setLabel("");
            toast.success("Invite link generated", TOAST_OPTIONS);
        } catch (error) {
            toast.error("Failed to generate invite link.", TOAST_OPTIONS);
        } finally {
            setIsProcessing(false);
        }
    }, [label, inviteLinks, props.eventId, props.hostId]);

    const handleToggleLink = async (index) => {
        const updatedLinks = await Promise.all(inviteLinks.map(async (link, i) => {
            if (i === index) {
                const newActiveStatus = !link.deactivated;
                await updateShortLinkStatus(link.id, newActiveStatus);
                toast.success(`Link ${!newActiveStatus ? 'activated' : 'paused'}`, TOAST_OPTIONS);
                return {...link, deactivated: newActiveStatus};
            }
            return link;
        })).then(values => values);
        setInviteLinks(updatedLinks);
    };


    const handleDeleteLink = (index) => {
        setLinkToDelete(index);
        setConfirmDeleteModalOpen(true);
    };

    const confirmDeleteLink = async () => {
        const deleteId = inviteLinks.filter((_, i) => i === linkToDelete)?.[0]?.id;
        await deleteShortLink(deleteId);
        const updatedLinks = inviteLinks.filter((_, i) => i !== linkToDelete);
        toast.success(`Link removed`, TOAST_OPTIONS);
        setInviteLinks(updatedLinks);
        setConfirmDeleteModalOpen(false);
    };

    const handleCopy = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            toast.success("Invite link copied to clipboard!", TOAST_OPTIONS);
        }).catch(error => {
            toast.error("Failed to copy invite link.", TOAST_OPTIONS);
        });
    };

    return (
        <div className='flex flex-col gap-3'>
            <TooltipComponent
                ToggleComponent={
                    <div className='flex flex-row items-center group gap-2'>
                        <p className='text-lg font-bold'>
                            Your Links
                        </p>
                        <SvgHelp className={'w-4 h-4 opacity-50 group-hover:opacity-100 group-hover:text-primary'}/>
                    </div>
                }
                hover
                placement={'bottom-start'}
                label={"Generate and manage multiple invite links for your event. Anyone will be able to request to attend with your links. You'll see guests who use them appear in your Approvals tab. You can label each link for personal organization."}
            />
            <div className='flex flex-row gap-2'>
                <TextInputWrapper
                    type='text'
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    placeholder='Enter a label for your link'
                    className='border rounded-xl p-2 flex-1'
                />
                <Button
                    onClick={handleGenerateLink}
                    loading={isProcessing}
                    variant={'default'}
                >
                    Generate
                </Button>
            </div>
            {inviteLinks.length > 0 &&
                <div className='flex flex-col gap-2'>
                    {inviteLinks.map((invite, index) => (
                        <HostInviteLinkListItem
                            key={index}
                            invite={invite}
                            onCopy={() => handleCopy(invite.short_link)}
                            onToggle={() => handleToggleLink(index)}
                            onDelete={() => handleDeleteLink(index)}
                        />
                    ))}
                </div>
            }
            <p className='text-xs text-gray-500'>
                Links expire 2h before start of the event
            </p>
            <ConfirmationModal
                isOpen={confirmDeleteModalOpen}
                title={'Are you sure you want to remove this link?'}
                description={"This action cannot be undone. Users that have access to the old link won't be able to request an RSVP with it."}
                confirmText={'Confirm'}
                cancelText={'Cancel'}
                onConfirm={confirmDeleteLink}
                close={() => setConfirmDeleteModalOpen(false)}
            />
        </div>
    );
}

export default HostInviteLink;
