// ImageLightbox.js
import React, {useState} from "react";
import SvgClose from "../icons/SvgClose";
import SvgChevronRight from "../icons/SvgChevronRight";
import SvgExpand from "../icons/SvgExpand";
import {Button} from "./Button";

const ImageLightbox = ({images, className}) => {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const openLightbox = (index) => {
        setCurrentIndex(index);
        setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
        setIsLightboxOpen(false);
    };

    const showNext = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const showPrev = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            closeLightbox();
        }
    };

    return (
        <>
            <div className='relative cursor-pointer group w-full' onClick={() => openLightbox(0)}>
                {images.length > 0 && (
                    <img
                        alt={`Event Image ${currentIndex + 1}`}
                        src={images[0]}
                        className={(className || 'w-full md:max-h-40 rounded-lg object-cover')}
                    />
                )}
                <div className='absolute bottom-2 right-2 text-white group-hover:text-primary-600 flex flex-row items-center gap-3'>
                    {images.length > 1 &&
                        <div className='bg-white border-slate-300 px-3 py-0.5 rounded-full text-xs text-black font-semibold'>
                            +{images.length - 1} Image{images.length !== 2 && 's'}
                        </div>
                    }
                    <SvgExpand/>
                </div>
            </div>
            {isLightboxOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center"
                    onClick={handleBackdropClick}
                >
                    <Button size={'icon'} variant={'default'}  className="absolute top-4 right-4 text-white text-2xl" onClick={closeLightbox}>
                        <SvgClose/>
                    </Button>
                    {images.length > 1 && (
                        <Button size={'icon'} variant={'primary'}  className="absolute left-4" onClick={showPrev}>
                            <SvgChevronRight className="rotate-180"/>
                        </Button>
                    )}
                    <img
                        src={images[currentIndex]}
                        alt={`Event Image ${currentIndex + 1}`}
                        className="max-w-full max-h-full p-4"
                    />
                    {images.length > 1 && (
                        <Button size={'icon'} variant={'primary'} className="absolute right-4" onClick={showNext}>
                            <SvgChevronRight/>
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};

export default ImageLightbox;
