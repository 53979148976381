import React, {useCallback, useState, useEffect} from "react";
import OrganizerHostListItem from "../tables/OrganizerHostListItem";
import {Button} from "../../atoms/Button";
import AddHostModal from "../../modals/AddHostModal";
import EditHostModal from "../../modals/EditHostModal";
import {sample_hosts} from "../../../utils/dummy";
import SvgUserPlus from "../../icons/SvgUserPlus";
import {connect} from 'react-redux';
import InviteHostPaywallModal from '../../modals/InviteHostPaywallModal';

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        guests: state.userReducer.guests,
        hosts: state.userReducer.hosts,
        userEvents: state.userReducer.userEvents,
        tier: state.userReducer.userProfile.tier,
    }
}

function OrganizerHostManagementView(props) {
    const [hosts, setHosts] = useState([]);
    const [selectedHost, setSelectedHost] = useState({});
    const [guests, setGuests] = useState([]);
    const [isAddHostModalOpen, setIsAddHostModalOpen] = useState(false)
    const [isEditHostModalOpen, setIsEditHostModalOpen] = useState(false)
    const [hostPaywallOpen, setHostPaywallOpen] = useState(false);

    useEffect(() => {
        const _hosts = props.hosts[props.currentEventId] || [];
        const invitedHosts = props.userEvents[props.currentEventId]
            .invited_users
            .filter(u => u.type === 'Host')
            .map(u => ({
                id: u.id,
                event_id: u.event_id,
                frozen: u.type_attributes?.frozen,
                invite_limit: u.type_attributes?.invite_limit,
                requires_approval: u.type_attributes?.requires_approval,
                profile: {
                    first_name: !u.name ? 'Invited Host' : u.name,
                    phone_number: u.phone_number,
                    email: u.email
                }
            }))
        setHosts(_hosts.concat(invitedHosts));
        const _guests = props.guests[props.currentEventId];
        setGuests(_guests || []);
    }, [props.currentEventId, props.guests, props.hosts, props.userEvents])

    const openAddHostModal = useCallback(() => {
      if (props.tier === 'Free' && hosts.length > 2) {
          setHostPaywallOpen(true);
          return;
      } else if (props.tier === 'Pro' && hosts.length > 9) {
          setHostPaywallOpen(true);
          return;
      }

      setIsAddHostModalOpen(true)
    },[hosts.length, props.tier])

    const closeAddHostModal = useCallback(() => {
        setIsAddHostModalOpen(false)
    },[])

    const openEditHostModal = useCallback((host) => {
        setSelectedHost(host)
        setIsEditHostModalOpen(true)
    },[])

    const closeEditHostModal = useCallback(() => {
        setIsEditHostModalOpen(false)
    },[])

    return (
        <div className='flex flex-col flex-1 relative'>
            <div className='mt-4 md:hidden flex flex-row items-center justify-between'>
                <p className='text-2xl font-semibold'>
                    Hosts
                </p>
                {hosts.length > 0 &&
                    <Button icon={SvgUserPlus} onClick={openAddHostModal} className='ml-auto'>
                        Add a host
                    </Button>
                }
            </div>
            <Button icon={SvgUserPlus} onClick={openAddHostModal}
                    className='ml-auto absolute hidden md:block right-0 -top-14 z-[10]'>
                Add a host
            </Button>
            <ul>
                {hosts.map((item) =>
                    <OrganizerHostListItem
                        user={item}
                        guests={guests}
                        openEditHostModal={openEditHostModal}
                    />
                )}
                <div className="mx-auto text-gray-500 my-12 text-center rounded-full">
                    {hosts.length === 0 && <p className='mb-5'>No hosts to show</p>}
                    <Button icon={SvgUserPlus} onClick={openAddHostModal}
                            className=''>
                        Add a host
                    </Button>
                </div>
            </ul>
            <EditHostModal
                isOpen={isEditHostModalOpen}
                close={closeEditHostModal}
                selectedHost={selectedHost}
                currentEventId={props.currentEventId}
            />
            <AddHostModal
                isOpen={isAddHostModalOpen}
                close={closeAddHostModal}
                currentEventId={props.currentEventId}
            />
            <InviteHostPaywallModal
              isOpen={hostPaywallOpen}
              close={() => setHostPaywallOpen(false)}
              tier={props.tier}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(OrganizerHostManagementView);
