import React, {useCallback, useEffect, useState} from "react";
import OrganizerHostListItemSummary from "./OrganizerHostListItemSummary";
import CollapseComponent from "../../atoms/CollapseComponent";
import OrganizerGuestListTable from "./OrganizerGuestListTable";

function OrganizerHostListItem({user, openEditHostModal, guests}) {
    const [hostGuests, setHostGuests] = useState([]);
    const [isListCollapsed, setIsListCollapsed] = useState(false)

    useEffect(() => {
        const _hostGuests = guests.filter(g =>
            g.host_id === user.profile_id
        ).map(g => ({
            ...g,
            plusOne: g.plusOne?.host_id === g.host_id ? g.plusOne : null
        }))
        const doubleCountedPlusOnes = _hostGuests.map(g => g.plusOne?.profile_id).filter(e => !!e);
        setHostGuests(_hostGuests.filter(g => !doubleCountedPlusOnes.includes(g.profile_id)))
    }, [guests, user])

    const toggleCollapse = useCallback(() => {
        setIsListCollapsed(!isListCollapsed)
    }, [isListCollapsed])

    return (
        <li className='flex flex-col
        border border-gray-200 rounded-xl mt-2 md:mt-3 shadow-sm
        md:border-b md:border-gray-200 md:px-0 md:py-4 md:last:border-none md:shadow-none
        relative z-[0]' key={user.id}>
            <OrganizerHostListItemSummary
                user={user}
                isListCollapsed={isListCollapsed}
                toggleCollapse={toggleCollapse}
                openEditHostModal={openEditHostModal}
            />
            <CollapseComponent isOpen={isListCollapsed}>
                <div className='w-full p-2 md:pt-4 '>
                    <OrganizerGuestListTable
                        attendees={hostGuests}
                        viewingAs={'organizer'}
                    />
                </div>
            </CollapseComponent>
        </li>
    )
}

export default OrganizerHostListItem
