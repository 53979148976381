import {useState, useEffect} from 'react';

import AuthLandingView from './AuthLandingView';
import AuthEnterPhoneNumberView from './AuthEnterPhoneNumberView';
import SvgLogoMark from "../../components/atoms/SvgLogoMark";
import {MeshGradientRenderer} from "@johnn-e/react-mesh-gradient";

const AuthPage = () => {
    const [authView, setAuthView] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCodeError, setVerificationCodeError] = useState('');

    useEffect(() => {
        setAuthView('landing');
    }, [])

    const renderAuthView = () => {
        switch (authView) {
            case "landing":
                return <AuthLandingView setAuthView={setAuthView} setPhoneNumber={setPhoneNumber}
                                        setError={setVerificationCodeError}/>;
            case "enterPhone":
                return <AuthEnterPhoneNumberView setAuthView={setAuthView} phoneNumber={phoneNumber}
                                                 error={verificationCodeError}/>;
            default:
                return <></>;
        }
    };

    return (
        <div className='min-h-[100dvh] flex flex-col items-center justify-center relative'>
            <MeshGradientRenderer
                className='absolute opacity-20 top-0 left-0 right-0 bottom-0 hue-rotate-90'
                colors={['#eaa166', '#a43348', '#ff7575', '#f5c7cf', '#d5ae13']}
            />
            <div className='px-2 flex flex-col items-center w-full'>
                {renderAuthView()}
            </div>
        </div>
    );
}

export default AuthPage;
