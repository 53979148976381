import supabase from './supabase';
import store from '../Store';
import axios from './axios';

export const createProfile = async (profile) => {
    const url = "/profile/create"
    const res = await axios.post(url, {
        ...profile,
        inviteEventId: window.sessionStorage.getItem("opassityInviteLinkEventId"),
        inviteHostId: window.sessionStorage.getItem("opassityInviteLinkHostId"),
        inviteParentId: window.sessionStorage.getItem("opassityInviteLinkParentId"),
    });
    const data = res.data;

    // supabase caches auth user which does not refresh on reload.
    // manually set onboarded to true
    if (data.response === 'OK') {
        const key = "sb-jvkoggxvpivczefygqcr-auth-token";
        const session = JSON.parse(window.localStorage.getItem(key))
        session.user.user_metadata.onboarded = true;
        session.user.phone = profile.phoneNumber;
        localStorage.setItem(key, JSON.stringify(session));
    }

}

export const checkProfileExists = async (phoneNumber) => {
    const { data, error } = await supabase.from("profiles").select("id").eq("phone_number", phoneNumber);
    return data.length > 0;
}

export const editProfile = async (profileId, updates) => {
  const instagram = updates?.instagram;
  const display_name = updates?.display_name;
  const filteredUpdates = {
    ...(!!instagram && {instagram}),
    ...(!!display_name && {display_name}),
  }

  const { error } = await supabase
                    .from("profiles")
                    .update(filteredUpdates)
                    .eq("id", profileId)
  if (!!error)
    throw new Error("Unable to update profile")
}
