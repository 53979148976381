import supabase from './supabase';
import store from '../Store';
import axios from 'api/axios';
import { generateInviteLink } from 'api/hosts';
import { bufferToBase64Url, base64UrlToBuffer } from 'utils/helpers';

let prevSession = {};
export const listenAuthEvents = (callback) => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
        // prevent refresh on tab focus (https://github.com/supabase/supabase/issues/7250)
        if (!!prevSession?.user?.id && prevSession?.user?.id === session?.user?.id) return;
        prevSession = session;

        switch (event) {
            case "INITIAL_SESSION":
            case "SIGNED_IN":
            case "SIGNED_OUT":
            case "TOKEN_REFRESHED":
              callback(session);
              store.dispatch({
                  type: "SET_AUTH_SESSION",
                  authSession: session
              })
              break;
            default:
              throw new Error("Should never reach here")
        }
    })
}

export const signOut = async () => {
    const { error } = await supabase.auth.signOut();
}

// deprecated
// export const signInWithEmail = async () => {
//     const { data, error } = await supabase.auth.signInWithPassword({
//         email: 'testfoobar@test.com',
//         password: 'testfoobar'
//     })
// }

const getRedirectUrl = () => {
    if (window.sessionStorage.getItem("opassityInviteLinkEventId")) {
        const eventId = window.sessionStorage.getItem("opassityInviteLinkEventId");
        const hostId = window.sessionStorage.getItem("opassityInviteLinkHostId");
        return generateInviteLink(eventId, hostId);
    } else {
        return `${window.location.origin}/`
    }
}

export const signInWithApple = async () => {
    const res = await supabase.auth.signInWithOAuth({
        provider: "apple",
        options: {
            redirectTo: getRedirectUrl()
        }
    })
}

export const signInWithGoogle = async () => {
    const res = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
            redirectTo: getRedirectUrl()
        }
    })
}

export const registerWithPasskey = async () => {
    // Step 1: Request challenge from server
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/register?user_name=Opassity`)
    const json = await res.json();
    json.publicKey.user.id = base64UrlToBuffer(json.publicKey.user.id)
    json.publicKey.challenge = base64UrlToBuffer(json.publicKey.challenge)
    try {
        // Step 2: Generate pubkey/privkey pair
        const credential = await window.navigator.credentials.create(json);

        // step 3: Send PublicKeyCredential to server
        const _credential = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64Url(credential.rawId),
            response: {
                attestationObject: bufferToBase64Url(credential.response.attestationObject),
                clientDataJSON: bufferToBase64Url(credential.response.clientDataJSON),
            },
            type: credential.type,
            user: {
                id: bufferToBase64Url(json.publicKey.user.id)
            }
        }
        const res2 = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/verify-registration`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(_credential)
        })
        const json2 = await res2.json();

        // sign in
        if (json2.user) {
            const { data, error } = await supabase.auth.setSession({
                access_token: json2.user?.session?.access_token,
                refresh_token: json2.user?.session?.refresh_token
            })
            window.location.href = "/"
        }
    } catch (err) {
        // user cancelled passkey flow
    }
}

export const signInWithPasskey = async () => {
    try {
        // Step 1: Request challenge from server
        const res = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/authenticate`)
        const json = await res.json();
        json.publicKey.challenge = base64UrlToBuffer(json.publicKey.challenge)

        // Step 2: Sign challenge using private key
        const credential = await window.navigator.credentials.get(json)
        // default PublicKeyCredential object is not serializable or enumerable
        const _credential = {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: bufferToBase64Url(credential.rawId),
            response: {
                authenticatorData: bufferToBase64Url(credential.response.authenticatorData),
                clientDataJSON: bufferToBase64Url(credential.response.clientDataJSON),
                signature: bufferToBase64Url(credential.response.signature),
                userHandle: bufferToBase64Url(credential.response.userHandle)
            },
            type: credential.type,
            challengeId: json.publicKey.challengeId
        }

        // step 3: Send signed challenge to server for verification
        const res2 = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/verify-authentication`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(_credential)
        })
        const json2 = await res2.json()

        // sign in
        if (json2.user) {
            const { data, error } = await supabase.auth.setSession({
                access_token: json2.user?.session?.access_token,
                refresh_token: json2.user?.session?.refresh_token
            })
            const eventId = window.sessionStorage.getItem("opassityInviteLinkEventId");
            const hostId = window.sessionStorage.getItem("opassityInviteLinkHostId");
            let route = "/";
            if (!!eventId) {
                route = `/event/${eventId}`;
                window.sessionStorage.removeItem("opassityInviteLinkEventId");
                window.sessionStorage.removeItem("opassityInviteLinkHostId");
                try {
                    await axios.post("/event/login", {
                        profileId: data.user.id,
                        eventId,
                        hostId
                    })
                } catch (err) {
                    console.log(err)
                }
            }
            window.location.href = route;
        }
    } catch (err) {
        // user cancelled flow
    }
}

export const sendSmsVerificationCode = async (phoneNumber) => {
    const { data, error } = await supabase.auth.signInWithOtp({
      phone: phoneNumber
    })

    switch (error?.status) {
        case 400: return "Max send attempts reached. Please try again in 10 minutes." 
        case 422: return "Unable to send message to unverified numbers using trial account"
        default: return ""
    }
}

export const checkSmsVerificationCode = async (phoneNumber, code) => {
    const { data, error } = await supabase.auth.verifyOtp({
        phone: phoneNumber,
        token: code,
        type: 'sms'
    })
    const verified = !!data.user;
    return {verified, error: verified ? '' : error.message};
}

