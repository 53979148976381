import React from 'react';
import SvgLogout from "../icons/SvgLogout";
import {signOut} from 'api/auth';
import { Button } from 'components/atoms/Button';

function OnboardingStepHeader({title, description}) {
    const handleSignOut = () => {
        signOut();
    }

    return (
        <div className='flex flex-row items-center justify-between w-full'>
            {/* <div className='flex-grow'></div> */}
            <div className='flex flex-col gap-3 items-center'>
                <p className='text-2xl md:text-4xl font-semibold text-center'>
                    {title}
                </p>
                <p className='text-md text-slate-500 text-center'>
                    {description}
                </p>
            </div>
            <div className='flex items-center'>
                <Button 
                    onClick={handleSignOut} 
                    icon={SvgLogout} 
                    variant={'secondary'} 
                    size={'icon'}
                    radius={'full'}
                />
            </div>
        </div>
       
    );
}

export default OnboardingStepHeader;
