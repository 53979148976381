import supabase from './supabase';

export const addBlockedHosts = async (profile_id, host_id) => {
    const { error } = await supabase
        .from("blocked_hosts")
        .insert({profile_id, host_id});

    if (error) {
        throw new Error(error.message);
    }
};


export const getBlockedHosts = async (profile_id) => {
    const {data, error} = await supabase
        .from('blocked_hosts')
        .select(`
            *,
            host:profiles!blocked_hosts_host_id_fkey(*)
        `)
        .eq('profile_id', profile_id)
    
    return data
}