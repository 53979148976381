import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";

function InviteHostPaywallModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header="Host Limit Reached!"
            description={`You've reached the maximum number of hosts that can be invited on a ${props.tier.toLowerCase()} tier account. Remove an existing host or upgrade this event to add more hosts.`}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={props.close} variant={'secondary'}>
                      Close
                    </Button>
                </div>
            }
        >
        </ModalComponent>
    );
}

export default InviteHostPaywallModal;
