import React, {useCallback, useEffect, useState} from 'react';
import AnimatedTabs from "../components/atoms/AnimatedTabs";
import GuestPendingEventsList from "../components/guest_view/GuestPendingEventsList";
import GuestUpcomingEventsList from "../components/guest_view/GuestUpcomingEventsList";
import GuestPastEventsList from "../components/guest_view/GuestPastEventsList";
import {connect} from 'react-redux';
import ListZeroState from "../components/components/ListZeroState";
import {DateTime} from 'luxon';
import zero_state_video from '../assets/video/3d-casual-life-searching-for-a-new-employee.mov'


const mapStateToProps = (state) => {
    return {
        authSession: state.userReducer.authSession,
        currentEventId: state.userReducer.currentEventId,
        userEvents: state.userReducer.userEvents,
        userProfile: state.userReducer.userProfile,
    }
}

function HomePage(props) {
    const [activeTab, setActiveTab] = useState('Upcoming');
    const [pendingEvents, setPendingEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);

    useEffect(() => {
        const listUserEvents = Object.values(props.userEvents);

        const pendingEvents = [];
        const upcomingEvents = [];
        const pastEvents = [];
        const currentTimestamp = DateTime.now();
        for (const event of listUserEvents) {
            // Checks if event is in the past first
            const eventEndTimestamp = DateTime.fromISO(event.end_timestamp);
            if (eventEndTimestamp < currentTimestamp) {
                pastEvents.push(event);
                continue;
            }

            // Iterates over event guests to find user guest object, checks state
            let isPending = false;
            for (const guest of event.guests) {
                if (guest.profile_id === props.authSession?.user.id ) {
                    if (!guest.status || ["Approved", "Pending Host", "Pending Organizer", "Rejected"].includes(guest.status)) {
                        pendingEvents.push(event)
                        isPending = true;
                    } else if (guest.status === "Not Attending") {
                        isPending = true;
                        continue
                    }
                }
            }
            if (!isPending) {
                upcomingEvents.push(event);
            }
        }
        setPendingEvents(pendingEvents);
        setUpcomingEvents(upcomingEvents);
        setPastEvents(pastEvents);
    }, [props.authSession, props.userEvents])

    const switchView = useCallback(() => {
        switch (activeTab) {
            case 'Upcoming':
                return (
                    <>
                        {(pendingEvents.length === 0 && upcomingEvents.length === 0) ?
                            <ListZeroState
                                // video={zero_state_video}
                                title={"You haven't been invited to any events on the Opassity platform"}
                                description={"Check with a host if you were expecting an invite, and ensure the account you are using has the correct contact email/phone."}
                            />
                            :
                            <>
                                <GuestPendingEventsList events={pendingEvents}/>
                                <GuestUpcomingEventsList events={upcomingEvents}/>
                            </>
                        }
                    </>
                )

            case 'Past':
                return (
                    <GuestPastEventsList events={pastEvents}/>
                )
            default:
                return null
        }
    }, [activeTab, pendingEvents, upcomingEvents, pastEvents])

    return (
        <div className='w-full max-w-[1400px] mx-auto pt-14 flex flex-col items-center flex-1'>
            <div className='w-full md:w-[740px] flex flex-col gap-3 mt-1 px-5 pt-14'>
                <p className='text-3xl font-bold'>
                    My Events
                </p>
                <div
                    className='sticky top-14 -mx-3 px-3 bg-white z-[0] flex flex-row justify-between items-end border-b border-gray-100'>
                    <div className='mr-auto'>
                        <AnimatedTabs
                            activeTab={activeTab}
                            changeTab={setActiveTab}
                            tabs={[
                                {title: 'Upcoming'},
                                {title: 'Past'},
                            ]}
                            className={'my-3'}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-3 mt-3 w-full pb-20'>
                    {switchView()}
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(HomePage);
