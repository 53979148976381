import React from "react";


function ListZeroState({title, description, video, image, imageClassName}) {
    return (
        <div className='mx-auto text-center gap-3 my-12 hidden only:block'>
            {!!video &&
                <video
                    playsInline={true}
                    autoPlay={true}
                    controls={false}
                    loop={true}
                    src={video}
                    muted={true}
                    className='w-1/2 mx-auto mb-5'
                />
            }
            {/*{!!image &&*/}
            {/*    <img*/}
            {/*        src={image}*/}
            {/*        alt={'zero-state'}*/}
            {/*        className={`w-1/2 mx-auto mb-5 ${imageClassName}`}*/}
            {/*    />*/}
            {/*}*/}
            <p>
                {title}
            </p>
            <p className='text-sm opacity-50 mx-auto'>
                {description}
            </p>
        </div>
    )
}

export default ListZeroState
