import React, {useCallback} from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import {DateTime} from 'luxon';
import {Button} from "../../atoms/Button";
import SvgMoreVertical from "../../icons/SvgMoreVertical";

function HostPendingInviteItem({attendee, index, openEditPendingInviteModal}) {
    const renderCell = useCallback((key, customRender = null) => {
        const content = attendee[key];
        // For cells that need custom rendering
        if (customRender) return customRender(content);
        // Default rendering for cells with plain value or '--'
        return <div className='max-w-xs truncate'>{content || '--'}</div>;
    }, [attendee]);

    return (
        <>
            <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`} key={index}>
                <td className={`px-5 py-3 sticky left-0 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                    {renderCell('name', (name) => (
                        <div className='max-w-xs truncate font-semibold'>
                            {name}
                        </div>
                    ))}
                </td>
                <td className='whitespace-nowrap px-5 py-3'>{renderCell('phone_number', (phoneNumber) => 
                    <p>
                        {parsePhoneNumber(`+1${phoneNumber}` || "")?.formatNational() || "--"}
                    </p>
                )}</td>
                <td className='px-5 py-3'>{renderCell('email')}</td>
                <td className='px-5 py-3'>{renderCell('created_at', (timestamp) => 
                    <div className='max-w-xs truncate'>
                        {DateTime.fromISO(timestamp, {zone: 'utc'}).toRelative()}
                    </div>
                )}</td>
                <td className={`px-2 py-3 sticky right-0`}>
                    <div className='flex flex-row gap-3'>
                        <Button
                            icon={SvgMoreVertical}
                            onClick={() => openEditPendingInviteModal(attendee)}
                            variant={'secondary'}
                            size={'icon'}
                            radius={'full'}
                            className={'shadow-lg'}
                        />
                    </div>
                </td>
            </tr>
        </>
    );
}

export default HostPendingInviteItem;
