import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import SvgClock from "../icons/SvgClock";
import SvgAddPhoto from "../icons/SvgAddPhoto";
import SvgDnd from '../icons/SvgDnd';

const DropzoneUploader = ({ onFileUpload, Icon, label, labelActive, disabled , doesAcceptImages}) => {
    const onDrop = (acceptedFiles) => {
        if (disabled) return;
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            onFileUpload(reader.result); // Pass the image data back to the parent component
        };
        reader.readAsDataURL(file);
    };

    // key value pairs mapping MIME type to file extensions.
    // * does not work as a catch all
    const acceptOptions = {
        'text/csv': ['.csv'],
        ...(doesAcceptImages && {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
        }),
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptOptions,
    });

    return (
        <div className='flex flex-col w-full gap-2'>
          {disabled ?
            <div
              className="border-dashed text-sm text-slate-400 rounded-xl border-2 border-slate-300 p-4 text-center cursor-default"
            >
                <SvgDnd className={'mx-auto'}/>
                {isDragActive ?
                        <p>{labelActive}</p> :
                        <p>{label}</p>
                }
            </div>
          :
            <div {...getRootProps()}
                 className="border-dashed hover:text-cyan-500 text-sm text-slate-400 rounded-xl border-2 border-slate-300 p-4 text-center cursor-pointer">
                <Icon className={'mx-auto'}/>
                <input {...getInputProps()}/>
                {isDragActive ?
                        <p>{labelActive}</p> :
                        <p>{label}</p>
                }
            </div>
          }
        </div>
    );
};

export default DropzoneUploader;
