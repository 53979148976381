import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {sample_attendees} from "../../../utils/dummy";
import GuestListTableFilters from "../../components/GuestListTableFilters";
import OrganizerGuestListTable from "../tables/OrganizerGuestListTable";
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        currentEventId: state.userReducer.currentEventId,
        guests: state.userReducer.guests,
    }
}

function EventAttendeeTablePage(props) {
    const [guests, setGuests] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [genderFilter, setGenderFilter] = useState('');
    const [groupFilter, setGroupFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState(''); // Added
    const [hostFilter, setHostFilter] = useState(''); // Added

    useEffect(() => {
        const _guests = props.guests?.[props.currentEventId] || [];
        const attendingGuests = _guests.filter(guest => guest.status === 'Attending')
        const primaryGuests = attendingGuests.filter(guest => guest.is_primary_guest);
        const primaryGuestsFilteredPlusOnes = primaryGuests.map(g => ({ ...g, plusOne: g.plusOne?.filter(p => p.status === 'Approved' || p.status === 'Attending') }))
        const filteredGuests = primaryGuestsFilteredPlusOnes.filter(attendee => {
            return (genderFilter ? attendee.profile.gender === genderFilter : true) &&
                ((groupFilter ? attendee.group === groupFilter : true) ||
                (groupFilter ? attendee.plusOne?.group === groupFilter : true)) &&
                (statusFilter ? attendee.status === statusFilter : true) &&
                (hostFilter ? `${attendee.host.profile.first_name} ${attendee.host.profile.last_name}` === hostFilter : true) &&
                ((searchFilter ? `${attendee.profile.first_name.toLowerCase()} ${attendee.profile.last_name.toLowerCase()}`.includes(searchFilter.toLowerCase()) : true) ||
                (searchFilter ? `${attendee.plusOne?.profile.first_name.toLowerCase()} ${attendee.plusOne?.profile.last_name.toLowerCase()}`.includes(searchFilter.toLowerCase()) : true));
        });
        

        setGuests(filteredGuests)
        props.setFilteredGuests(filteredGuests)

        return () => props.setFilteredGuests(primaryGuests)
    }, [props.currentEventId, props.guests, searchFilter, genderFilter, groupFilter, statusFilter, hostFilter])


    const handleFilterChange = useCallback((filterName) => (value) => {
        switch (filterName) {
            case 'searchFilter':
                setSearchFilter(value);
                break;
            case 'genderFilter':
                setGenderFilter(value);
                break;
            case 'groupFilter':
                setGroupFilter(value);
                break;
            case 'statusFilter':
                setStatusFilter(value);
                break;
            case 'hostFilter':
                setHostFilter(value);
                break;
            default:
                break;
        }
    },[]);

    return (
        <div className='flex flex-col flex-1 md:mt-4'>
            <div className='mt-5 mb-2 md:hidden flex flex-row items-center justify-between'>
                <p className='text-2xl font-semibold'>
                    Guest List
                </p>
            </div>
            <GuestListTableFilters
                searchFilter={searchFilter}
                handleFilterChange={handleFilterChange}
                genderFilter={genderFilter}
                groupFilter={groupFilter}
                statusFilter={statusFilter}
                hostFilter={hostFilter}
                hideFilters={['status']}
            />
            <OrganizerGuestListTable
                attendees={guests}
                viewingAs={'organizer'}
            />
        </div>
    )
}

export default connect(mapStateToProps, null)(EventAttendeeTablePage);
