import React from 'react';

function OnboardingStepper(props) {
    return (
        <div className='flex flex-row items-center gap-3'>
            {[...Array(props.numSteps).keys()].map((item) =>
                <div
                    key={item} className="bg-slate-300 w-[40px] md:w-[95px] h-[2px] relative">
                    <div
                        className="absolute top-0 left-0 bottom-0 bg-primary transition-width duration-300"
                        style={{ width: props.step >= (item + 1) ? '100%' : '0%' }}
                    />
                </div>
            )}
        </div>
    );
}

export default OnboardingStepper;
