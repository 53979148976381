import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";

function InviteHostPaywallModal(props) {
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header="Event at Capacity!"
            description={`This event is at capacity and is not accepting any more guests. Your spot in line is saved in case capacity is increased in the future.`}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={props.close} variant={'primary'}>
                      Go Home
                    </Button>
                </div>
            }
        >
        </ModalComponent>
    );
}

export default InviteHostPaywallModal;

