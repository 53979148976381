import './App.css';
import Navbar from "./components/navigation/Navbar";
import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useSearchParams, useLocation} from "react-router-dom";
import {connect} from 'react-redux';
import {listenAuthEvents} from './api/auth';
import {getUserEvents} from './api/events';
import { fetchHostForShortlink } from 'api/hosts';
import 'react-toastify/dist/ReactToastify.css';
import supabase from './api/supabase';

// Pages
import OnboardingPage from "./pages/OnboardingPage";
import EventPage from './pages/event/EventPage';
import AuthPage from "./pages/auth/AuthPage";
import HomePage from "./pages/HomePage";
import Spinner from "./components/atoms/Spinner";
import InvitePage from "./pages/InvitePage";
import PhoneVerifyPage from "./pages/PhoneVerifyPage";
import ToastComponent from "./components/atoms/ToastComponent";
import ScrollToTop from "./components/navigation/ScrollToTop";
import ProfilePage from "./pages/ProfilePage";
import CreateEventPage from "./pages/CreateEventPage";
import PricingPage from "./pages/PricingPage";
import CheckoutPage from './pages/CheckoutPage';
import CheckoutSuccessPage from './pages/CheckoutSuccessPage';
import TermsOfServicePage from './pages/TermsOfServicePage';

const mapStateToProps = (state) => {
    return {
        authSession: state.userReducer.authSession,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setMobile: (mobile) => {
            dispatch({
                type: "SET_MOBILE",
                mobile
            })
        },
        setUserProfile: (userProfile) => {
          dispatch({
            type: "SET_USER_PROFILE",
            userProfile
          })
        }
    }
}

function Router(props) {
    const [searchParams] = useSearchParams();
    const [authSession, setAuthSession] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [loadingAuthUser, setLoadingAuthUser] = useState(true);
    const [onboarded, setOnboarded] = useState(false);
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    const resize = () => props.setMobile(window.innerWidth <= 760);

    useEffect(() => {
        resize();
    }, [location.pathname]);

    useEffect(() => {
        listenAuthEvents(handleAuthEvent);
    }, [])

    // determine if user has onboarded
    useEffect(() => {
        setOnboarded(props.authSession?.user?.user_metadata?.onboarded || false)
    }, [props.authSession?.user])

    // determine if user has entered phone number
    useEffect(() => {
        // console.log(props.authSession?.user)
    }, [props.authSession?.user])

    useEffect(() => {
        setLoading(loadingAuthUser);
    }, [loadingAuthUser])

    const handleAuthEvent = async (authSession) => {
        setAuthSession(authSession);
        const _authenticated = !!authSession;
        setAuthenticated(_authenticated);

        if (_authenticated) {
            setLoadingAuthUser(true);
            const eventIds = await getUserEvents();

            const userProfile = (await supabase
                            .from("profiles")
                            .select("*")
                            .eq("id", authSession?.user.id)
                          ).data?.[0];
          setLoadingAuthUser(false);
          if (!!userProfile) {
            props.setUserProfile(userProfile)
          }

          if (!userProfile)
            return

        } else {
          setLoadingAuthUser(false);

          // save event id for proper routing after onboarding
          const inviteLinkRe = /^\/invite\/([\w\d]{6})$/;
          if (inviteLinkRe.test(location.pathname)) {
              const shortlink = inviteLinkRe.exec(location.pathname)[1];
              const {event_id, host_id, parent_id} = await fetchHostForShortlink(shortlink);
              window.sessionStorage.setItem("opassityInviteLinkEventId", event_id)
              window.sessionStorage.setItem("opassityInviteLinkHostId", host_id)
              window.sessionStorage.setItem("opassityInviteLinkParentId", parent_id)
          } else if (!!searchParams.get("eventId")) {
              window.sessionStorage.setItem("opassityInviteLinkEventId", searchParams.get("eventId"))
              window.sessionStorage.setItem("opassityInviteLinkHostId", searchParams.get("hostId"))
          }
        }

    }

    const PrivateRoute = (props) => {
        const {children} = props;

        return authenticated ? (
            onboarded ?
                <>{children}</>
                :
                <Navigate to="/onboarding" replace={true}/>
        ) : (
            <Navigate to="/auth" replace={true}/>
        )
    }

    return loading ?
        <div className='flex flex-col items-center justify-center min-h-[100dvh]'>
            <Spinner size={24}/>
        </div>
        :
        <>
            <div className="min-h-[100dvh] flex flex-col flex-1 text-gray-800">
                <ScrollToTop/>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<PrivateRoute><HomePage/></PrivateRoute>}/>
                    <Route path="/event/:eventId" element={<PrivateRoute><EventPage/></PrivateRoute>}/>
                    <Route path="/auth" element={<AuthPage/>}/>
                    <Route path="/onboarding" element={<OnboardingPage/>}/>
                    <Route path="/invite" element={<InvitePage/>}/>
                    <Route path="/invite/:shortlink" element={<InvitePage/>}/>
                    {/*{Todo: @nick add this verify step to flow}*/}
                    <Route path="/verify" element={<PhoneVerifyPage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/create-event" element={<PrivateRoute><CreateEventPage/></PrivateRoute>}/>
                    <Route path="/pricing" element={<PricingPage/>}/>
                    <Route path="/checkout" element={<PrivateRoute><CheckoutPage/></PrivateRoute>}/>
                    <Route path="/checkout/success" element={<PrivateRoute><CheckoutSuccessPage/></PrivateRoute>}/>
                    <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                </Routes>
                <ToastComponent/>
            </div>
        </>
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
