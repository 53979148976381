import ModalComponent from '../atoms/ModalComponent';
import SvgClose from '../icons/SvgClose';
import {Button} from '../atoms/Button';

export default function Modal(props) {

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            toggle={props.close}
            header={props.title}
            headerToggle
            FooterComponent={
                <Button
                    onClick={props.close}
                    className="w-3/4"
                >
                    Close
                </Button>
            }
        >
            <p>{props.text}</p>
        </ModalComponent>
    )
}
