import {useState} from 'react';
import SvgPasskey from '../../components/icons/SvgPasskey';
import SvgApple from '../../components/icons/SvgApple';
import SvgGoogle from '../../components/icons/SvgGoogle';
import {
    registerWithPasskey,
    sendSmsVerificationCode,
    signInWithApple,
    signInWithGoogle,
    signInWithPasskey
} from '../../api/auth';
import {useNavigate} from 'react-router-dom';
import {Button} from "../../components/atoms/Button";
import SvgLogoMark from "../../components/atoms/SvgLogoMark";
import SignUpInfoModal from '../../components/modals/SignUpInfoModal';
import AliasEducationModal from '../../components/modals/AliasEducationModal';
import SvgHelp from "../../components/icons/SvgHelp";
import SvgInfo from "../../components/icons/SvgInfo";

const AuthLandingView = (props) => {
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [activeTab, setActiveTab] = useState('signup')
    const [signInWithPasskeyLoading, setSignInWithPasskeyLoading] = useState(false);
    const [signUpInfoModalOpen, setSignUpInfoModalOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false)
    const [isGoogleProcessing, setIsGoogleProcessing] = useState(false)
    const handleAppleSignIn = async () => {
        setIsProcessing(true)
        await signInWithApple();
        setIsProcessing(false)
    }


    const handleGoogleSignIn = async () => {
        setIsGoogleProcessing(true)
        await signInWithGoogle();
        setIsGoogleProcessing(false)
    }

    const handlePasskeySignUp = async () => {
        setSignInWithPasskeyLoading(true);
        registerWithPasskey();
        setSignInWithPasskeyLoading(false);
    }

    const handlePasskeySignIn = async () => {
        setSignInWithPasskeyLoading(true);
        await signInWithPasskey();
        setSignInWithPasskeyLoading(false);
    }

    const onChangePhoneNumber = ({phoneNumber, countryCode}) => {
        setPhoneNumber(phoneNumber);
        setCountryCode(countryCode);
    }

    const handlePhoneSignIn = async () => {
        if (phoneNumber.length < 10) return;

        sendSmsVerificationCode(`${countryCode}${phoneNumber}`)
            .then(err => {
                props.setError(err)
            })
        props.setPhoneNumber(phoneNumber)
        props.setAuthView('enterPhone')
    }

    const toggleActiveTab = () => {
        if (activeTab === 'login') {
            setActiveTab('signup')
        } else {
            setActiveTab('login')
        }
    }
    return (
        <div
            className='flex flex-col w-full md:w-[460px] bg-white p-5 py-8 rounded-xl shadow-2xl max-w-screen-sm ml-auto mr-auto gap-2'>
            <SvgLogoMark className={'h-8 w-32 mx-auto'}/>
            <p className='text-gray-800 my-3 text-center text-2xl font-semibold'>
                {activeTab === 'login' ? 'Sign in to your account below' : 'RSVP in a few easy steps'}
            </p>
            {/* {activeTab === 'login' &&
                <Button
                    onClick={handlePasskeySignIn}
                    icon={SvgPasskey}
                    loading={signInWithPasskeyLoading}
                >
                    Sign in with Passkey
                </Button>
            } */}
            {activeTab !== 'login' &&
                <p className='font-semibold text-gray-700 text-center'>
                    For iPhone users:
                </p>
            }
            <Button
                onClick={() => handleAppleSignIn()}
                icon={SvgApple}
                loading={isProcessing}
            >
                Sign {activeTab === 'login' ? 'in' : 'up'} with Apple
            </Button>
            {activeTab !== 'login' &&
                <p className='font-semibold text-gray-700 text-center mt-2'>
                    For Android users:
                </p>
            }
            <Button
                onClick={handleGoogleSignIn}
                loading={isGoogleProcessing}
                icon={SvgGoogle}
            >
                Sign {activeTab === 'login' ? 'in' : 'up'} with Google
            </Button>
            {activeTab !== 'login' &&
                <div className='bg-slate-50 border rounded-xl items-center mt-5 p-3 flex flex-row'>
                    <p className='text-gray-500 text-xs md:text-sm'>
                        <SvgInfo className={'inline-flex w-4 h-4 text-primary mb-1'}/> <span className='text-primary font-medium'>Tip: Your phone is your wristband</span> <p>We strongly recommend using the account type
                        associated with your phone's OS.</p>
                    </p>
                    <Button
                        variant={'ghost'}
                        size={'sm'}
                        onClick={() => setSignUpInfoModalOpen(true)}
                        className="cursor-pointer whitespace-nowrap font-semibold text-primary-400 hover:text-primary-600 ml-1"
                    >
                        Learn more
                    </Button>
                </div>
            }
            {activeTab === 'login' ?
                <p className="text-center mt-5 text-sm md:text-sm text-gray-500">
                    Don't have an account? <span onClick={toggleActiveTab}
                                                 className='cursor-pointer font-semibold text-primary hover:text-primary-600'> Create an account</span>
                </p>
                :
                <p className="text-center mt-5 text-sm md:text-sm text-gray-500">
                    Already RSVP'd for a previous event? <span onClick={toggleActiveTab}
                                                               className='cursor-pointer font-semibold text-primary hover:text-primary-600'> Sign in</span>
                </p>
            }
            <p href="info.opassity.com" className="text-center mt-2 text-sm text-gray-500">
              <a href="https://info.opassity.com" className="text-primary-500">Learn more about Opassity</a>
            </p>
            <SignUpInfoModal isOpen={signUpInfoModalOpen} close={() => setSignUpInfoModalOpen(false)}/>
        </div>
    )
}

export default AuthLandingView;
