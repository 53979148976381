import './App.css';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from "./Routes";
import "react-datepicker/dist/react-datepicker.css";
import {Provider} from "react-redux";
import Store from "./Store";

function App() {
    return (
        <div className='font-outfit'>
            <Router>
                <Provider store={Store}>
                  <Routes />
                </Provider>
            </Router>
        </div>
    );
}

export default App;
