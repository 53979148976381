import React from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import { useNavigate } from 'react-router-dom';

function CreateEventPaywallModal(props) {
    const navigate = useNavigate();

    const goToPricing = () => {
        props.close();
        navigate('/pricing')
    }
    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header="Event Limit Reached!"
            description="You've reached the maximum number of events that can be created on a free tier account. Upgrade your account to create more events."
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-col gap-3 w-full'>
                    <Button onClick={goToPricing} variant={'primary'}>
                      Upgrade
                    </Button>
                    <Button onClick={props.close} variant={'secondary'}>
                      Close
                    </Button>
                </div>
            }
        >
        </ModalComponent>
    );
}

export default CreateEventPaywallModal;
