import React, {useState} from 'react';
import ConfirmationModal from "../components/modals/ConfirmationModal";
import {Button} from "../components/atoms/Button";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    tier: state.userReducer?.userProfile?.tier
  }
}

const FeatureListItem = ({feature}) => (
    <li className="flex items-start">
        <svg className={`w-5 h-5 mr-2 ${feature.available ? 'text-primary' : 'text-gray-500'}`} fill="none"
             stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round"
                  d={feature.available ? "M5 13l4 4L19 7" : "M6 18L18 6M6 6l12 12"}></path>
        </svg>
        <div>
            {feature.label}
            {feature.subLabel && <p className="text-gray-500 text-xs ml-0">{feature.subLabel}</p>}
        </div>
    </li>
);

const TierCard = ({title, price, features, current, popular, contextNote, onSelect, tryDowngrade}) => (
    <div
        className={`border flex flex-1 flex-col rounded-lg p-6 ${popular ? 'shadow-lg transform scale-105 border-primary' : 'border-gray-300'} transition-transform duration-200 ease-in-out`}>
        <div className='flex flex-row items-center gap-2 mb-4'>
            <h2 className={`text-2xl font-semibold ${popular ? 'text-primary' : ''}`}>{title}</h2>
            {current &&
                <div className='text-sm font-semibold text-white bg-primary px-3 py-1 rounded-full'>
                    Current
                </div>
            }
        </div>
        <p className="text-xl font-bold mb-4">{price}</p>
        <ul className="mb-4 space-y-2">
            {features.map((feature, index) => (
                <FeatureListItem key={index} feature={feature}/>
            ))}
        </ul>
        {!!contextNote && (
            <p className="mb-2 text-center text-gray-500 text-xs mt-6">
                {contextNote}
            </p>
        )}
        <Button
            onClick={onSelect}
            variant={current ? 'primary' : 'secondary'}
            disabled={current}
            className={'mt-auto'}
        >
            {current ? 'Current Plan' : 'Select Plan'}
        </Button>
        {(current && title !== 'Free Tier') &&
            <button onClick={tryDowngrade} className='text-xs mt-2 text-primary hover:text-primary-600'>
                Cancel your plan
            </button>
        }
    </div>
);

const PricingPage = ({ tier }) => {
    const [currentTier, setCurrentTier] = useState(tier);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleSelectPlan = (tier) => {
        if (tier === currentTier) {
            return;
        } else if ((tier === 'Free' && (currentTier === 'Pro' || currentTier === 'Enterprise')) || (tier === 'Pro' && currentTier === 'Enterprise')) {
          tryDowngrade();
        } else if (tier === 'Enterprise') {
            window.location.href = "mailto:support@opassity.com";
        } else {
            navigate(`/checkout?tier=${tier}`);
            setCurrentTier(tier);
        }
    };

    const tryDowngrade = () => {
        setConfirmModalOpen(true);
    }

    return (
        <div className="max-w-5xl mx-auto py-12 px-4">
            <div className='mt-12 flex flex-col items-center gap-3 mb-12'>
                <div className='rounded-full border border-primary px-3 py-1 text-sm font-semibold text-primary'>
                    <p>Plans</p>
                </div>
                <h1 className="text-5xl font-bold text-center text-balance md:w-1/2">
                    Pricing for <span className='text-primary'>all kinds of events.</span>
                </h1>
                <p className='text-center text-balance'>
                    Make hosting and managing events simpler than ever. Join Opassity today.
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <TierCard
                    title="Free Tier"
                    price="$0/mo"
                    features={[
                        {label: '1 event', available: true},
                        {label: 'Up to 100 guests', available: true},
                        {label: '3 hosts', available: true},
                        {
                            label: 'Access to scanner check in app',
                            available: false,
                            subLabel: 'Export guest list via CSV, manual check in'
                        },
                    ]}
                    contextNote={"You'll be notified once you reach 25 free invites left. Upgrade any time."}
                    current={currentTier === 'Free'}
                    onSelect={() => handleSelectPlan('Free')}
                    tryDowngrade={tryDowngrade}
                />
                <TierCard
                    title="Pro Tier"
                    price="$500/mo"
                    features={[
                        {label: 'Unlimited events', available: true},
                        {label: 'Up to 500 guests per event', available: true},
                        {label: 'Up to 10 hosts per event', available: true},
                        {label: 'Up to 10 scanners per event', available: true},
                        {label: 'Access to scanner check in app', available: true},
                    ]}
                    current={currentTier === 'Pro'}
                    contextNote={"You'll be notified once you reach 100 invites left. Upgrade any time."}
                    popular={true}
                    onSelect={() => handleSelectPlan('Pro')}
                    tryDowngrade={tryDowngrade}
                />
                <TierCard
                    title="Enterprise"
                    price="Price on request"
                    features={[
                        {label: 'Unlimited events', available: true},
                        {label: 'Unlimited guests', available: true},
                        {label: 'Unlimited hosts', available: true},
                        {label: 'Unlimited scanners', available: true},
                        {label: 'Access to scanner check in app', available: true},
                    ]}
                    current={currentTier === 'Enterprise'}
                    onSelect={() => handleSelectPlan('Enterprise')}
                    tryDowngrade={tryDowngrade}
                />
            </div>
            <div className="mt-12 text-center">
                <p>For any questions, email <a href="mailto:support@opassity.com"
                                               className="text-primary">support@opassity.com</a></p>
            </div>
            <div className="mt-6 text-sm text-gray-500 text-center">
                <p>By purchasing any of the above plans, you agree to our <a href="/terms-of-service" className="text-sky-500">Terms of Service</a>.</p>
            </div>

            <ConfirmationModal
                isOpen={confirmModalOpen}
                title={'Reach out to support to downgrade your plan'}
                description={"Please email support@opassity.com and we'll be happy to assist."}
                cancelText={'Close'}
                hideConfirm
                close={() => setConfirmModalOpen(false)}
            />
        </div>
    );
};

export default connect(mapStateToProps, null)(PricingPage);
