import React, {useCallback, useEffect, useRef, useState} from "react";
import Portal from "./PortalComponent";
import {wait} from "@testing-library/user-event/dist/utils";
import SvgClose from "../icons/SvgClose";
import PropTypes from 'prop-types'
import '../styles/ModalComponent.css'
import ConfettiComponent from "./ConfettiComponent";

// import ConfettiComponent from "./ConfettiComponent";

function ModalComponent(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [modalOpacity, setModalOpacity] = useState(0);
    const [triggeringElement, setTriggeringElement] = useState(null);
    const [element, setElement] = useState(null);
    const [mouseDownElement, setMouseDownElement] = useState(null);

    const dialogRef = useRef(null);
    const initialMount = useRef(true);

    const animateOpen = () => {
        setIsOpen(true);
        document.body.style.overflowY = 'hidden'
        wait(50).then(() => {
            setModalOpacity(1);
        });
    }

    const animateClosed = () => {
        setModalOpacity(0);
        document.body.style.overflowY = 'scroll'
        wait(300).then(() => {
            setIsOpen(false);
        });
    }
    const manageFocusAfterClose = useCallback(() => {
        if (triggeringElement) {
            if (triggeringElement.focus) {
                triggeringElement.focus();
            }
            setTriggeringElement(null);
        }
    }, [triggeringElement])

    const init = useCallback(() => {
        try {
            setTriggeringElement(document.activeElement);
        } catch (err) {
            setTriggeringElement(null);
        }

        if (!element) {
            const newElement = document.createElement('div');
            newElement.setAttribute('tabindex', '-1');

            newElement.style.position = 'relative';
            newElement.style.zIndex = props.zIndex || 1050;
            if (props.node) {
                props.node.appendChild(newElement);
            } else {
                const els = document.querySelectorAll('body');
                const mountContainer = els[0];
                mountContainer.appendChild(newElement);
            }
            setElement(newElement);

        }
    }, [props.node, props.zIndex, element]);

    const destroy = useCallback(() => {
        if (element) {
            if (props.node) {
                props.node.removeChild(element);
            } else {
                const mountContainer = document.querySelectorAll('body')[0];
                mountContainer.removeChild(element);
            }
        }
        manageFocusAfterClose();
    }, [element, props.node, manageFocusAfterClose])

    const setFocus = () => {
        if (dialogRef.current && dialogRef.current.parentNode && typeof dialogRef.current.parentNode.focus === 'function') {
            dialogRef.current.parentNode.focus();
        }
    }


    const handleEscape = e => {
        if (!props.preventEscape && props.isOpen && e.keyCode === 27 && props.toggle) {
            e.preventDefault();
            e.stopPropagation();
            animateClosed();
        }
    }

    const handleBackdropMouseDown = e => {
        setMouseDownElement(e.target);
    }

    const handleBackdropClick = e => {
        if (e.target === mouseDownElement) {
            e.stopPropagation();
            const backdrop = dialogRef.current ? dialogRef.current.parentNode : null;
            if (!props.isOpen) return;

            if (backdrop && e.target === backdrop && props.toggle) {
                props.toggle()
                animateClosed();
            }
        }
    }

    useEffect(() => {
        if (isOpen && !triggeringElement) {
            setFocus();
        }
    }, [isOpen, triggeringElement]);

    useEffect(() => {
        if (props.isOpen && !isOpen && initialMount.current) {
            animateOpen();
            initialMount.current = false;
            //console.warn('modalComponent, animateOpen2')
        } else if (isOpen && !props.isOpen) {
            animateClosed();
            initialMount.current = true;
            //console.warn('modalComponent, animateClosed')
        }
    }, [props.isOpen, isOpen]);

    useEffect(() => {
        if (isOpen && !element) {
            init();
            // console.log('modalComponent, init')
        }
    }, [isOpen, init, element]);

    useEffect(() => {
        if (element) {
            return () => destroy();
        }
    }, [element, destroy]);

    if (!!element && isOpen) {
        const isModalHidden = !!element && !isOpen;
        element.style.display = isModalHidden ? 'none' : 'block';

        const modalAttributes = {
            onClick: handleBackdropClick,
            onMouseDown: handleBackdropMouseDown,
            className: `modal-new-open backdrop-blur text-white ${props.backdropClass}`,
            onKeyUp: handleEscape,
            role: 'dialog',
            tabIndex: '-1',
        };

        return (
            <Portal node={element}>
                <div>
                    <div {...modalAttributes} style={{opacity: modalOpacity, left: 0}}>
                        {props.confetti &&
                            <ConfettiComponent/>
                        }
                        <div ref={dialogRef}>
                            <div
                                className={`modal-content font-outfit modal-content-${props.size || 'md'} ${props.noPadding && 'overflow-hidden'} ${props.modalClassName} rounded-xl text-gray-800`}
                                role="document"
                                style={{
                                    transition: '100ms ease',
                                    padding: props.noPadding && 0,
                                    backgroundColor: props.backgroundColor,
                                    width: props.width,
                                    minWidth: props.minWidth,
                                    maxWidth: props.width,
                                }}
                                id={props.id || ''}
                            >
                                {(props.header || props.headerToggle) &&
                                    <div className={'mb-4'}>
                                        <div
                                            style={{padding: props.noPadding && `${24}px ${24}px 0`}}
                                            className={`text-2xl font-semibold flex flex-row items-center justify-between dark:text-darkMain-lighter`}>
                                            <div className={``}>
                                                {props.header}
                                            </div>
                                            {props.headerToggle && props.toggle &&
                                                <div className="pointer text-white/80 hover:text-white cursor-pointer"
                                                     onClick={() => {
                                                         props.toggle()
                                                         animateClosed();
                                                     }}>
                                                    <SvgClose width={24} height={24}/>
                                                </div>
                                            }
                                        </div>
                                        {props.description &&
                                            <p className='text-gray-500 mt-2'>
                                                {props.description}
                                            </p>
                                        }
                                    </div>
                                }
                                {props.children}
                                {props.FooterComponent &&
                                    <div
                                        style={{padding: props.noPadding && `0 ${24}px ${24}px`}}
                                        className={props.footerAlignment === 'right' ? "flex flex-row justify-end pt-8 items-center" : "flex flex-row justify-center pt-8 items-center"}>
                                        {props.FooterComponent}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Portal>
        );
    }
    return null;
}

ModalComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'full']),
    header: PropTypes.string,
    headerToggle: PropTypes.bool,
    noPadding: PropTypes.bool,
    FooterComponent: PropTypes.object,
    footerAlignment: PropTypes.oneOf(['left', 'center', 'right']),
    zIndex: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    preventEscape: PropTypes.bool,
}

export default ModalComponent;
