const initialState = {
    authSession: null,
    userProfile: null,
    mobile: false,
    userEvents: [],
    currentEventId: null,
    guests: {},
    hosts: {},
    currentEventView: window.localStorage.getItem("opassity-event-view"),
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_MOBILE":
            return {
                ...state,
                mobile: action.mobile
            }
        case "SET_AUTH_SESSION":
            return {
                ...state,
                authSession: action.authSession
            }
        case "SET_USER_PROFILE":
          return {
            ...state,
            userProfile: action.userProfile
          }
        case "SET_PROFILE_TIER":
          return {
            ...state,
            userProfile: {
              ...state.userProfile,
              tier: action.tier
            }
          }
        case "SET_USER_EVENTS":
            return {
                ...state,
                userEvents: action.userEvents
            }
        case "UPDATE_USER_EVENT":
            return {
                ...state,
                userEvents: {...state.userEvents, [action.eventId]: {...state.userEvents[action.eventId], ...action.eventDetails}}
            }
        case "SET_CURRENT_EVENT_ID":
            return {
                ...state,
                currentEventId: action.currentEventId
            }
        case "SET_GUEST_FOR_EVENT":
            return {
                ...state,
                guests: {...state.guests, [action.eventId]: action.guests}
            }
        case "INSERT_GUEST_FOR_EVENT":
            return {
                ...state,
                guests: {...state.guests, [action.eventId]: [...state.guests[action.eventId], action.guest]}
            }
        case "UPDATE_GUEST_FOR_EVENT":
            const parentId = state.guests[action.eventId].filter(g => g.profile_id === action.guestId)[0]?.parent_guest;
            
            return {
                ...state,
                guests: {...state.guests, [action.eventId]: state.guests[action.eventId].map(g =>
                    g.profile_id === action.guestId ? {...g, ...action.guestUpdates, plusOne: g?.plusOne?.map(p => ({...p, ...action.guestUpdates}))}
                    : g.profile_id === parentId ? {...g, ...(action?.updateParent ? action.guestUpdates : {}), plusOne: g?.plusOne?.map(p => ({...p, ...action.guestUpdates}))}
                    : g
                )}
            }
        case "DELETE_GUEST_FOR_EVENT":
            return {
                ...state,
                guests: {...state.guests, [action.eventId]: state.guests[action.eventId].filter(g =>
                    g.profile_id !== action.guestId
                ).map(g =>
                    g.profile_id === state.guests[action.eventId].filter(g => g.profile_id === action.guestId)[0].parent_guest ? {...g, plusOne: []}
                    : g
                )}
            }
        case "SET_HOSTS_FOR_EVENT":
            return {
                ...state,
                hosts: {...state.hosts, [action.eventId]: action.hosts}
            }
        case "INSERT_HOST_FOR_EVENT":
            return {
                ...state,
                hosts: {...state.hosts, [action.eventId]: [...state.hosts[action.eventId], action.hostDetails]}
            }
            
        case "UPDATE_HOST_FOR_EVENT":
            return {
                ...state,
                hosts: {...state.hosts, [action.eventId]: state.hosts[action.eventId].map(h => 
                    h.profile_id === action.hostId ? {...h, ...action.hostUpdates} : h
                )}
            }
        case "DELETE_HOST_FOR_EVENT":
            return {
                ...state,
                hosts: {...state.hosts, [action.eventId]: state.hosts[action.eventId].filter(h =>
                    h.profile_id !== action.hostId
                )},
                guests: {...state.guests, [action.eventId]: state.guests[action.eventId].map(g =>
                    g.host_id === action.hostId ? {...g, host_id: null, host: null, plusOne: g.plusOne?.host_id === action.hostId ? {...g, plusOne: g.plusOne.map(p => ({...p, host_id: null, host: null}))} : g.plusOne } :
                    g.plusOne?.host_id === action.hostId ? {...g, plusOne: g.plusOne.map(p => ({...p, host_id: null, host: null}))}
                    : g
                )}
            }
        case "SET_EVENT_VIEW":
          window.localStorage.setItem("opassity-event-view", action.view)
          return {
            ...state,
            currentEventView: action.view
          }
        default:
            return state;
    }
};

