import supabase from './supabase';
import store from '../Store';
import {DateTime} from 'luxon';
import parsePhoneNumber from 'libphonenumber-js';
import axios from './axios';

export const updateHostDetails = async (eventId, hostId, hostDetails, isRegisteredUser=true) => {
    if (isRegisteredUser) {
        const { error } = await supabase
            .from("hosts")
            .update(hostDetails)
            .eq("event_id", eventId)
            .eq("profile_id", hostId)
        store.dispatch({
            type: "UPDATE_HOST_FOR_EVENT",
            eventId,
            hostId,
            hostUpdates: {
                invite_limit: hostDetails.invite_limit,
                requires_approval: hostDetails.requires_approval,
                frozen: hostDetails.frozen
            }
        })
    } else {
        const { error: error2 } = await supabase
            .from("invited_users")
            .update(hostDetails)
            .eq("event_id", eventId)
            .eq("id", hostId)
        store.dispatch({
            type: "UPDATE_USER_EVENT",
            eventId,
            eventDetails: {
                invited_users: store.getState().userReducer.userEvents[eventId].invited_users.map(
                    s => s.id === hostId ? {...s, ...hostDetails} : s
                ),
            }
        })
    }
}

export const deleteHost = async (eventId, hostId, isRegisteredUser) => {
    if (isRegisteredUser) {
        const { error } = await supabase
            .from("hosts")
            .delete()
            .eq("event_id", eventId)
            .eq("profile_id", hostId)
        store.dispatch({
            type: "DELETE_HOST_FOR_EVENT",
            eventId,
            hostId
        })
    } else {
        const { error: error2 } = await supabase
            .from("invited_users")
            .delete()
            .eq("event_id", eventId)
            .eq("id", hostId)
        store.dispatch({
            type: "UPDATE_USER_EVENT",
            eventId,
            eventDetails: {
                invited_users: store.getState().userReducer.userEvents[eventId].invited_users.filter(s => s.id !== hostId),
            }
        })
    }
}

export const addHost = async (eventId, phoneNumber, hostDetails) => {
    const { data: { session: { access_token }} } = await supabase.auth.getSession();
    const endpoint = "/event/host"
    const res = await axios.post(endpoint, {
        access_token,
        event_id: eventId,
        phone_number: phoneNumber,
        ...hostDetails,
    });

    if (!!res?.data?.error)
      throw new Error(res.data.error)

    if (res.data.type === 'invite') {
      store.dispatch({
          type: "UPDATE_USER_EVENT",
          eventId,
          eventDetails: {
              invited_users: [
                  ...store.getState().userReducer.userEvents[eventId].invited_users, res.data.invite
              ]
          }
      })
    } else if (res.data.type === 'host') {
      const hostId = res.data.host.profile_id;
      store.dispatch({
          type: "INSERT_HOST_FOR_EVENT",
          eventId,
          hostId,
          hostDetails: {
              profile: res.data.profile,
              profile_id: hostId,
              created_at: DateTime.utc().toISO(),
              event_id: eventId,
              invite_limit: hostDetails.invite_limit,
              requires_approval: hostDetails.requires_approval,
              frozen: hostDetails.frozen
          }
      })
    }
}

export const isDisabled = (eventId, hostId) => {
  const event = store.getState().userReducer.userEvents[eventId];
  const host = store.getState().userReducer.userEvents[eventId]?.hosts
    ?.filter(h => h.event_id === eventId && h.profile_id === hostId)?.[0];

  const cutoffTime = DateTime.fromISO(event?.start_timestamp || "9999-01-01").minus({hours: 2});
  const eventClosed = DateTime.utc() > cutoffTime;
  const hostFrozen = host?.frozen || false;
  return eventClosed || hostFrozen;
}

// DEPRECATED, only here temporarily. Use fetchShortLinks instead
export const generateInviteLink = async (eventId, hostId, i=0) => {
    const baseUrl = window.location.origin;
    // const link = `${baseUrl}/invite?eventId=${eventId}&hostId=${hostId}`;
    let shortLink = (await supabase
                      .from("host_links")
                      .select("id, short_link")
                      .eq("event_id", eventId)
                      .eq("host_id", hostId)
                      .eq("deactivated", false)
                      ).data?.[i]
  if (!shortLink) {
      shortLink = await createShortLink(eventId, hostId);
  }

    const link = `${baseUrl}/invite/${shortLink.short_link}`;
    return link;
}


export const createShortLink = async (event_id, host_id, label="default") => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  const shortLinkSize = 6;
  const short_link = [...Array(shortLinkSize).keys()].map(k => {
    const i = Math.floor(Math.random() * 62);
    return charset[i];
  }).join('')

  // add to supabase
  const { data, error } = await supabase
                    .from("host_links")
                    .insert({
                      host_id,
                      event_id,
                      short_link,
                      label,
                    }).select("*")
  if (!!error)
    throw new Error("Unable to add short link to supabase");

  return {id: data?.[0]?.id, short_link};
}

const createGuestShortLink = async (event_id, guest_id) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  const shortLinkSize = 6;
  const short_link = [...Array(shortLinkSize).keys()].map(k => {
    const i = Math.floor(Math.random() * 62);
    return charset[i];
  }).join('')

  const { data, error } = await supabase
                    .from("guests")
                    .update({
                      invite_shortlink: short_link,
                    })
                    .eq("event_id", event_id)
                    .eq("profile_id", guest_id)
                   .select("*")
  if (!!error)
    throw new Error("Unable to add short link to supabase");

  return short_link;
}

export const fetchShortLinks = async (event_id, host_id) => {
  const shortLinks = (await supabase
                      .from("host_links")
                      .select("*")
                      .eq("host_id", host_id)
                      .eq("event_id", event_id)
                      .eq("deactivated", false)
                      ).data

  if (!shortLinks)
    throw new Error("No host found for this event")
  
  const baseUrl = window.location.origin;
  const expandedShortLinks = shortLinks.map(s => ({
    ...s,
    short_link: `${baseUrl}/invite/${s.short_link}`,
  }))
  return expandedShortLinks;
}

export const fetchGuestShortLinks = async (event_id, guest_id) => {
  let shortLink = (await supabase
                      .from("guests")
                      .select("*")
                      .eq("event_id", event_id)
                      .eq("profile_id", guest_id)
                      ).data?.[0]?.invite_shortlink

  if (!shortLink)
    shortLink = await createGuestShortLink(event_id, guest_id);
  
  const baseUrl = window.location.origin;
  const expandedShortLink = `${baseUrl}/invite/${shortLink}`
  return [{short_link: expandedShortLink}];
}

export const updateShortLinkStatus = async (id, deactivated) => {
  const { error } = await supabase
                    .from("host_links")
                    .update({deactivated})
                    .eq("id", id)

  if (!!error)
    throw new Error("Error updating shortlink")
}

export const deleteShortLink = async (id) => {
  const { error } = await supabase
                    .from("host_links")
                    .delete()
                    .eq("id", id)

  if (!!error)
    throw new Error("Error deleting shortlink")
}

export const fetchHostForShortlink = async (shortlink) => {
  const { data, error } = await supabase
    .from("host_links")
    .select("host_id, event_id")
    .eq("deactivated", false)
    .eq("short_link", shortlink)

  if (!!error)
    throw new Error("Unable to find shortlink")

  if (data.length === 0) {

    const res = await axios.get("/event/shortlink?shortlink=" + shortlink);
    if (!!res.data.error)
      return {host_id: null, event_id: null}
    return res.data;
  }

  return data[0];
}
