import React, {useState} from "react";
import {Button} from "../../atoms/Button";
import SvgUsers from "../../icons/SvgUsers";
import DropzoneUploader from "../../atoms/DropzoneUploader";
import SvgAddList from "../../icons/SvgAddList";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../../utils/consts";
import {parseCSV} from '../../../utils/csv';
import {quickInviteGuest} from 'api/guests';
import BulkUpdloadHelperModal from "../../modals/BulkUpdloadHelperModal";

function HostBulkInvite(props) {
    const [isHelperModalOpen, setIsHelperModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleFileUpload = async (filePath) => {
        setLoading(true)
        try {
            // Parse method ensures each row has name, email, phone
            // Rows will not be added twice which is useful if uploading some rows fails
            // and you wish to re-attempt.
            const csv = await parseCSV(filePath);
            await Promise.all(csv.map(async row => {
                const [name, email, phone] = row;
                return await quickInviteGuest(props.eventId, props.hostId, phone, name, email, true);
            }))
            window.location.reload();
        } catch (err) {
            let message = err.message;
            if (message === 'FreePaywall')
              message = "Guest limit reached! Free tier events can only invite 100 guests.";
            if (message === 'ProPaywall')
              message = "Guest limit reached! Pro tier events can only invite 500 guests.";
            toast.error(message, TOAST_OPTIONS);
        } finally {
            setLoading(false)
        }
    };

    const openHelperModal = () => {
        setIsHelperModalOpen(true)
    }

    const closeHelperModal = () => {
        setIsHelperModalOpen(false)
    }

    return (
        <div className='flex flex-col gap-3'>
            <p className='text-lg font-bold'>
                Bulk Invite
            </p>
            <p>
                Upload guests with a spreadsheet to send out invites via email (or phone number if they are opted
                in). Click <span onClick={openHelperModal}
                                 className='text-primary cursor-pointer underline'>here</span> to learn about how to
                format your
                uploads
            </p>
            <div>
                <DropzoneUploader
                    label={props.disabled ? "No longer accepting invites" : 'Add a spreadsheet'}
                    labelActive={props.disabled ? "No longer accepting invites" : 'Drop here ...'}
                    Icon={SvgAddList}
                    onFileUpload={handleFileUpload}
                    disabled={props.disabled || false}
                />
            </div>
            <Button loading={loading} onClick={null} disabled={props.disabled} icon={SvgUsers} className=''>
                Send invites
            </Button>
            <BulkUpdloadHelperModal
                isOpen={isHelperModalOpen}
                close={closeHelperModal}
            />
        </div>
    )
}

export default HostBulkInvite
