import React from "react";
import HostQuickSendInvite from "./HostQuickSendInvite";
import HostBulkInvite from "./HostBulkInvite";
import HostInviteLink from "./HostInviteLink";
import {connect} from 'react-redux';
import {isDisabled} from 'api/hosts';
import HostInviteLinks from "./HostInviteLinks";

const mapStateToProps = (state) => {
    return {
        eventId: state.userReducer.currentEventId,
        hostId: state.userReducer.authSession.user.id,
        mobile: state.userReducer.mobile,
    }
}

function HostInvitePanel(props) {
    const disabled = isDisabled(props.eventId, props.hostId);

    return (
        <div className='flex flex-[.5] w-full flex-col gap-8 max-w-[500px]'>
            {/*Todo: Make new invite links component dynamic @nick*/}
            <HostInviteLinks eventId={props.eventId} hostId={props.hostId} mobile={props.mobile} disabled={disabled}/>
            {/*<HostInviteLink eventId={props.eventId} hostId={props.hostId} mobile={props.mobile} disabled={disabled}/>*/}
            <HostQuickSendInvite eventId={props.eventId} hostId={props.hostId} disabled={disabled}/>
            <HostBulkInvite disabled={disabled} eventId={props.eventId} hostId={props.hostId}/>
        </div>
    )
}

export default connect(mapStateToProps, null)(HostInvitePanel);
