import axios from './axios';

export const sendEmail = async (to, subject, html) => {
  const endpoint = '/utils/email'
  const res = await axios.post(endpoint, {
    to,
    subject,
    html
  })
  const data = res.data;
  return data;
}
