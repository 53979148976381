import React, { PureComponent, createRef } from "react";

class SectionTabs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            indicatorOffset: 0,
            indicatorWidth: 0,
        };
        this.tabsRef = this.props.tabs.reduce((acc, tab) => {
            acc[tab] = createRef();
            return acc;
        }, {});
    }

    componentDidMount() {
        this.updateIndicator(this.props.activeTab);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeTab !== this.props.activeTab) {
            this.updateIndicator(this.props.activeTab);
        }
    }

    updateIndicator(tab) {
        const currentTabRef = this.tabsRef[tab].current;
        if (currentTabRef) {
            this.setState({
                indicatorOffset: currentTabRef.offsetLeft,
                indicatorWidth: currentTabRef.offsetWidth,
            });
        }
    }

    render() {
        const { indicatorOffset, indicatorWidth } = this.state;

        return (
            <div className='flex flex-row items-center' style={{gap: 20, position: 'relative'}}>
                {this.props.tabs.map((tab, index) => (
                    <div
                        key={tab}
                        ref={this.tabsRef[tab]}
                        onClick={() => this.props.changeTab(tab)}
                        className={`cursor-pointer border-transparent pb-1 flex flex-col items-center border-b-2 ${this.props.activeTab.includes(tab) ? 'border-primary text-primary' : 'text-gray-400 border-transparent hover:text-gray-600'} pb-2`}
                    >
                        <p className='text-lg font-semibold'>
                            {this.props.labelOverrides?.[index] || tab}
                        </p>
                    </div>
                ))}
                <div
                    className='bg-primary'
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: indicatorOffset,
                        width: indicatorWidth,
                        height: '2px',
                        transition: 'left 0.3s, width 0.3s'
                    }}
                />
            </div>
        );
    }
}

export default SectionTabs;
