import React, {useState, useEffect} from "react";
import StatItem from "../../atoms/StatItem";
import UserInfoComponent from "../../atoms/UserInfoComponent";
import {Button} from "../../atoms/Button";
import SvgMoreVertical from "../../icons/SvgMoreVertical";
import SvgChevronRight from "../../icons/SvgChevronRight";
import {getGuestsByHostId} from '../../../api/guests';

function OrganizerHostListItemSummary({user, toggleCollapse, isListCollapsed, openEditHostModal}) {
    const [hostGuests, setHostGuests] = useState([]);
    const [numInvited, setNumInvited] = useState(0);
    const [numAccepted, setNumAccepted] = useState(0);
    const [numPending, setNumPending] = useState(0);
    const [numRejected, setNumRejected] = useState(0);
    const [numRemaining, setNumRemaining] = useState(0);

    useEffect(() => {
        getGuestsByHostId(user.event_id, user.profile_id || user.id)
            .then(res => {
                setHostGuests(res);
                setNumInvited(res.length)
                setNumAccepted(res.reduce((s, g) => s + (g.status === 'Approved' ? 1 : 0), 0))
                setNumPending(res.reduce((s, g) => s + (g.status === 'Pending Organizer' || g.status === 'Pending Host' ? 1 : 0), 0))
                setNumRejected(res.reduce((s, g) => s + (g.status === 'Rejected' ? 1 : 0), 0))
                setNumRemaining(user.invite_limit)
            }).catch(err => {})
    }, [user])

    return (
        <div
            className='flex flex-col md:flex-row md:items-center gap-5 md:justify-between cursor-pointer
            {/*hover:bg-gray-100*/}
             p-2 px-4 rounded-xl'>
            <UserInfoComponent user={user}/>
            <div className='flex flex-col md:flex-row md:items-center gap-6'>
                <div className='flex flex-col gap-1'>
                    <p className='text-gray-500 text-xs uppercase'>
                        Invites
                    </p>
                    <div className='flex flex-row gap-10'>
                        <StatItem
                            value={numAccepted}
                            label={'Accepted'}
                        />
                        <StatItem
                            value={numRemaining}
                            label={'Remaining'}
                        />
                        <StatItem
                            value={numPending}
                            label={'Req. Approval'}
                        />
                    </div>
                </div>
               <div className='flex flex-row gap-3'>
                   <Button
                       variant={'secondary'}
                       onClick={toggleCollapse}
                       className='flex-1 md:w-[150px]'
                   >
                       {!isListCollapsed ?
                           <>View Invites <SvgChevronRight className={'w-4 h-4 rotate-[90deg]'}/></>
                           :
                           <>Hide Invites <SvgChevronRight className={'w-4 h-4 rotate-[-90deg]'}/></>
                       }
                   </Button>
                   <Button
                       icon={SvgMoreVertical}
                       onClick={() => openEditHostModal(user)}
                       variant={'secondary'}
                       size={'icon'}
                       radius={'full'}
                   />
               </div>
            </div>
        </div>
    )
}

export default OrganizerHostListItemSummary
